// Kanun kısaltmalarını tam adlarıyla eşleştiren bir nesne
const kanunKisaltmalari = {
    'TCK': 'Türk Ceza Kanunu',
    'CMK': 'Ceza Muhakemesi Kanunu',
    'CMUK': 'Ceza Muhakemeleri Usulü Kanunu',
    'HMK': 'Hukuk Muhakemeleri Kanunu',
    'TMK': 'Türk Medeni Kanunu',
    'TBK': 'Türk Borçlar Kanunu',
    // Diğer kanun kısaltmalarını buraya ekleyin
  };
  
  // Kanun numaralarını kısaltmalarla veya özel isimlerle eşleştiren bir nesne
  const kanunNumaralari = {
    '5237': 'TCK',
    '5271': 'CMK',
    '1412': 'CMUK',
    '765': '765 sayılı TCK',
    '6100': 'HMK',
    '4721': 'TMK',
    '6098': 'TBK',
    '5275': '5275 sayılı Kanun',
    // Diğer özel durumları veya kanun numaralarını buraya ekleyin
  };
  
  export function normalizeKanunMaddesi(madde) {
    // Boşlukları temizle ve büyük harfe çevir
    madde = madde.trim().toUpperCase();
    
    // Kanun kısaltması veya numarası ile başlıyorsa
    const kisaltmaMatch = madde.match(/^([A-Z]+)/);
    const numaraMatch = madde.match(/^(\d+)/);
    
    let kanunAdi = '';
    
    if (kisaltmaMatch) {
      kanunAdi = kanunKisaltmalari[kisaltmaMatch[1]] || kisaltmaMatch[1];
      madde = madde.replace(kisaltmaMatch[1], '').trim();
    } else if (numaraMatch) {
      kanunAdi = kanunNumaralari[numaraMatch[1]] || numaraMatch[1] + ' sayılı Kanun';
      madde = madde.replace(numaraMatch[1], '').trim();
    } else {
      // Uzun kanun isimlerini kısalt
      const sayiliKanunMatch = madde.match(/(\d+)\s+SAYILI/i);
      if (sayiliKanunMatch) {
        kanunAdi = kanunNumaralari[sayiliKanunMatch[1]] || sayiliKanunMatch[1] + ' sayılı Kanun';
        madde = madde.replace(/.*SAYILI/i, '').trim();
      }
    }
    
    // Madde, fıkra ve bent numaralarını ayır ve standartlaştır
    const maddeFikraBentMatch = madde.match(/(\d+)(?:\/(\d+))?(?:-([a-z]))?/i);
    if (maddeFikraBentMatch) {
      const maddeNo = maddeFikraBentMatch[1];
      const fikraNo = maddeFikraBentMatch[2] || '';
      const bentNo = maddeFikraBentMatch[3] ? maddeFikraBentMatch[3].toLowerCase() : '';
      
      madde = `${maddeNo}${fikraNo ? '/' + fikraNo : ''}${bentNo ? '-' + bentNo : ''}`;
    }
    
    // Eğer kanun adı bir kısaltma ise, kısaltmayı kullan
    const kisaltma = Object.keys(kanunKisaltmalari).find(k => kanunKisaltmalari[k] === kanunAdi);
    if (kisaltma) {
      kanunAdi = kisaltma;
    }
    
    return `${kanunAdi} m. ${madde}`.trim();
  }
  
  export function normalizeKanunMaddeleri(maddeler) {
    return [...new Set(maddeler.map(normalizeKanunMaddesi))];
  }