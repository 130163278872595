import axios from 'axios';

const API_URL = 'http://localhost:5000/api/temyiz-talepleri';

const handleError = (error) => {
  console.error('API Hatası:', error);
  if (error.response) {
    throw new Error(error.response.data.message || 'Bir hata oluştu');
  } else if (error.request) {
    throw new Error('Sunucuya ulaşılamadı');
  } else {
    throw new Error('İstek oluşturulurken bir hata oluştu');
  }
};

export const createTemyizTalebi = async (takrirId, talepData) => {
  try {
    const response = await axios.post(API_URL, { takrirId, talepData });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getTemyizTalepleri = async (takrirId) => {
  try {
    const response = await axios.get(`${API_URL}/${takrirId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateTemyizTalebi = async (id, talepData) => {
  console.log('Gönderilen güncelleme verisi:', talepData);
  try {
    const response = await axios.put(`${API_URL}/${id}`, talepData);
    console.log('Alınan cevap:', response.data);
    return response.data;
  } catch (error) {
    console.error('Güncelleme hatası:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteTemyizTalebi = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
