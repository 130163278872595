import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';

export const createDelillerTable = (delillerInfo) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: 'single', size: 1, color: BORDER_COLOR },
      bottom: { style: 'single', size: 1, color: BORDER_COLOR },
      left: { style: 'single', size: 1, color: BORDER_COLOR },
      right: { style: 'single', size: 1, color: BORDER_COLOR },
      insideHorizontal: { style: 'single', size: 1, color: BORDER_COLOR },
      insideVertical: { style: 'single', size: 1, color: BORDER_COLOR },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [
                new TextRun({
                  text: "Deliller",
                  bold: true
                })
              ]
            })],
            shading: { fill: HEADER_FILL_COLOR },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph(delillerInfo || "")] }),
        ],
      }),
    ],
  });
};