import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { turkishToLower } from '../../utils/stringUtils';

const SablonArama = ({ aramaMetni, setAramaMetni }) => {
  const handleInputChange = (e) => {
    setAramaMetni(turkishToLower(e.target.value));
  };

  return (
    <div className="relative flex-grow max-w-3xl">
      <input
        type="text"
        placeholder="Şablon ara..."
        className="w-full p-3 pl-10 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-lg"
        value={aramaMetni}
        onChange={handleInputChange}
      />
      <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-400" />
    </div>
  );
};

export default SablonArama;
