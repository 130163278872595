import React, { useState, useMemo, useCallback } from 'react';
import { ChevronDownIcon, ChevronRightIcon, FolderIcon, MagnifyingGlassIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import YeniKategoriModal from './YeniKategoriModal.js';
import KategoriKlasorGorunumu from './KategoriKlasorGorunumu.js';
import { turkishToLower } from '../../utils/stringUtils';
import { toast } from 'react-toastify';
import { handleKategoriEkleVeyaGuncelle, silKategori } from './agac/kategoriIslemleri.js';
import KategoriContextMenu from './KategoriContextMenu.js'; // Yeni bileşeni import edin
import { ekleYeniSablon } from './sablonUtils';

const KategoriAgaci = ({ kategoriler, setKategoriler, secilenKategoriler, setSecilenKategoriler, onKategoriEkle, onYeniSablonEkle }) => {
  const [acikKategoriler, setAcikKategoriler] = useState({});
  const [modalAcik, setModalAcik] = useState(false);
  const [yeniKategoriParent, setYeniKategoriParent] = useState(null);
  const [duzenlenecekKategori, setDuzenlenecekKategori] = useState(null);
  const [aramaMetni, setAramaMetni] = useState('');
  const [ustKategoriler, setUstKategoriler] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [klasorGorunumu, setKlasorGorunumu] = useState(false);

  const toggleKategori = (kategori) => {
    setAcikKategoriler(prev => ({...prev, [kategori]: !prev[kategori]}));
  };

  const tumAltKategorileriGetir = (kategori) => {
    let altKategoriler = [kategori.isim];
    if (kategori.altKategoriler) {
      kategori.altKategoriler.forEach(alt => {
        altKategoriler = altKategoriler.concat(tumAltKategorileriGetir(alt));
      });
    }
    return altKategoriler;
  };

  const handleKategoriSecim = (kategori, altKategoriler) => {
    setSecilenKategoriler(prev => {
      let yeniSecim = [...prev];
      const tumAltKategoriler = tumAltKategorileriGetir({ isim: kategori, altKategoriler });
      
      if (yeniSecim.includes(kategori)) {
        // Kategori ve tüm alt kategorileri kaldır
        yeniSecim = yeniSecim.filter(k => !tumAltKategoriler.includes(k));
      } else {
        // Kategori ve tüm alt kategorileri ekle
        tumAltKategoriler.forEach(k => {
          if (!yeniSecim.includes(k)) {
            yeniSecim.push(k);
          }
        });
      }
      return yeniSecim;
    });
  };

  const tumAltKategoriler = (kategoriler) => {
    return kategoriler.reduce((acc, kategori) => {
      acc.push(kategori.isim);
      return acc.concat(tumAltKategoriler(kategori.altKategoriler || []));
    }, []);
  };

  const handleKategoriSil = (kategoriIsim) => {
    setKategoriler(prevKategoriler => silKategori(prevKategoriler, kategoriIsim));
    setSecilenKategoriler(prev => prev.filter(k => k !== kategoriIsim));

    // Silinen kategorinin alt kategorilerini de seçilmiş kategorilerden kaldır
    const tumAltKategoriler = tumAltKategorileriGetir({ isim: kategoriIsim, altKategoriler: [] });
    setSecilenKategoriler(prev => prev.filter(k => !tumAltKategoriler.includes(k)));

    // Silinen kategoriyle ilgili açık/kapalı durumunu temizle
    setAcikKategoriler(prev => {
      const yeniAcikKategoriler = { ...prev };
      delete yeniAcikKategoriler[kategoriIsim];
      return yeniAcikKategoriler;
    });

    toast.success(`"${kategoriIsim}" kategorisi başarıyla silindi.`);
  };

  const handleKategoriDuzenle = (kategori) => {
    setDuzenlenecekKategori(kategori.isim);
    setModalAcik(true);
  };

  const getUstKategoriler = (kategoriIsim, kategoriler, ustKategoriler = []) => {
    for (let kategori of kategoriler) {
      if (kategori.isim === kategoriIsim) {
        return ustKategoriler;
      }
      if (kategori.altKategoriler && kategori.altKategoriler.length > 0) {
        const bulunan = getUstKategoriler(kategoriIsim, kategori.altKategoriler, [...ustKategoriler, kategori.isim]);
        if (bulunan) return bulunan;
      }
    }
    return null;
  };

  const handleYeniKategoriEkle = (kategori = null) => {
    if (kategori) {
      const ustKategoriler = getUstKategoriler(kategori.isim, kategoriler);
      setYeniKategoriParent(kategori.isim);
      setUstKategoriler(ustKategoriler || []);
    } else {
      setYeniKategoriParent(null);
      setUstKategoriler([]);
    }
    setModalAcik(true);
  };

  const handleContextMenu = useCallback((e, kategori) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      kategori
    });
  }, []);

  const closeContextMenu = useCallback(() => {
    setContextMenu(null);
  }, []);

  const renderKategori = (kategori, altKategoriler = [], derinlik = 0, ustKategoriler = []) => {
    const ikonBoyutu = 'h-5 w-5';
    const yaziBoyutu = 'text-sm';

    const isAramaEslesmesi = aramaMetni && turkishToLower(kategori.isim).includes(turkishToLower(aramaMetni));

    return (
      <div 
        key={`${ustKategoriler.join('-')}-${kategori.isim}`} 
        className={derinlik === 0 ? "" : "ml-0"} // Ana başlıklar için girinti yok
      >
        <div 
          className="flex items-center group hover:bg-gray-100 rounded-lg p-2 transition-colors duration-150"
          onContextMenu={(e) => handleContextMenu(e, kategori)}
        >
          <input
            type="checkbox"
            checked={secilenKategoriler.includes(kategori.isim)}
            onChange={() => handleKategoriSecim(kategori.isim, altKategoriler)}
            className={`form-checkbox ${ikonBoyutu} text-blue-600 rounded focus:ring-blue-400 mr-2`}
          />
          <div 
            className={`flex items-center cursor-pointer ${
              secilenKategoriler.includes(kategori.isim) 
                ? 'text-blue-600 font-medium' 
                : isAramaEslesmesi 
                  ? 'text-green-600 font-medium'
                  : 'text-gray-700'
            }`}
            onClick={() => toggleKategori(kategori.isim)}
          >
            {altKategoriler.length > 0 ? (
              <button className="mr-1 focus:outline-none">
                {acikKategoriler[kategori.isim] 
                  ? <ChevronDownIcon className={`${ikonBoyutu} text-gray-400`} /> 
                  : <ChevronRightIcon className={`${ikonBoyutu} text-gray-400`} />
                }
              </button>
            ) : (
              <MinusIcon className={`${ikonBoyutu} text-gray-400 mr-1`} />
            )}
            {/* <FolderIcon className={`${ikonBoyutu} text-gray-400 mr-2 flex-shrink-0`} /> */}
            <FolderIcon className={`${ikonBoyutu} text-yellow-500 mr-2 flex-shrink-0`} />
            <span className={`${yaziBoyutu} truncate`}>{kategori.isim}</span>
          </div>
        </div>
        {acikKategoriler[kategori.isim] && altKategoriler.length > 0 && (
          <div className="ml-4"> {/* Alt kategoriler için girinti */}
            {altKategoriler.map(alt => renderKategori(alt, alt.altKategoriler || [], derinlik + 1, [...ustKategoriler, kategori.isim]))}
          </div>
        )}
      </div>
    );
  };

  const handleKategoriEkleVeyaGuncelleWrapper = (yeniKategoriAdi, parentKategori, eskiKategoriAdi) => {
    const yeniKategoriler = handleKategoriEkleVeyaGuncelle(kategoriler, yeniKategoriAdi, parentKategori, eskiKategoriAdi);
    setKategoriler(yeniKategoriler);

    if (eskiKategoriAdi) {
      setSecilenKategoriler(prev => 
        prev.map(k => k === eskiKategoriAdi ? yeniKategoriAdi : k)
      );
    }

    return yeniKategoriler !== kategoriler; // İşlem başarılı oldu mu?
  };

  const filtrelenmiKategoriler = useMemo(() => {
    if (!aramaMetni) return kategoriler;

    const filtreleme = (kategoriListesi, ustKategoriler = []) => {
      return kategoriListesi.reduce((acc, kategori) => {
        const isimUyuyor = turkishToLower(kategori.isim).includes(turkishToLower(aramaMetni));
        const altKategorilerSonuc = kategori.altKategoriler ? filtreleme(kategori.altKategoriler, [...ustKategoriler, kategori.isim]) : [];
        
        if (isimUyuyor) {
          // Eğer kategori ismi uyuyorsa, tüm alt kategorileri de ekle
          acc.push({
            ...kategori,
            ustKategoriler,
            altKategoriler: kategori.altKategoriler || []
          });
        } else if (altKategorilerSonuc.length > 0) {
          // Eğer alt kategorilerden biri uyuyorsa, bu kategoriyi ve uygun alt kategorileri ekle
          acc.push({
            ...kategori,
            ustKategoriler,
            altKategoriler: altKategorilerSonuc
          });
        }
        return acc;
      }, []);
    };

    return filtreleme(kategoriler);
  }, [kategoriler, aramaMetni]);

  const handleSablonEklendi = (yeniSablon) => {
    // Burada yeni şablonu eklemek için gerekli işlemleri yapabilirsiniz
    // Örneğin, bir API çağrısı yapabilir veya yerel state'i güncelleyebilirsiniz
    const eklenenSablon = ekleYeniSablon(yeniSablon);
    // Eğer gerekiyorsa, eklenen şablonla ilgili başka işlemler yapabilirsiniz
    console.log('Yeni şablon eklendi:', eklenenSablon);
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Kategoriler</h2>
        <div className="flex items-center space-x-4">
          <Switch
            checked={klasorGorunumu}
            onChange={setKlasorGorunumu}
            className={`${
              klasorGorunumu ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
          >
            <span className="sr-only">Klasör görünümünü etkinleştir</span>
            <span
              className={`${
                klasorGorunumu ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
          <button 
            onClick={() => {
              setYeniKategoriParent(null);
              setModalAcik(true);
            }}
            className="text-gray-400 hover:text-green-500 focus:outline-none"
          >
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Kategori ara..."
          value={aramaMetni}
          onChange={(e) => setAramaMetni(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>
      <div className="mb-4 flex items-center hover:bg-gray-100 rounded-lg p-2 transition-colors duration-150">
        <input
          type="checkbox"
          checked={secilenKategoriler.includes('Tümü')}
          onChange={() => handleKategoriSecim('Tümü', tumAltKategoriler(kategoriler))}
          className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-400"
        />
        <FolderIcon className="h-5 w-5 text-gray-400 ml-2 mr-2" />
        <span className="text-sm font-medium text-gray-700 truncate flex-grow">Tümü</span>
      </div>
      <div className="mb-4">
        <div className="flex items-center hover:bg-gray-100 rounded-lg p-2 transition-colors duration-150">
          <input
            type="checkbox"
            checked={secilenKategoriler.includes('Kategorisiz')}
            onChange={() => handleKategoriSecim('Kategorisiz', [])}
            className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-400"
          />
          <FolderIcon className="h-5 w-5 text-gray-400 ml-2 mr-2" />
          {/* <FolderIcon className="h-5 w-5 text-yellow-500 mr-2" /> */}
          <span className="text-sm font-medium text-gray-700">Kategorisiz</span>
        </div>
        <div className="border-b border-gray-200 my-2"></div> {/* İnce çizgi eklendi */}
      </div>
      {klasorGorunumu ? (
        <KategoriKlasorGorunumu
          kategoriler={filtrelenmiKategoriler}
          secilenKategoriler={secilenKategoriler}
          handleKategoriSecim={handleKategoriSecim}
          handleContextMenu={handleContextMenu}
          getUstKategoriler={getUstKategoriler}
          onYeniKategoriEkle={handleYeniKategoriEkle}
          onYeniSablonEkle={onYeniSablonEkle}
        />
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <div className="min-w-max">
            {filtrelenmiKategoriler.map(kat => (
              <div key={`main-${kat.ustKategoriler ? kat.ustKategoriler.join('-') : ''}-${kat.isim}`}>
                {renderKategori(kat, kat.altKategoriler || [], 0, [])}
              </div>
            ))}
          </div>
        </div>
      )}
      <YeniKategoriModal 
        isOpen={modalAcik}
        onClose={() => {
          setModalAcik(false);
          setDuzenlenecekKategori(null);
          setYeniKategoriParent(null);
          setUstKategoriler([]);
        }}
        onKategoriEkle={handleKategoriEkleVeyaGuncelleWrapper}
        parentKategori={yeniKategoriParent}
        duzenlenecekKategori={duzenlenecekKategori}
        ustKategoriler={ustKategoriler}
        kategoriler={kategoriler}
      />
      {contextMenu && (
        <KategoriContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          kategori={contextMenu.kategori}
          onClose={closeContextMenu}
          onDuzenle={handleKategoriDuzenle}
          onSil={handleKategoriSil}
          onYeniEkle={handleYeniKategoriEkle}
        />
      )}
    </div>
  );
};

export default KategoriAgaci;