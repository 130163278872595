import React, { useState, useEffect } from 'react';

const TetkikHakimiGorusuCard = ({ info, onUpdate }) => {
  const [localInfo, setLocalInfo] = useState(info);

  useEffect(() => {
    setLocalInfo(info);
  }, [info]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleBlur = () => {
    onUpdate(localInfo);
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {/* <h3 className="text-xl font-bold mb-4">TETKİK HÂKİMİ GÖRÜŞÜ</h3> */}
      <textarea
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        name="tetkikHakimiGorusu"
        value={localInfo.tetkikHakimiGorusu || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        rows="10"
        placeholder="İncelenmeksizin iade, ret, reddin onanması, düşme, onama, düzeltilerek onama ya da bozma (yazdığımız karar her ne ise aynen ya da özetleyerek)

Ayrıca müzakere sırasında heyetin dikkatine sunulması gereken diğer hususlar ve heyetin değerlendirmesi !!!

(Örn; Eylemin TCK'nın 43.m'si kapsamında değerlendirilip değerlendirilmeyeceği heyete arz edildi, iki ayrı suç oluştuğuna ilişkin kabulde isabetsizlik görülmedi !)"
      />
    </div>
  );
};

export default TetkikHakimiGorusuCard;