import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, BorderStyle, AlignmentType } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';
import { formatDate } from './utils';
import { parseHtmlContent } from './htmlParser';

export const createSummaryTable = (summary) => {
	if (!summary || typeof summary !== 'object') {
		console.warn('Geçersiz özet bilgisi:', summary);
		return createEmptyTable('Özet bilgisi mevcut değil');
	}

	const isOzet = summary.type === 'özet';
	let leftHeader, rightHeader;

	if (isOzet) {
		// Eğer kategori "Diğer" ise ve customCategory varsa, onu kullan
		leftHeader = summary.category === 'Diğer' && summary.customCategory
			? summary.customCategory
			: summary.category;
		rightHeader = formatDate(summary.date);
	} else {
		// Burada summary.type'ı kullanarak doğru kategoriyi belirliyoruz
		let category;
		switch(summary.type) {
			case 'sanık':
				category = 'Sanık';
				break;
			case 'mağdur':
				category = 'Mağdur';
				break;
			case 'tanık':
				category = 'Tanık';
				break;
			default:
				category = summary.category;
		}
		leftHeader = `${category} ${summary.name}`;
		rightHeader = `${summary.stage} Beyanı (${formatDate(summary.date)})`;
	}

	return new Table({
		width: {
			size: 100,
			type: WidthType.PERCENTAGE,
		},
		borders: {
			top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
			bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
			left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
			right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
			insideHorizontal: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
			insideVertical: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
		},
		rows: [
			new TableRow({
				children: [
					new TableCell({
						children: [new Paragraph({
							children: [new TextRun({
								text: leftHeader,
								bold: true,
							})],
							alignment: AlignmentType.LEFT,
						})],
						width: {
							size: 50,
							type: WidthType.PERCENTAGE,
						},
						margins: {
							left: 100,
							right: 100,
						},
						shading: {
							fill: HEADER_FILL_COLOR,
						},
					}),
					new TableCell({
						children: [new Paragraph({
							children: [new TextRun({
								text: rightHeader,
								bold: true,
							})],
							alignment: AlignmentType.RIGHT,
						})],
						width: {
							size: 50,
							type: WidthType.PERCENTAGE,
						},
						margins: {
							left: 100,
							right: 100,
						},
						shading: {
							fill: HEADER_FILL_COLOR,
						},
					}),
				],
			}),
			new TableRow({
				children: [
					new TableCell({
						children: parseHtmlContent(summary.content),
						columnSpan: 2,
						margins: {
							top: 100,
							bottom: 100,
							left: 100,
							right: 100,
						},
					}),
				],
			}),
		],
	});
};

function createEmptyTable(message) {
  return new Table({
    width: { size: 100, type: WidthType.PERCENTAGE },
    rows: [new TableRow({
      children: [new TableCell({
        children: [new Paragraph({ text: message })],
      })],
    })],
  });
}