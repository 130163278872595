export function cezaArttir(ceza, oran) {
  if (!oran || !oran.rate) {
    console.log('Invalid oran:', oran);
    return ceza;
  }

  const { year, month, day, fine } = ceza;
  const { numerator, denominator } = oran.rate;

  console.log('Artırım oranı:', numerator, '/', denominator);

  // Yıl Hesabı
  const modyil = year % denominator;
  const yildanarttirilacak0 = Math.floor(year / denominator) * numerator;
  const yildanarttirilacak1 = Math.floor((modyil * 12) / denominator) * numerator;
  const yildanarttirilipyilaeklenecek = Math.floor(yildanarttirilacak1 / 12);
  const yildanarttirilipayaeklenecek = yildanarttirilacak1 % 12;
  const yildanarttirilipguneeklenecek0 = (modyil * 12) % denominator;
  const yildanarttirilipguneeklenecek1 = Math.floor((yildanarttirilipguneeklenecek0 * 30) / denominator) * numerator;
  const yildanartansonucyil = yildanarttirilacak0 + yildanarttirilipyilaeklenecek;

  // Ay Hesabı
  const modAy = month % denominator;
  const aydanArttirilacak0 = Math.floor(month / denominator) * numerator;
  const aydanArttirilacak1 = Math.floor((modAy * 30) / denominator) * numerator;
  const aydanArttirilipAyaeklenecek = Math.floor(aydanArttirilacak1 / 30);
  const aydanArttirilipGuneeklenecek = aydanArttirilacak1 % 30;
  const aydanartansonucay = aydanArttirilacak0 + aydanArttirilipAyaeklenecek + yildanarttirilipayaeklenecek;

  // Gün Hesabı
  const gundenarttirilcak = Math.floor(day / denominator) * numerator + aydanArttirilipGuneeklenecek;
  const gundenAyaekelencek = Math.floor((day + gundenarttirilcak + yildanarttirilipguneeklenecek1) / 30);
  const gundenGuneekelenceksonuc = (day + gundenarttirilcak + yildanarttirilipguneeklenecek1) % 30;

  const sonuc1 = year + yildanartansonucyil;
  const sonuc2 = month + aydanartansonucay + gundenAyaekelencek;
  const sonuc3 = gundenGuneekelenceksonuc;

   // Fine Hesabı (bağımsız olarak)
   const arttirilmisFine = fine + Math.floor((fine * numerator) / denominator);

  return {
    year: sonuc1,
    month: sonuc2,
    day: sonuc3,
    fine: arttirilmisFine
  };
}

export function cezaEksilt(ceza, oran) {
  if (!oran || !oran.rate) {
    console.log('Invalid oran:', oran);
    return ceza;
  }

  const { year, month, day, fine } = ceza;
  const { numerator, denominator } = oran.rate;

  console.log('Eksiltme oranı:', numerator, '/', denominator);

  // Yıl Hesabı
  const modyil = year % denominator;
  const yildanartan = modyil * 12;
  const yeniyil = year - modyil;

  // Ay Hesabı
  const toplamay = month + yildanartan;
  const modAy = toplamay % denominator;
  const aydanartan = modAy * 30;
  const yeniay = toplamay - modAy;

  const toplamgun = day + aydanartan;

  // Çıkarma işlemi
  const gundencikacak = Math.ceil((toplamgun / denominator) * numerator);
  const aydancikacak = Math.floor(yeniay / denominator) * numerator;
  let yildancikacak = Math.floor(yeniyil / denominator) * numerator;

  if (yildancikacak < 0) {
    yildancikacak = 0;
  }

  let sonuc1 = year;
  let sonuc2 = month;
  let sonuc3 = day;

  // Gün hesabı
  while (sonuc3 - gundencikacak < 0) {
    sonuc2 -= 1;
    sonuc3 += 30;
  }
  sonuc3 -= gundencikacak;

  // Ay hesabı
  while (sonuc2 - aydancikacak < 0) {
    sonuc1 -= 1;
    sonuc2 += 12;
  }
  sonuc2 -= aydancikacak;

  // Yıl hesabı
  sonuc1 -= yildancikacak;

  // Fine Hesabı (bağımsız olarak)
  const eksiltilmisFine = Math.max(0, fine - Math.ceil((fine * numerator) / denominator));

  return {
    year: sonuc1,
    month: sonuc2,
    day: sonuc3,
    fine: eksiltilmisFine
  };
}

export function formatCeza(ceza) {
  const parts = [];
  if (ceza.year > 0) parts.push(`${ceza.year}Y`);
  if (ceza.month > 0) parts.push(`${ceza.month}A`);
  if (ceza.day > 0) parts.push(`${ceza.day}G`);
  if (ceza.fine > 0) parts.push(`${ceza.fine}APC`);
  return parts.join(' ');
}