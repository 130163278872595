import React from 'react';

function SebepKarti({ sebep, onSil }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4">
      <h3 className="font-semibold text-lg mb-2">{sebep.tip}</h3>
      {sebep.baslangic && (
        <p className="text-sm text-gray-600 mb-1">
          Başlangıç: {sebep.baslangic.toLocaleDateString()}
        </p>
      )}
      {sebep.bitis && (
        <p className="text-sm text-gray-600 mb-1">
          Bitiş: {sebep.bitis.toLocaleDateString()}
        </p>
      )}
      {sebep.tarih && (
        <p className="text-sm text-gray-600 mb-1">
          Tarih: {sebep.tarih.toLocaleDateString()}
        </p>
      )}
      <p className="text-sm text-gray-700 mb-2">{sebep.aciklama}</p>
      <button 
        onClick={onSil}
        className="bg-red-500 hover:bg-red-600 text-white text-sm font-bold py-1 px-2 rounded transition duration-300 ease-in-out"
      >
        Sil
      </button>
    </div>
  );
}

export default SebepKarti;

