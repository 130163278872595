import { Table, TableRow, TableCell, Paragraph, WidthType } from 'docx';

export const createOnIncelemeTable = (onIncelemeInfo) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph("Ön İncelemede Tespit Edilen Hususlar")],
            columnSpan: 2,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(onIncelemeInfo)],
            columnSpan: 2,
          }),
        ],
      }),
    ],
  });
};