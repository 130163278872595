import React, { useState, useEffect } from 'react';
import { Edit2, Trash2, PlusCircle } from 'lucide-react';
import EditColumnModal from './modals/EditColumnModal';
import { formatCeza } from '../utils/cezaHesaplamalari';

const PredefinedColumnsManager = () => {
  const [predefinedColumns, setPredefinedColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingColumn, setEditingColumn] = useState(null);

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem('predefinedColumns')) || [];
    setPredefinedColumns(storedColumns);
  }, []);

  const handleAdd = () => {
    setEditingColumn(null);
    setIsModalOpen(true);
  };

  const handleEdit = (column) => {
    setEditingColumn(column);
    setIsModalOpen(true);
  };

  const handleDelete = (columnId) => {
    const updatedColumns = predefinedColumns.filter(column => column.id !== columnId);
    setPredefinedColumns(updatedColumns);
    localStorage.setItem('predefinedColumns', JSON.stringify(updatedColumns));
  };

  const handleSave = (editedColumn) => {
    // Kolon tipine göre gereksiz alanları temizle
    const cleanedColumn = cleanColumnData(editedColumn);

    let updatedColumns;
    if (editingColumn) {
      // Mevcut kolonu güncelle
      updatedColumns = predefinedColumns.map(column =>
        column.id === editingColumn.id ? cleanedColumn : column
      );
    } else {
      // Yeni kolon ekle
      updatedColumns = [...predefinedColumns, { ...cleanedColumn, id: Date.now() }];
    }
    setPredefinedColumns(updatedColumns);
    localStorage.setItem('predefinedColumns', JSON.stringify(updatedColumns));
    setIsModalOpen(false);
  };

  const cleanColumnData = (column) => {
    const baseColumn = {
      id: column.id,
      isSingleLine: column.isSingleLine,
      isTwoLineText: column.isTwoLineText,
      isBasePenalty: column.isBasePenalty,
    };

    if (column.isSingleLine) {
      return {
        ...baseColumn,
        content: column.content || '',
      };
    } else if (column.isTwoLineText) {
      return {
        ...baseColumn,
        topContent: column.topContent || '',
        bottomContent: column.bottomContent || '',
      };
    } else {
      return {
        ...baseColumn,
        law: column.law || '',
        content: column.content || '',
        penalty: column.penalty || null,
        discountRate: column.discountRate || null,
      };
    }
  };

  const formatDiscountRate = (discountRate) => {
    if (!discountRate || discountRate.rate.numerator === 0 || discountRate.rate.denominator === 0) {
      return '';
    }
    const { type, rate } = discountRate;
    const sign = type === 'indirim' ? '-' : '+';
    return `(${rate.numerator}/${rate.denominator})${sign}`;
  };

  const renderColumnContent = (column) => {
    if (column.isSingleLine) {
      return column.content;
    } else if (column.isTwoLineText) {
      return (
        <>
          <div>{column.topContent || ''}</div>
          <div>{column.bottomContent || ''}</div>
        </>
      );
    } else {
      const topContent = column.law ? (
        <>
          {column.law}
          {!column.isBasePenalty && formatDiscountRate(column.discountRate) && (
            <span className="ml-1 text-gray-500">
              {formatDiscountRate(column.discountRate)}
            </span>
          )}
        </>
      ) : (
        column.content || 'Boş Kolon'
      );

      const bottomContent = column.isBasePenalty || column.penalty ? (
        <div dangerouslySetInnerHTML={{ __html: formatCeza(column.penalty).replace(/(\d+)([YAGAPC])/g, '<strong>$1</strong>$2 ').trim() }} />
      ) : (
        column.content || ''
      );

      return (
        <>
          <div>{topContent}</div>
          <div>{bottomContent}</div>
        </>
      );
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-700">Ön Tanımlı Kolonlar</h2>
      <button
        onClick={handleAdd}
        className="mb-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300 flex items-center"
      >
        <PlusCircle className="h-4 w-4 mr-2" /> Yeni Kolon Ekle
      </button>
      <ul className="space-y-2">
        {predefinedColumns.map((column) => (
          <li key={column.id} className="flex justify-between items-center bg-gray-50 p-2 rounded">
            <div className="truncate max-w-xs">
              {renderColumnContent(column)}
            </div>
            <div className="flex items-center">
              <span className="text-xs text-gray-500 mr-2">
                {column.isSingleLine ? 'Tek Satır' : column.isTwoLineText ? 'İki Satır' : 'Standart'}
              </span>
              <button onClick={() => handleEdit(column)} className="mr-2 text-blue-500">
                <Edit2 className="h-4 w-4" />
              </button>
              <button onClick={() => handleDelete(column.id)} className="text-red-500">
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <EditColumnModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        column={editingColumn}
        onSave={handleSave}
        prevPenalty={null}
      />
    </div>
  );
};

export default PredefinedColumnsManager;