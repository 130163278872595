import React, { useState, useEffect, useCallback } from 'react';
import { handleNumberWithSlashChange } from '../../utils/formatHelpers.js';
import Select from 'react-select';
import { useGeneralInfo } from '../../contexts/GeneralInfoContext';

const GeneralInfoCard = ({ info, onUpdate }) => {
  const { generalInfo, updateGeneralInfo } = useGeneralInfo();
  const [localInfo, setLocalInfo] = useState(info);
  const [crimeOptions, setCrimeOptions] = useState([]);

  useEffect(() => {
    const storedCrimes = JSON.parse(localStorage.getItem('crimes')) || [];
    setCrimeOptions(storedCrimes);
  }, []);

  useEffect(() => {
    if (JSON.stringify(info) !== JSON.stringify(localInfo)) {
      setLocalInfo(info);
    }
  }, [info]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === 'esasNo' || name === 'kararNo') {
      handleNumberWithSlashChange(value, 4, (formattedValue) => {
        const updatedInfo = { ...localInfo, [name]: formattedValue };
        setLocalInfo(updatedInfo);
        onUpdate(updatedInfo);
      });
    } else {
      const updatedInfo = { ...localInfo, [name]: value };
      setLocalInfo(updatedInfo);
      onUpdate(updatedInfo);
      updateGeneralInfo(updatedInfo);

      if (name === 'sucTarihi') {
        console.log('Suç tarihi güncellendi:', value);
      }
    }
  }, [localInfo, onUpdate, updateGeneralInfo]);

  const handleCrimesChange = useCallback((selectedOptions) => {
    const updatedInfo = { ...localInfo, crimes: selectedOptions };
    setLocalInfo(updatedInfo);
    onUpdate(updatedInfo);
  }, [localInfo, onUpdate]);

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mahkemesi">
          Mahkemesi
        </label>
        <input
          className="shadow-sm border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          type="text"
          id="mahkemesi"
          name="mahkemesi"
          value={localInfo.mahkemesi || ''}
          onChange={handleChange}
          placeholder="Örn: İstanbul 5. Ağır Ceza Mahkemesi"
        />
      </div>
      
      {/* Esas No, Karar No ve Karar Tarihi tek satırda */}
      <div className="flex space-x-4">
        <div className="w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="esasNo">
            Esas No
          </label>
          <input
            className="shadow-sm border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="text"
            id="esasNo"
            name="esasNo"
            value={localInfo.esasNo || ''}
            onChange={handleChange}
            placeholder="Örn: 2023/123"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="kararNo">
            Karar No
          </label>
          <input
            className="shadow-sm border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="text"
            id="kararNo"
            name="kararNo"
            value={localInfo.kararNo || ''}
            onChange={handleChange}
            placeholder="Örn: 2023/456"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="kararTarihi">
            Karar Tarihi
          </label>
          <input
            className="shadow-sm border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="date"
            id="kararTarihi"
            name="kararTarihi"
            value={localInfo.kararTarihi || ''}
            onChange={handleChange}
          />
        </div>
      </div>
      
      {/* Suç Tarihi ve Suçlar yan yana */}
      <div className="flex space-x-4">
        <div className="w-1/2">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sucTarihi">
            Suç Tarihi
          </label>
          <input
            className="shadow-sm border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="date"
            id="sucTarihi"
            name="sucTarihi"
            value={localInfo.sucTarihi || ''}
            onChange={handleChange}
          />
        </div>
        <div className="w-1/2">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="crimes">
            Suçlar
          </label>
          <Select
            isMulti
            options={crimeOptions}
            value={localInfo.crimes || []}
            onChange={handleCrimesChange}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Suçları seçin"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(GeneralInfoCard);