import React, { useState } from 'react';
import { X } from 'lucide-react';

const margins = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5];

const WordSettingsCard = ({ isOpen, onClose, onSave }) => {
  const [topMargin, setTopMargin] = useState(1.5);
  const [bottomMargin, setBottomMargin] = useState(1.5);
  const [leftMargin, setLeftMargin] = useState(2);
  const [rightMargin, setRightMargin] = useState(2);

  if (!isOpen) return null;

  const handleSave = () => {
    onSave({ top: topMargin, bottom: bottomMargin, left: leftMargin, right: rightMargin });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-96">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Word Ayarları</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block mb-1">Üst Kenar Boşluğu (cm)</label>
            <select
              value={topMargin}
              onChange={(e) => setTopMargin(parseFloat(e.target.value))}
              className="w-full p-2 border rounded"
            >
              {margins.map((margin) => (
                <option key={margin} value={margin}>{margin}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-1">Alt Kenar Boşluğu (cm)</label>
            <select
              value={bottomMargin}
              onChange={(e) => setBottomMargin(parseFloat(e.target.value))}
              className="w-full p-2 border rounded"
            >
              {margins.map((margin) => (
                <option key={margin} value={margin}>{margin}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-1">Sol Kenar Boşluğu (cm)</label>
            <select
              value={leftMargin}
              onChange={(e) => setLeftMargin(parseFloat(e.target.value))}
              className="w-full p-2 border rounded"
            >
              {margins.map((margin) => (
                <option key={margin} value={margin}>{margin}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-1">Sağ Kenar Boşluğu (cm)</label>
            <select
              value={rightMargin}
              onChange={(e) => setRightMargin(parseFloat(e.target.value))}
              className="w-full p-2 border rounded"
            >
              {margins.map((margin) => (
                <option key={margin} value={margin}>{margin}</option>
              ))}
            </select>
          </div>
        </div>
        <button
          onClick={handleSave}
          className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300"
        >
          Kaydet
        </button>
      </div>
    </div>
  );
};

export default WordSettingsCard;