import React from 'react';

function SonucGosterimi({ sonuc }) {
  if (!sonuc) return null;

  return (
    <div className="mt-4 p-4 bg-gray-100 rounded">
      <h2 className="text-lg font-semibold mb-2">Zamanaşımı Sonuçları</h2>
      <p>Olağan Zamanaşımı Tarihi: {sonuc.olaganZamanAsimi.toLocaleDateString()}</p>
      <p>Olağanüstü Zamanaşımı Tarihi: {sonuc.olaganustuZamanAsimi.toLocaleDateString()}</p>
    </div>
  );
}

export default SonucGosterimi;

