import React, { useState, useEffect } from 'react';

const OnIncelemeCard = ({ info, onUpdate }) => {
  const [localInfo, setLocalInfo] = useState(info);

  useEffect(() => {
    setLocalInfo(info);
  }, [info]);

  const handleChange = (e) => {
    setLocalInfo(e.target.value);
  };

  const handleBlur = () => {
    onUpdate(localInfo);
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    
      <textarea
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        value={localInfo}
        onChange={handleChange}
        onBlur={handleBlur}
        rows="6"
        placeholder="Tebligat eksiği, ek tebliğname, vs…"
      />
    </div>
  );
};

export default OnIncelemeCard;