import React, { useState, useEffect } from 'react';
import { ClipboardDocumentIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/20/solid';

const SummaryModal = ({ isOpen, onClose, summary, onRegenerate, initialPrompt, isLoading }) => {
  const [prompt, setPrompt] = useState(initialPrompt);

  useEffect(() => {
    setPrompt(initialPrompt);
  }, [initialPrompt]);

  if (!isOpen) return null;

  const handleCopyText = () => {
    navigator.clipboard.writeText(summary)
      .then(() => alert('Metin panoya kopyalandı!'))
      .catch(err => console.error('Kopyalama hatası:', err));
  };

  const handleRegenerateWithNewPrompt = () => {
    onRegenerate(prompt);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full m-4">
        <h2 className="text-xl font-bold mb-4">AI Özeti</h2>
        <div className="mb-4">
          <label htmlFor="prompt" className="block text-sm font-medium text-gray-700 mb-2">
            Özetleme Prompt'u
          </label>
          <textarea
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            rows="3"
          />
        </div>
        <div className="max-h-96 overflow-y-auto mb-4">
          <p>{summary}</p>
        </div>
        <div className="flex justify-between flex-wrap gap-2">
          <button
            onClick={handleCopyText}
            className="flex items-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300"
          >
            <ClipboardDocumentIcon className="h-5 w-5 mr-1" />
            Metni Kopyala
          </button>
          <button
            onClick={handleRegenerateWithNewPrompt}
            disabled={isLoading}
            className={`flex items-center ${
              isLoading ? 'bg-gray-400' : 'bg-yellow-500 hover:bg-yellow-600'
            } text-white px-4 py-2 rounded transition-colors duration-300`}
          >
            <ArrowPathIcon className="h-5 w-5 mr-1" />
            {isLoading ? 'Özetleniyor...' : 'Yeni Prompt ile Özetle'}
          </button>
          <button
            onClick={onClose}
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
          >
            <XMarkIcon className="h-5 w-5 mr-1" />
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;