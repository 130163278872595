import React, { createContext, useState, useContext } from 'react';

const GeneralInfoContext = createContext();

export const useGeneralInfo = () => useContext(GeneralInfoContext);

export const GeneralInfoProvider = ({ children }) => {
  const [generalInfo, setGeneralInfo] = useState({
    sucTarihi: '',
    takrirId: null,
    // ... diğer genel bilgiler ...
  });

  const updateGeneralInfo = (newInfo) => {
    setGeneralInfo(prevInfo => ({ ...prevInfo, ...newInfo }));
  };

  return (
    <GeneralInfoContext.Provider value={{ generalInfo, updateGeneralInfo }}>
      {children}
    </GeneralInfoContext.Provider>
  );
};
