export const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

export const createTableCell = (content, options = {}) => {
    const { bold = false, alignment = 'left', fill = null } = options;
    return {
        children: [
            {
                type: 'paragraph',
                children: [
                    {
                        type: 'text',
                        text: content,
                        bold: bold,
                    },
                ],
            },
        ],
        alignment: alignment,
        ...(fill && { shading: { fill: fill } }),
    };
};

export const createTableRow = (cells) => {
    return {
        children: cells,
    };
};