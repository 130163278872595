import React, { useState } from 'react';
import { cezaArttir, cezaEksilt, formatCeza } from '../utils/cezaHesaplamalari';
import { PlusIcon, MinusIcon, TrashIcon, ArrowUpIcon, CalculatorIcon } from '@heroicons/react/24/outline';

const formatCezaWithSpaces = (ceza) => {
  const parts = [];
  if (ceza.year > 0) parts.push(`${ceza.year} yıl`);
  if (ceza.month > 0) parts.push(`${ceza.month} ay`);
  if (ceza.day > 0) parts.push(`${ceza.day} gün`);
  return parts.join(' ') + ' '; // Sona bir boşluk ekliyoruz
};

const formatAPC = (fine) => {
  return fine > 0 ? `${fine} gün APC` : '';
};

const Calculation = ({ onChange, data }) => {
  const [ceza, setCeza] = useState({ year: 0, month: 0, day: 0, fine: 0 });
  const [oran, setOran] = useState({ numerator: 1, denominator: 2 });
  const [hesaplamaGecmisi, setHesaplamaGecmisi] = useState([]);
  const [customNumerator, setCustomNumerator] = useState('');
  const [customDenominator, setCustomDenominator] = useState('');

  const handleCezaChange = (e) => {
    const { name, value } = e.target;
    setCeza(prev => ({ ...prev, [name]: parseInt(value) || 0 }));
  };

  const handleOranChange = (e) => {
    const [numerator, denominator] = e.target.value.split('/').map(Number);
    setOran({ numerator, denominator });
    setCustomNumerator(numerator.toString());
    setCustomDenominator(denominator.toString());
  };

  const handleCustomRateChange = (part) => (e) => {
    const value = e.target.value;
    if (part === 'numerator') {
      setCustomNumerator(value);
    } else {
      setCustomDenominator(value);
    }
    
    const numerator = part === 'numerator' ? parseInt(value, 10) : parseInt(customNumerator, 10);
    const denominator = part === 'denominator' ? parseInt(value, 10) : parseInt(customDenominator, 10);
    
    if (numerator && denominator) {
      setOran({ numerator, denominator });
    }
  };

  const hesapla = (isArttir) => {
    let yeniCeza;
    if (isArttir) {
      yeniCeza = cezaArttir(ceza, { rate: oran });
    } else {
      yeniCeza = cezaEksilt(ceza, { rate: oran });
    }
    // Yeni hesaplamayı listenin sonuna ekliyoruz
    setHesaplamaGecmisi(prev => [...prev, { ceza, oran, sonuc: yeniCeza, isArttir }]);
    setCeza(yeniCeza);
    if (onChange) onChange(yeniCeza);
  };

  const yeniHesap = () => {
    setCeza({ year: 0, month: 0, day: 0, fine: 0 });
    setOran({ numerator: 1, denominator: 2 });
    setCustomNumerator('');
    setCustomDenominator('');
    setHesaplamaGecmisi([]);
  };

  const handleYukariTasi = (gecmisCeza, e) => {
    e.stopPropagation(); // Tıklama olayının üst öğelere yayılmasını engeller
    setCeza(gecmisCeza);
  };

  const handleGecmisSil = (index, e) => {
    e.stopPropagation(); // Tıklama olayının üst öğelere yayılmasını engeller
    setHesaplamaGecmisi(prev => prev.filter((_, i) => i !== index));
  };

  const handleInputFocus = (event) => {
    event.target.select();
  };

  return (
    <div className="space-y-8 p-4 sm:p-8 bg-gradient-to-br from-gray-50 to-gray-100 shadow-xl rounded-2xl max-w-4xl mx-auto">
      <div className="flex flex-col sm:flex-row justify-between items-center border-b border-gray-200 pb-4">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 flex items-center mb-4 sm:mb-0">
          <CalculatorIcon className="h-6 w-6 sm:h-8 sm:w-8 mr-2 text-blue-600" />
          Ceza Hesaplama
        </h2>
        <button
          onClick={yeniHesap}
          className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200 shadow-md hover:shadow-lg"
        >
          Yeni Hesap
        </button>
      </div>
      
      <div className="space-y-6">
        <div className="grid grid-cols-3 gap-6">
          {['Yıl', 'Ay', 'Gün'].map((label, index) => (
            <div key={index} className="relative">
              <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">{label}</label>
              <input
                type="number"
                name={['year', 'month', 'day'][index]}
                value={ceza[['year', 'month', 'day'][index]]}
                onChange={handleCezaChange}
                onFocus={handleInputFocus}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              />
            </div>
          ))}
        </div>
        <div className="relative">
          <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">Adli Para Cezası</label>
          <input
            type="number"
            name="fine"
            value={ceza.fine}
            onChange={handleCezaChange}
            onFocus={handleInputFocus}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative">
          <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">Oran</label>
          <div className="flex items-center space-x-2">
            <select
              value={`${oran.numerator}/${oran.denominator}`}
              onChange={handleOranChange}
              className="w-1/2 px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
            >
              {['1/1','1/2', '1/3', '1/6', '2/3', '3/4', '5/6', '1/12'].map(rate => (
                <option key={rate} value={rate}>{rate}</option>
              ))}
            </select>
            <input
              type="number"
              value={customNumerator}
              onChange={handleCustomRateChange('numerator')}
              onFocus={handleInputFocus}
              className="w-1/4 px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              placeholder="Pay"
            />
            <span className="text-2xl font-bold text-gray-500">/</span>
            <input
              type="number"
              value={customDenominator}
              onChange={handleCustomRateChange('denominator')}
              onFocus={handleInputFocus}
              className="w-1/4 px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              placeholder="Payda"
            />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => hesapla(true)}
            className="flex-1 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-200 shadow-md hover:shadow-lg flex items-center justify-center"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Arttır
          </button>
          <button
            onClick={() => hesapla(false)}
            className="flex-1 px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all duration-200 shadow-md hover:shadow-lg flex items-center justify-center"
          >
            <MinusIcon className="h-5 w-5 mr-2" />
            Azalt
          </button>
        </div>
      </div>

      {/* Sonuç ceza kartı */}
      {hesaplamaGecmisi.length > 0 && (
        <div className="mt-8 p-6 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-xl shadow-lg transform hover:scale-105 transition-all duration-300">
          <div className="text-sm text-white opacity-80 mb-2">
            {formatCezaWithSpaces(hesaplamaGecmisi[hesaplamaGecmisi.length - 1].ceza)} 
            {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].ceza.fine > 0 && 
              <span className="block text-xs opacity-70">
                {formatAPC(hesaplamaGecmisi[hesaplamaGecmisi.length - 1].ceza.fine)}
              </span>
            }
            ceza {' '}
            {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].oran.numerator}/
            {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].oran.denominator} oranında 
            {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].isArttir ? ' arttırıldı' : ' eksiltildi'} ve bulunan sonuç:
          </div>
          <div className="text-3xl font-bold text-white">
            {formatCezaWithSpaces(ceza)}
            {ceza.fine > 0 && 
              <span className="block text-lg opacity-80 mt-1">
                {formatAPC(ceza.fine)}
              </span>
            }
          </div>
        </div>
      )}

      {/* Hesaplama Geçmişi */}
      {hesaplamaGecmisi.length > 0 && (
        <div className="mt-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Hesaplama Geçmişi</h3>
          <div className="space-y-4">
            {hesaplamaGecmisi.map((hesaplama, index) => (
              <div 
                key={index} 
                className="p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-all duration-200 group relative"
              >
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-500">Temel Ceza</p>
                    <p className="text-lg font-semibold text-gray-700">
                      {formatCezaWithSpaces(hesaplama.ceza)}
                      {hesaplama.ceza.fine > 0 && 
                        <span className="block text-sm text-gray-500">
                          {formatAPC(hesaplama.ceza.fine)}
                        </span>
                      }
                    </p>
                  </div>
                  <div className="flex-1 text-center">
                    <p className="text-3xl font-bold text-indigo-600">
                      {hesaplama.oran.numerator}/{hesaplama.oran.denominator}
                    </p>
                    <div className="flex items-center justify-center mt-1">
                      {hesaplama.isArttir ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">Artırım</span>
                      ) : (
                        <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">İndirim</span>
                      )}
                    </div>
                  </div>
                  <div className="flex-1 text-right">
                    <p className="text-sm font-medium text-gray-500">Sonuç</p>
                    <p className="text-lg font-semibold text-green-600">
                      {formatCezaWithSpaces(hesaplama.sonuc)}
                      {hesaplama.sonuc.fine > 0 && 
                        <span className="block text-sm text-gray-500">
                          {formatAPC(hesaplama.sonuc.fine)}
                        </span>
                      }
                    </p>
                  </div>
                </div>
                <div className="absolute right-2 top-2 hidden group-hover:flex space-x-2">
                  <button 
                    className="p-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none transition-colors duration-200"
                    onClick={(e) => handleYukariTasi(hesaplama.ceza, e)}
                    title="Yukarı Taşı"
                  >
                    <ArrowUpIcon className="h-4 w-4" />
                  </button>
                  <button 
                    className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none transition-colors duration-200"
                    onClick={(e) => handleGecmisSil(index, e)}
                    title="Sil"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calculation;
