import React, { useState, useRef, useEffect, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Edit, Trash2, PlusCircle, ChevronDown, Save } from 'lucide-react';
import PartyInfo from './PartyInfo.js';
import AppealInfo from './AppealInfo.js';
import PenaltyCard from './PenaltyCard.js';
import EditPartyModal from '../EditPartyModal.js';
import { cezaEksilt, cezaArttir } from '../../utils/cezaHesaplamalari.js';
import { useGeneralInfo } from '../../contexts/GeneralInfoContext.js'; // Bu satırı ekleyin
import { calculateAge } from '../../utils/dateUtils.js';
import { updateParty, deleteParty, createParty } from '../../services/partyService';

const ItemTypes = {
  CARD: 'card',
  PENALTY_CARD: 'penaltyCard'
};

const templates = [
  { title: 'Ceza Kartı 1' },
  { title: 'Ceza Kartı 2' },
  { title: 'Ceza Kartı 3' },
];  

// Ön tanımlı sütunları buraya ekleyin 
const predefinedColumns = [
  { law: '141/1', penalty: { year: 1, month: 0, day: 0, fine: 0 }, discountRate: { type: 'indirim', rate: { numerator: 0, denominator: 1 } } },
  { law: '142/1-b', penalty: { year: 3, month: 0, day: 0, fine: 0 }, discountRate: { type: 'indirim', rate: { numerator: 1, denominator: 3 } } },
  // ... diğer ön tanımlı sütunlar ...
];

const PartyCard = ({ id, index, moveCard, onUpdate, onDelete, ...party }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const ref = useRef(null);

  const { generalInfo } = useGeneralInfo();
  const takrirId = generalInfo.takrirId;

  const handleUpdateParty = async (updatedParty) => {
    try {
      if (!takrirId) {
        console.error('takrirId bulunamadı');
        return;
      }
      await updateParty(id, { ...party, ...updatedParty, takrirId });
      onUpdate({ ...party, ...updatedParty });
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Taraf güncellenirken hata oluştu:', error);
    }
  };

  const handleAddColumn = (cardId, columnIndex, predefinedColumn = null) => {
    const newPenaltyInfo = [...party.penaltyInfo];
    const targetCard = newPenaltyInfo[cardId];
    
    if (!targetCard || !targetCard.columns) {
      console.error('Hedef kart veya sütunlar bulunamadı:', targetCard);
      return;
    }
    
    let newColumn;
    if (predefinedColumn) {
      const prevColumn = targetCard.columns[columnIndex];
      const prevPenalty = prevColumn ? prevColumn.penalty : { year: 0, month: 0, day: 0, fine: 0 };
      const newPenalty = calculateNewPenalty(predefinedColumn, prevPenalty);
      newColumn = { 
        ...predefinedColumn, 
        id: Date.now(), 
        width: 25, 
        height: targetCard.columns[0]?.height || 100,
        penalty: newPenalty
      };
    } else {
      newColumn = { 
        id: Date.now(), 
        law: '', 
        penalty: { year: 0, month: 0, day: 0, fine: 0 }, 
        discountRate: { type: 'indirim', rate: { numerator: 0, denominator: 1 } }, 
        width: 25, 
        height: targetCard.columns[0]?.height || 100 
      };
    }
    
    targetCard.columns = [
      ...targetCard.columns.slice(0, columnIndex + 1),
      newColumn,
      ...targetCard.columns.slice(columnIndex + 1)
    ].map(col => ({ ...col, width: 100 / (targetCard.columns.length + 1) }));
    
    handleUpdateParty({ ...party, penaltyInfo: newPenaltyInfo });
  };

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleChange = (field, value) => {
    onUpdate(field, value);
  };

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleUpdateAppealInfo = (updatedAppealInfo) => {
    handleUpdateParty({ ...party, appealInfo: updatedAppealInfo });
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    if (isValidDate(newDate)) {
      onUpdate(id, 'date', newDate);
    } else {
      console.warn('Geçersiz tarih formatı:', newDate);
    }
  };

  // isValidDate fonksiyonunu burada tanımlıyoruz
  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  const handleUpdatePenaltyInfo = (updatedPenaltyInfo) => {
    onUpdate({ ...party, penaltyInfo: updatedPenaltyInfo });
  };

  const calculateNewPenalty = (column, prevPenalty) => {
    if (!prevPenalty || !column.discountRate) return column.penalty;
  
    if (column.discountRate.type === 'indirim') {
      return cezaEksilt(prevPenalty, column.discountRate);
    } else {
      return cezaArttir(prevPenalty, column.discountRate);
    }
  };

  const handleSaveParty = (editedParty) => {
    onUpdate({ ...party, ...editedParty });
    setIsEditModalOpen(false);
  };

  const handleMovePenaltyCard = (dragIndex, hoverIndex) => {
    const newPenaltyInfo = [...party.penaltyInfo];
    const [reorderedItem] = newPenaltyInfo.splice(dragIndex, 1);
    newPenaltyInfo.splice(hoverIndex, 0, reorderedItem);
    handleUpdateParty({ ...party, penaltyInfo: newPenaltyInfo });
  };

  
  return (
    <>
      <div 
        ref={ref} 
        style={{ opacity: isDragging ? 0.5 : 1 }} 
        className="bg-white shadow-xl rounded-lg overflow-visible flex flex-col relative border border-gray-200 mb-4"
      >
        <div className="p-6 relative">
          <PartyInfo 
            party={party} 
            sucTarihi={generalInfo.sucTarihi}
            onEdit={() => setIsEditModalOpen(true)}
          />
          
          {party.appealInfo && (
            <div className="mt-4">
              <AppealInfo
                appealInfo={party.appealInfo}
                partyRole={party.role}
                onUpdate={(updatedAppealInfo) => handleUpdateParty({ ...party, appealInfo: updatedAppealInfo })}
              />
            </div>
          )}
          
          <div className="mt-4">
            <PenaltyCard
              penaltyInfo={party.penaltyInfo || []}
              onUpdate={handleUpdatePenaltyInfo}
              onAddColumn={handleAddColumn}
              predefinedColumns={predefinedColumns}
              onMovePenaltyCard={handleMovePenaltyCard}
            />
          </div>
        </div>
        
        {/* Sil butonu */}
        <button 
          onClick={async () => {
            try {
              if (!takrirId) {
                console.error('takrirId bulunamadı');
                return;
              }
              await deleteParty(id, takrirId);
              onDelete(id);
            } catch (error) {
              console.error('Taraf silinirken hata oluştu:', error);
            }
          }} 
          className="absolute bottom-4 left-4 text-red-500 hover:text-red-700 transition-colors duration-200 flex items-center gap-2 bg-white rounded-md px-3 py-2 shadow-sm border border-gray-200 hover:bg-red-50"
        >
          <Trash2 size={18} />
          <span className="text-sm font-medium">Sil</span>
        </button>
      </div>
      <EditPartyModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        party={party}
        onSave={handleSaveParty}
      />
    </>
  );
};

export default PartyCard;
