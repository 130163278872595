import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, BorderStyle, AlignmentType } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';
import { formatDate } from './utils';
import { turkishToUpper, turkishToLower } from '../../utils/stringUtils';

// İsmin her kelimesinin ilk harfini büyük yapan yardımcı fonksiyon
const capitalizeWords = (string) => {
  return string.split(' ')
    .map(word => turkishToUpper(word.charAt(0)) + turkishToLower(word.slice(1)))
    .join(' ');
};

export const createGeneralInfoTable = (generalInfo) => {
  const rows = [
    ['Mahkemesi', capitalizeWords(generalInfo.mahkemesi) || ''],
    ['Esas No', generalInfo.esasNo || ''],
    ['Karar No', generalInfo.kararNo || ''],
    ['Karar Tarihi', formatDate(generalInfo.kararTarihi) || ''],
    ['Suç Tarihi', formatDate(generalInfo.sucTarihi) || ''],
    ['Suç/lar', generalInfo.crimes && Array.isArray(generalInfo.crimes) ? generalInfo.crimes.map(s => s.label).join(', ') : ''],
  ];

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      insideHorizontal: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      insideVertical: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: 'GENEL BİLGİLER',
                bold: true,
              })],
              alignment: AlignmentType.CENTER,
            })],
            columnSpan: 2,
            shading: {
              fill: HEADER_FILL_COLOR,
            },
          }),
        ],
      }),
      ...rows.map(([label, value]) => new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: label,
                bold: true,
              })],
            })],
            width: {
              size: 30,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: value,
              })],
            })],
            width: {
              size: 70,
              type: WidthType.PERCENTAGE,
            },
          }),
        ],
      })),
    ],
  });
};