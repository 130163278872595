import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import { calculateNewPenalty, cezaArttir, cezaEksilt, formatCeza } from '../../utils/cezaHesaplamalari.js';
import { PlusCircle } from 'lucide-react';
import { addPredefinedColumn } from '../../utils/predefinedColumnUtils.js';


const predefinedRates = [
  { numerator: 1, denominator: 2 },
  { numerator: 1, denominator: 3 },
  { numerator: 1, denominator: 6 },
  { numerator: 2, denominator: 3 },
  { numerator: 3, denominator: 4 },
  { numerator: 5, denominator: 6 },
  { numerator: 1, denominator: 12 }
];


const EditColumnModal = ({ isOpen, onClose, column, onSave, prevPenalty }) => {
  const [editedColumn, setEditedColumn] = useState(null);
  const [calculatedPenalty, setCalculatedPenalty] = useState(null);
  const [customNumerator, setCustomNumerator] = useState('');
  const [customDenominator, setCustomDenominator] = useState('');

  useEffect(() => {
    if (column) {
      const initialPenalty = column.isSingleLine || column.isTwoLineText ? null : (column.penalty || prevPenalty || { year: 0, month: 0, day: 0, fine: 0 });
      const initialDiscountRate = column.isSingleLine || column.isTwoLineText || column.isBasePenalty 
        ? null 
        : (column.discountRate || { type: 'indirim', rate: { numerator: 0, denominator: 1 } });
      
      setEditedColumn({
        ...column,
        penalty: initialPenalty,
        discountRate: initialDiscountRate
      });
      setCalculatedPenalty(initialPenalty);
      setCustomNumerator(initialDiscountRate?.rate.numerator.toString() || '0');
      setCustomDenominator(initialDiscountRate?.rate.denominator.toString() || '1');
      
      if (!column.isSingleLine && !column.isTwoLineText && !column.isBasePenalty && prevPenalty) {
        calculatePenalty(prevPenalty, initialDiscountRate);
      }
    }
  }, [column, prevPenalty]);

  const calculatePenalty = (basePenalty, discountRate) => {
    const { type, rate } = discountRate;
    let newPenalty;

    if (type === 'indirim') {
      newPenalty = cezaEksilt(basePenalty, { rate });
    } else {
      newPenalty = cezaArttir(basePenalty, { rate });
    }

    setCalculatedPenalty(newPenalty);
    return newPenalty;
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (editedColumn) {
      setEditedColumn(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleRateChange = (e) => {
    const { value } = e.target;
    const [numerator, denominator] = value.split('/').map(Number);
    updateRate(numerator, denominator);
    setCustomNumerator(numerator.toString());
    setCustomDenominator(denominator.toString());
  };

  const handleCustomRateChange = (part) => (e) => {
    const value = e.target.value;
    if (part === 'numerator') {
      setCustomNumerator(value);
    } else {
      setCustomDenominator(value);
    }
    
    const numerator = part === 'numerator' ? parseInt(value, 10) : parseInt(customNumerator, 10);
    const denominator = part === 'denominator' ? parseInt(value, 10) : parseInt(customDenominator, 10);
    
    if (numerator && denominator) {
      updateRate(numerator, denominator);
    }
  };

  const updateRate = (numerator, denominator) => {
    if (numerator && denominator) {
      const newDiscountRate = {
        ...editedColumn.discountRate,
        rate: { numerator, denominator }
      };
      setEditedColumn(prev => ({
        ...prev,
        discountRate: newDiscountRate
      }));
      calculatePenalty(prevPenalty, newDiscountRate);
    }
  };

  const handlePenaltyChange = (e) => {
    const { name, value } = e.target;
    const newValue = parseInt(value, 10) || 0;
    const updatedPenalty = { ...calculatedPenalty, [name]: newValue };
    setCalculatedPenalty(updatedPenalty);
    
    // Güncellenmiş cezayı editedColumn state'ine de yansıt
    setEditedColumn(prev => ({
      ...prev,
      penalty: updatedPenalty
    }));
  };

  const handleRateTypeChange = (e) => {
    const newType = e.target.value;
    setEditedColumn(prev => ({
      ...prev,
      discountRate: {
        ...prev.discountRate,
        type: newType
      }
    }));
    // Yeni tip ile cezayı yeniden hesapla
    calculatePenalty(prevPenalty, {
      ...editedColumn.discountRate,
      type: newType
    });
  };

  const handleSave = () => {
    if (editedColumn) {
      onSave({
        ...editedColumn,
        penalty: editedColumn.isSingleLine ? null : calculatedPenalty
      });
    }
    onClose();
  };

  const handleAddToPredefined = () => {
    if (editedColumn) {
      addPredefinedColumn(editedColumn);
      // Kullanıcıya bir bildirim gösterebilirsiniz
      alert('Kolon ön tanımlı kolonlara eklendi.');
    }
  };

  if (!isOpen || !editedColumn) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Sütunu Düzenle">
      {editedColumn && (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Sütun Tipi</label>
            <select
              value={editedColumn.isSingleLine ? 'single' : editedColumn.isTwoLineText ? 'twoLine' : editedColumn.isBasePenalty ? 'basePenalty' : 'normal'}
              onChange={(e) => {
                const value = e.target.value;
                setEditedColumn(prev => ({
                  ...prev,
                  isSingleLine: value === 'single',
                  isTwoLineText: value === 'twoLine',
                  isBasePenalty: value === 'basePenalty',
                  discountRate: value === 'normal' ? { type: 'indirim', rate: { numerator: 0, denominator: 1 } } : null
                }));
              }}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="normal">Normal</option>
              <option value="single">Tek Satır</option>
              <option value="twoLine">İki Satırlı Metin</option>
              <option value="basePenalty">Temel Ceza</option>
            </select>
          </div>
          {editedColumn.isSingleLine ? (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">İçerik</label>
              <input
                type="text"
                value={editedColumn.content || ''}
                onChange={(e) => setEditedColumn(prev => ({ ...prev, content: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          ) : editedColumn.isTwoLineText ? (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Üst Satır</label>
                <input
                  type="text"
                  value={editedColumn.topContent || ''}
                  onChange={(e) => setEditedColumn(prev => ({ ...prev, topContent: e.target.value }))}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Alt Satır</label>
                <input
                  type="text"
                  value={editedColumn.bottomContent || ''}
                  onChange={(e) => setEditedColumn(prev => ({ ...prev, bottomContent: e.target.value }))}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </>
          ) : (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Madde</label>
                <input
                  type="text"
                  name="law"
                  value={editedColumn.law || ''}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Ceza (Manuel düzenlenebilir)
                </label>
                <div className="mt-1 flex">
                  <div className="relative mr-2 w-1/4">
                    <input
                      type="number"
                      name="year"
                      value={calculatedPenalty?.year || 0}
                      onChange={handlePenaltyChange}
                      placeholder="Yıl"
                      className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-8"
                    />
                    <span className="absolute top-0 right-0 px-2 py-1 text-xs text-gray-500">yıl</span>
                  </div>
                  <div className="relative mr-2 w-1/4">
                    <input
                      type="number"
                      name="month"
                      value={calculatedPenalty?.month || 0}
                      onChange={handlePenaltyChange}
                      placeholder="Ay"
                      className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-8"
                    />
                    <span className="absolute top-0 right-0 px-2 py-1 text-xs text-gray-500">ay</span>
                  </div>
                  <div className="relative mr-2 w-1/4">
                    <input
                      type="number"
                      name="day"
                      value={calculatedPenalty?.day || 0}
                      onChange={handlePenaltyChange}
                      placeholder="Gün"
                      className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-8"
                    />
                    <span className="absolute top-0 right-0 px-2 py-1 text-xs text-gray-500">gün</span>
                  </div>
                  <div className="relative w-1/4">
                    <input
                      type="number"
                      name="fine"
                      value={calculatedPenalty?.fine || 0}
                      onChange={handlePenaltyChange}
                      placeholder="Para Cezası"
                      className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-8"
                    />
                    <span className="absolute top-0 right-0 px-2 py-1 text-xs text-gray-500">apc</span>
                  </div>
                </div>
                <div className="mt-1 text-sm text-gray-500">
                  (Temel Ceza: {prevPenalty ? formatCeza(prevPenalty) : 'Ceza yok'})
                </div>
              </div>
              {!editedColumn.isBasePenalty && (
                <>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Oran</label>
                    <div className="mt-1 flex">
                      <select
                        value={`${editedColumn.discountRate.rate.numerator}/${editedColumn.discountRate.rate.denominator}`}
                        onChange={handleRateChange}
                        className="mr-2 block w-1/2 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      >
                        {predefinedRates.map((rate, index) => (
                          <option key={index} value={`${rate.numerator}/${rate.denominator}`}>
                            {rate.numerator}/{rate.denominator}
                          </option>
                        ))}
                      </select>
                      <div className="flex w-1/2 items-center">
                        <input
                          type="number"
                          value={customNumerator}
                          onChange={handleCustomRateChange('numerator')}
                          className="w-2/5 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        <span className="mx-2">/</span>
                        <input
                          type="number"
                          value={customDenominator}
                          onChange={handleCustomRateChange('denominator')}
                          className="w-2/5 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">İndirim/Artırım</label>
                    <select
                      value={editedColumn.discountRate.type}
                      onChange={handleRateTypeChange}
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      <option value="indirim">İndirim</option>
                      <option value="arttırım">Artırım</option>
                    </select>
                  </div>
                </>
              )}
            </>
          )}
          <div className="flex justify-between mt-4">
            <PlusCircle className="h-5 w-5 bg-gray-500 text-white rounded hover:bg-gray-600 cursor-pointer" onClick={handleAddToPredefined} />
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 border border-blue-600"
            >
              Kaydet
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditColumnModal;