import axios from 'axios';

const API_URL = 'http://localhost:5000/api/sablonlar';

export const getSablonlar = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const getSablonById = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};

export const createSablon = async (sablon) => {
  const response = await axios.post(API_URL, sablon);
  return response.data;
};

export const updateSablon = async (id, sablon) => {
  const response = await axios.put(`${API_URL}/${id}`, sablon);
  return response.data;
};

export const deleteSablon = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};