import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, VerticalAlign } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';

export const createCezaSahsilestirmeTable = (cezaSahsilestirmeInfo) => {
  // Tüm alanların boş olup olmadığını kontrol et
  const isAllEmpty = Object.values(cezaSahsilestirmeInfo).every(value => !value || value.trim() === '');

  // Eğer tüm alanlar boşsa, null döndür
  if (isAllEmpty) {
    return null;
  }

  const rows = [
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Cezanın Şahsîleştirilmesi",
                  bold: true,  // Başlığı koyu yapıyoruz
                }),
              ],
            }),
          ],
          columnSpan: 2,
          shading: { fill: HEADER_FILL_COLOR },
        }),
      ],
    }),
  ];

  const addRowIfNotEmpty = (label, value) => {
    if (value && value.trim() !== '') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: label,
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({ children: [new Paragraph({ text: value })] }),
          ],
        })
      );
    }
  };

  addRowIfNotEmpty("Yerel Mahkeme Uygulaması", cezaSahsilestirmeInfo.yerelMahkemeUygulamasi);
  addRowIfNotEmpty("TCK 62", cezaSahsilestirmeInfo.tck62);
  addRowIfNotEmpty("TCK 50", cezaSahsilestirmeInfo.tck50);
  addRowIfNotEmpty("TCK 51", cezaSahsilestirmeInfo.tck51);
  addRowIfNotEmpty("CMK 231", cezaSahsilestirmeInfo.cmk231);

  // Eğer başlık dışında hiç satır eklenmemişse, null döndür
  if (rows.length === 1) {
    return null;
  }

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: 'single', size: 1, color: BORDER_COLOR },
      bottom: { style: 'single', size: 1, color: BORDER_COLOR },
      left: { style: 'single', size: 1, color: BORDER_COLOR },
      right: { style: 'single', size: 1, color: BORDER_COLOR },
      insideHorizontal: { style: 'single', size: 1, color: BORDER_COLOR },
      insideVertical: { style: 'single', size: 1, color: BORDER_COLOR },
    },
    rows: rows,
  });
};