import React from 'react';

function TarihGirisi({ label, value, onChange }) {
  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return '';
    }
    return date.toISOString().split('T')[0];
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tarih">
        {label}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="tarih"
        type="date"
        value={formatDate(value)}
        onChange={(e) => onChange(new Date(e.target.value))}
      />
    </div>
  );
}

export default TarihGirisi;
