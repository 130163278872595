import React, { useState, useEffect } from 'react';

const SanikSabikasıCard = ({ info, onUpdate }) => {
  const [localInfo, setLocalInfo] = useState(info);

  useEffect(() => {
    setLocalInfo(info);
  }, [info]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleBlur = () => {
    onUpdate(localInfo);
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {/* <h3 className="text-xl font-bold mb-4">SANIK SABIKASI</h3> */}
      <textarea
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        name="sanikSabikasi"
        value={localInfo.sanikSabikasi || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        rows="6"
        placeholder="Sanık hakkında mükerrirlere özgü infaz rejimi uygulanması kararı verilmesi halinde tekerrür hükümlerinin sanık sabıkasıyla uyumlu olup olmadığının tespiti"
      />
    </div>
  );
};

export default SanikSabikasıCard;