import React from 'react';

const PanelButton = ({ onClick, icon: Icon, text, color }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center w-full p-2 mb-4 text-white rounded-md ${color}`}
    >
      {Icon && <Icon className="mr-2" size={20} />}
      {text}
    </button>
  );
};

export default PanelButton;