import { Table, TableRow, TableCell, Paragraph, WidthType } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';

export const createTemyizNedenleriTable = (temyizNedenleriInfo) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: 'single', size: 1, color: BORDER_COLOR },
      bottom: { style: 'single', size: 1, color: BORDER_COLOR },
      left: { style: 'single', size: 1, color: BORDER_COLOR },
      right: { style: 'single', size: 1, color: BORDER_COLOR },
      insideHorizontal: { style: 'single', size: 1, color: BORDER_COLOR },
      insideVertical: { style: 'single', size: 1, color: BORDER_COLOR },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph("Temyiz Nedenleri")],
            shading: { fill: HEADER_FILL_COLOR },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph(temyizNedenleriInfo || "")] }),
        ],
      }),
    ],
  });
};