import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, BorderStyle, VerticalAlign, AlignmentType } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';
import { capitalizeFullName } from '../../utils/stringUtils';
import { formatDate } from './utils';
import { calculateAge } from '../../utils/dateUtils';

export const createTarafCard = (tarafInfo, sucTarihi) => {
  console.log('createTarafCard içinde tarafInfo:', JSON.stringify(tarafInfo, null, 2));

  if (!tarafInfo || typeof tarafInfo !== 'object' || Object.keys(tarafInfo).length === 0) {
    console.warn('Geçersiz taraf bilgisi:', tarafInfo);
    return [createEmptyTable('Taraf bilgisi mevcut değil'), new Paragraph({})];
  }

  const { role = '', name = '', surname = '', birthDate = '', penaltyInfo = [] } = tarafInfo;
  console.log('penaltyInfo:', penaltyInfo);

  const age = calculateAge(birthDate, sucTarihi);

  const fullName = capitalizeFullName(`${name} ${surname}`.trim());
  const formattedBirthDate = formatDate(birthDate);
  const headerText = `${(role || '') || 'ROL BİLİNMİYOR'} ${fullName} (DT: ${formattedBirthDate || 'Bilinmiyor'}, Suç tarihindeki yaşı: ${age || 'Bilinmiyor'})`;

  const headerTable = new Table({
    width: { size: 100, type: WidthType.PERCENTAGE },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({ text: headerText, bold: true })],
             
            })],
            shading: {
              fill: HEADER_FILL_COLOR,
            },
          }),
        ],
      }),
    ],
  });

  const elements = [headerTable, new Paragraph({})];

  if (Array.isArray(penaltyInfo) && penaltyInfo.length > 0) {
    console.log('penaltyInfo mevcut ve dolu');
    penaltyInfo.forEach((card, cardIndex) => {
      console.log(`İşlenen kart ${cardIndex}:`, card);
      
      if (card && Array.isArray(card.columns)) {
        const cardRows = [];

        // Üst satır
        const topRowCells = [
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            rowSpan: 2,
            width: { size: 5, type: WidthType.PERCENTAGE },
            children: [new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [new TextRun({ text: `${cardIndex + 1}`, bold: true })],
            })],
            shading: {
              fill: HEADER_FILL_COLOR,
            },
          }),
          ...card.columns.map((column) => {
            if (column && typeof column === 'object') {
              if (column.isSingleLine) {
                return new TableCell({ 
                  verticalAlign: VerticalAlign.CENTER,
                  rowSpan: 2,
                  children: [
                    new Paragraph({ 
                      alignment: AlignmentType.CENTER,
                      children: [new TextRun({ text: column.content, bold: true })]
                    })
                  ]
                });
              } else if (column.isTwoLineText) {
                return new TableCell({ 
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({ 
                      alignment: AlignmentType.CENTER,
                      children: [new TextRun({ text: column.topContent, bold: true })]
                    })
                  ]
                });
              } else {
                const { law, discountRate, isBasePenalty } = column;
                const discountRateText = !isBasePenalty && discountRate ? ` (${formatDiscountRate(discountRate)})` : '';
                return new TableCell({ 
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({ 
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({ text: law || 'Belirtilmemiş', bold: true }),
                        new TextRun({ text: discountRateText, bold: false }),
                      ]
                    })
                  ]
                });
              }
            } else {
              return new TableCell({ 
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  new Paragraph({ 
                    alignment: AlignmentType.CENTER,
                    text: 'Geçersiz veri', 
                    bold: true 
                  })
                ]
              });
            }
          })
        ];
        cardRows.push(new TableRow({ children: topRowCells }));

        // Alt satır (sadece iki satırlı ve normal sütunlar için)
        const bottomRowCells = card.columns.map((column) => {
          if (column && typeof column === 'object') {
            if (column.isSingleLine) {
              return null; // Tek satırlı sütunlar için boş hücre
            } else if (column.isTwoLineText) {
              return new TableCell({ 
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  new Paragraph({ 
                    alignment: AlignmentType.CENTER,
                    children: [new TextRun({ text: column.bottomContent, bold: false })]
                  })
                ]
              });
            } else {
              const { penalty } = column;
              const penaltyText = penalty ? formatPenalty(penalty) : 'Belirtilmemiş';
              return new TableCell({ 
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  new Paragraph({ 
                    alignment: AlignmentType.CENTER,
                    children: [new TextRun({ text: penaltyText, bold: false })]
                  })
                ]
              });
            }
          } else {
            return new TableCell({ 
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({ 
                  alignment: AlignmentType.CENTER,
                  text: 'Geçersiz veri', 
                  bold: false 
                })
              ]
            });
          }
        }).filter(cell => cell !== null);
        
        if (bottomRowCells.length > 0) {
          cardRows.push(new TableRow({ children: bottomRowCells }));
        }

        const cardTable = new Table({
          width: { size: 100, type: WidthType.PERCENTAGE },
          borders: {
            top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
            bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
            left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
            right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
            insideHorizontal: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
            insideVertical: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
          },
          rows: cardRows,
        });

        elements.push(cardTable);
        elements.push(new Paragraph({})); // Ceza kartları arasına boşluk ekler
      } else {
        console.warn(`Geçersiz kart yapısı: ${JSON.stringify(card)}`);
      }
    });
  } else {
    console.log('penaltyInfo mevcut değil veya boş');
    elements.push(new Table({
      width: { size: 100, type: WidthType.PERCENTAGE },
      borders: {
        top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
        bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
        left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
        right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      },
      rows: [
        new TableRow({
          children: [new TableCell({
            children: [new Paragraph({ text: 'Ceza kartı bilgisi bulunmamaktadır.' })],
          })],
        }),
      ],
    }));
  }

  return elements;
};

function createEmptyTable(message) {
  return new Table({
    width: { size: 100, type: WidthType.PERCENTAGE },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
    },
    rows: [
      new TableRow({
        children: [new TableCell({
          children: [new Paragraph({ text: message })],
        })],
      }),
    ],
  });
}

function formatPenalty(penalty) {
  const { year, month, day, fine } = penalty;
  let parts = [];
  if (year) parts.push(`${year} yıl`);
  if (month) parts.push(`${month} ay`);
  if (day) parts.push(`${day} gün`);
  if (fine) parts.push(`${fine} TL`);
  return parts.join(' ') || 'Belirtilmemiş';
}

function formatDiscountRate(discountRate) {
  const { type, rate } = discountRate;
  const { numerator, denominator } = rate;
  const sign = type === 'indirim' ? '-' : '+';
  return `${numerator}/${denominator}${sign}`;
}

// calculateDiscountedPenalty fonksiyonunu kaldırabilirsiniz, artık kullanılmıyor.