import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import EditColumnModal from './EditColumnModal.js';

const EditTemplateModal = ({ isOpen, onClose, template, onSave }) => {
  const [editedTemplate, setEditedTemplate] = useState(null);
  const [isEditColumnModalOpen, setIsEditColumnModalOpen] = useState(false);
  const [editingColumn, setEditingColumn] = useState(null);
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);

  useEffect(() => {
    if (template) {
      setEditedTemplate({ ...template });
    }
  }, [template]);

  const handleTitleChange = (e) => {
    setEditedTemplate(prev => ({ ...prev, title: e.target.value }));
  };

  const handleEditColumn = (column, index) => {
    setEditingColumn(column);
    setEditingColumnIndex(index);
    setIsEditColumnModalOpen(true);
  };

  const handleSaveColumn = (savedColumn) => {
    const updatedColumns = [...editedTemplate.columns];
    updatedColumns[editingColumnIndex] = savedColumn;
    setEditedTemplate(prev => ({ ...prev, columns: updatedColumns }));
    setIsEditColumnModalOpen(false);
  };

  const handleSave = () => {
    onSave(editedTemplate);
    onClose();
  };

  if (!isOpen || !editedTemplate) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Şablonu Düzenle">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Şablon Başlığı</label>
          <input
            type="text"
            value={editedTemplate.title}
            onChange={handleTitleChange}
            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <h3 className="text-lg font-medium text-gray-900">Sütunlar</h3>
          {editedTemplate.columns.map((column, index) => (
            <div key={index} className="mt-2 flex justify-between items-center">
              <span>{column.law || column.content || `Sütun ${index + 1}`}</span>
              <button
                onClick={() => handleEditColumn(column, index)}
                className="text-blue-500"
              >
                Düzenle
              </button>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Kaydet
          </button>
        </div>
      </div>

      <EditColumnModal
        isOpen={isEditColumnModalOpen}
        onClose={() => setIsEditColumnModalOpen(false)}
        column={editingColumn}
        onSave={handleSaveColumn}
        prevPenalty={editingColumn?.penalty}
      />
    </Modal>
  );
};

export default EditTemplateModal;