import React from 'react';
import { useNotification } from '../contexts/NotificationContext';

const Notification = () => {
  const { notification } = useNotification();

  if (!notification) return null;

  return (
    <div className={`fixed top-4 right-4 p-4 rounded-md text-white ${notification.type === 'success' ? 'bg-green-500' : 'bg-blue-500'}`}>
      {notification.message}
    </div>
  );
};

export default Notification;