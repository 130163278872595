import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, AlignmentType } from 'docx';
import { BORDER_COLOR, HEADER_FILL_COLOR } from './constants';
import { formatDate } from './utils';
import { turkishToUpper, turkishToLower } from '../../utils/stringUtils';

// İsmin ilk harfini büyük, diğerlerini küçük yapan yardımcı fonksiyon
const capitalizeFirstLetter = (string) => {
  return string.split(' ')
    .map(word => turkishToUpper(word.charAt(0)) + turkishToLower(word.slice(1)))
    .join(' ');
};

export const createTemyizTalebindeBulunanlarTable = (temyizTalebindeBulunanlarInfo) => {
  // Eğer veri yoksa veya boş bir dizi ise, null döndür
  if (!temyizTalebindeBulunanlarInfo || temyizTalebindeBulunanlarInfo.length === 0) {
    return null;
  }

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: 'single', size: 1, color: BORDER_COLOR },
      bottom: { style: 'single', size: 1, color: BORDER_COLOR },
      left: { style: 'single', size: 1, color: BORDER_COLOR },
      right: { style: 'single', size: 1, color: BORDER_COLOR },
      insideHorizontal: { style: 'single', size: 1, color: BORDER_COLOR },
      insideVertical: { style: 'single', size: 1, color: BORDER_COLOR },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [
                new TextRun({
                  text: "Temyiz Talebinde Bulunanlar",
                  bold: true,
                })
              ],
              alignment: AlignmentType.CENTER,
            })],
            shading: { fill: HEADER_FILL_COLOR },
            columnSpan: 2,
          }),
        ],
      }),
      ...temyizTalebindeBulunanlarInfo.flatMap(talep => [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ 
                      text: `${capitalizeFirstLetter(talep.talepEden)}${talep.isim ? ` (${capitalizeFirstLetter(talep.isim)})` : ''}`,
                      bold: true 
                    }),
                  ]
                })
              ],
              columnSpan: 2,
              shading: { fill: HEADER_FILL_COLOR },
            }),
          ],
        }),
        talep.tebligTarihi && new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: "Tebliğ Tarihi:" }),
                  ]
                })
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ 
                      text: `${formatDate(talep.tebligTarihi)} (${talep.tebligTuru === "Ceza infaz kurumu tebliği" ? "CİK Tebliği" : 
                             talep.tebligTuru === "Diğer" ? talep.digerTebligTuru : talep.tebligTuru})` 
                    }),
                  ]
                })
              ],
            }),
          ],
        }),
        talep.temyizTarihi && new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: "Temyiz Tarihi:" }),
                  ]
                })
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: formatDate(talep.temyizTarihi) }),
                  ]
                })
              ],
            }),
          ],
        }),
      ]).filter(Boolean),
    ],
  });
};