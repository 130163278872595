import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import sunData from '../data/sunrise_sunset_data.json';
import { cityNames } from '../data/cityNames.js';
import { SunriseCard, SunsetCard, DstCard } from '../components/SunTimeCards.js';
import AutocompleteSearch from '../components/AutocompleteSearch.js';
import { turkishToLower, turkishSort } from '../utils/stringUtils';

const SunTimes = () => {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [result, setResult] = useState({ 
    sunrise: '', 
    sunset: '',
    city: '',
    district: '',
    date: '', // Yeni eklenen alan
    dstPeriod: null // Yeni eklenen alan
  });
  const [isDst, setIsDst] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [dstPeriod, setDstPeriod] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [showDstCard, setShowDstCard] = useState(false);

  const dstPeriods = [
    { start: "1990-03-25", end: "1990-09-30" },
    { start: "1991-03-31", end: "1991-09-29" },
    { start: "1992-03-29", end: "1992-09-27" },
    { start: "1993-03-28", end: "1993-09-26" },
    { start: "1994-03-20", end: "1994-09-25" },
    { start: "1995-03-26", end: "1995-09-24" },
    { start: "1996-03-31", end: "1996-10-27" },
    { start: "1997-03-30", end: "1997-10-26" },
    { start: "1998-03-29", end: "1998-10-25" },
    { start: "1999-03-28", end: "1999-10-31" },
    { start: "2000-03-26", end: "2000-10-29" },
    { start: "2001-03-25", end: "2001-10-28" },
    { start: "2002-03-31", end: "2002-10-27" },
    { start: "2003-03-30", end: "2003-10-26" },
    { start: "2004-03-28", end: "2004-10-31" },
    { start: "2005-03-27", end: "2005-10-30" },
    { start: "2006-03-26", end: "2006-10-29" },
    { start: "2007-03-25", end: "2007-10-28" },
    { start: "2008-03-30", end: "2008-10-26" },
    { start: "2009-03-29", end: "2009-10-25" },
    { start: "2010-03-28", end: "2010-10-31" },
    { start: "2011-03-28", end: "2011-10-30" },
    { start: "2012-03-25", end: "2012-10-28" },
    { start: "2013-03-31", end: "2013-10-27" },
    { start: "2014-03-31", end: "2014-10-26" },
    { start: "2015-03-29", end: "2015-10-25" },
    { start: "2016-03-27", end: "süresiz" }
  ];

  useEffect(() => {
    setCities(Object.keys(sunData).map(city => ({
      value: city,
      label: cityNames[city] || city
    })));
  }, []);

  useEffect(() => {
    if (selectedCity) {
      setDistricts(Object.keys(sunData[selectedCity]));
    }
  }, [selectedCity]);

  useEffect(() => {
    const cities = Object.keys(cityNames)
      .map(city => ({
        name: cityNames[city],
        type: 'city',
        key: city
      }))
      .sort((a, b) => turkishSort(a.name, b.name));

    const districts = Object.entries(sunData)
      .flatMap(([city, districts]) =>
        Object.keys(districts).map(district => ({
          name: district,
          type: 'district',
          parent: cityNames[city],
          key: district,
          cityKey: city
        }))
      )
      .sort((a, b) => turkishSort(a.name, b.name));

    setSearchData([...cities, ...districts]);
  }, []);

  const getSunTimes = () => {
    if (!selectedCity || !selectedDistrict || !selectedDate) {
      alert("Lütfen şehir, ilçe ve tarih seçin.");
      return;
    }

    const selectedDateObj = new Date(selectedDate);
    const day = selectedDateObj.getDate();
    const month = selectedDateObj.getMonth();
    const year = selectedDateObj.getFullYear();

    const monthNames = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 
                        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

    const districtData = sunData[selectedCity]?.[selectedDistrict];
    
    if (!districtData) {
      console.error(`Veri bulunamadı: ${selectedCity}, ${selectedDistrict}`);
      setResult({ 
        sunrise: "Veri bulunamadı.", 
        sunset: "Veri bulunamadı.",
        normalSunrise: "",
        normalSunset: "",
        dstSunrise: "",
        dstSunset: "",
        city: cityNames[selectedCity],
        district: selectedDistrict
      });
      return;
    }

    const dayData = districtData.find(entry => entry.Day === day);

    if (dayData) {
      const normalSunrise = dayData[`${monthNames[month]}_Gün. Doğ.`] || "Bilinmiyor";
      const normalSunset = dayData[`${monthNames[month]}_Gün. Bat.`] || "Bilinmiyor";

      const isDstActive = checkIfDstActive(selectedDate);
      setIsDst(isDstActive);

      let dstSunrise = normalSunrise;
      let dstSunset = normalSunset;

      if (isDstActive) {
        dstSunrise = adjustForDst(normalSunrise);
        dstSunset = adjustForDst(normalSunset);
      }

      const currentYearDstPeriod = getCurrentYearDstPeriod(selectedDate);

      setResult({ 
        sunrise: isDstActive ? dstSunrise : normalSunrise, 
        sunset: isDstActive ? dstSunset : normalSunset,
        normalSunrise,
        normalSunset,
        dstSunrise,
        dstSunset,
        city: cityNames[selectedCity],
        district: selectedDistrict,
        date: selectedDate,
        dstPeriod: currentYearDstPeriod
      });

      setShowDstCard(true); // Hesaplama yapıldığında kartı göster
    } else {
      setResult({ 
        sunrise: "Veri bulunamadı.", 
        sunset: "Veri bulunamadı.",
        normalSunrise: "",
        normalSunset: "",
        dstSunrise: "",
        dstSunset: "",
        city: cityNames[selectedCity],
        district: selectedDistrict
      });
    }
  };

  const checkIfDstActive = (date) => {
    const selectedDate = new Date(date);
    for (const period of dstPeriods) {
      const start = new Date(period.start);
      const end = period.end === "süresiz" ? new Date("9999-12-31") : new Date(period.end);
      if (selectedDate >= start && selectedDate <= end) {
        setDstPeriod({
          start: period.start,
          end: period.end === "süresiz" ? "süresiz" : period.end
        });
        return true;
      }
    }
    setDstPeriod(null);
    return false;
  };

  const adjustForDst = (time) => {
    if (time === "Bilinmiyor") return time;

    const [hours, minutes] = time.split(":");
    let adjustedHours = parseInt(hours) + 1;
    if (adjustedHours === 24) adjustedHours = 0;
    return `${adjustedHours.toString().padStart(2, "0")}:${minutes}`;
  };

  const handleSearch = (selectedItem) => {
    if (selectedItem.type === 'city') {
      setSelectedCity(selectedItem.key);
      setSelectedDistrict('');
    } else if (selectedItem.type === 'district') {
      setSelectedCity(selectedItem.cityKey);
      setSelectedDistrict(selectedItem.key);
    }
  };

  const formatDate = (dateString) => {
    if (dateString === "süresiz") return "süresiz";
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const getCurrentYearDstPeriod = (date) => {
    const currentYear = new Date(date).getFullYear();
    return dstPeriods.find(period => {
      const startYear = parseInt(period.start.split('-')[0]);
      const endYear = period.end === "süresiz" ? 9999 : parseInt(period.end.split('-')[0]);
      return currentYear >= startYear && currentYear <= endYear;
    });
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-10 bg-gradient-to-br from-blue-100 to-purple-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-center text-indigo-800">Güneşin Doğuş ve Batış Saatleri</h1>

      <div className="mb-6">
        <AutocompleteSearch data={searchData} onSelect={handleSearch} />
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="city" className="block mb-2 font-semibold text-gray-700">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-indigo-600" />
              Şehir Seç
            </label>
            <select 
              id="city" 
              value={selectedCity} 
              onChange={(e) => setSelectedCity(e.target.value)} 
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">-- Şehir Seç --</option>
              {cities.map(city => (
                <option key={city.value} value={city.value}>{city.label}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="district" className="block mb-2 font-semibold text-gray-700">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-indigo-600" />
              İlçe Seç
            </label>
            <select 
              id="district" 
              value={selectedDistrict} 
              onChange={(e) => setSelectedDistrict(e.target.value)} 
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">-- İlçe Seç --</option>
              {districts.map(district => (
                <option key={district} value={district}>{district}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="date" className="block mb-2 font-semibold text-gray-700">
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-indigo-600" />
              Tarih Seç
            </label>
            <input 
              type="date" 
              id="date" 
              value={selectedDate} 
              onChange={(e) => setSelectedDate(e.target.value)} 
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        <button 
          onClick={getSunTimes} 
          className="w-full mt-6 bg-indigo-600 text-white p-3 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Saatleri Göster
        </button>
      </div>

      {result.sunrise && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <SunriseCard 
            time={result.sunrise} 
            district={result.district}
            city={result.city}
            date={result.date}
          />
          <SunsetCard 
            time={result.sunset} 
            district={result.district}
            city={result.city}
            date={result.date}
          />
        </div>
      )}

      {showDstCard && isDst !== null && (
        <div className="mt-8">
          <DstCard 
            isDst={isDst}
            normalSunrise={result.normalSunrise}
            normalSunset={result.normalSunset}
            dstSunrise={result.dstSunrise}
            dstSunset={result.dstSunset}
            onShowDetails={() => setShowPopup(true)}
            dstPeriod={dstPeriod}
          />
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full">
            {result.date && (
              <div className="mb-6 bg-blue-100 p-4 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-blue-800 mb-2">Seçilen Tarihin Yaz Saati Uygulaması</h3>
                {result.dstPeriod ? (
                  <>
                    <p className="text-blue-700">
                      Başlangıç: {formatDate(result.dstPeriod.start)}
                    </p>
                    <p className="text-blue-700">
                      Bitiş: {formatDate(result.dstPeriod.end)}
                    </p>
                  </>
                ) : (
                  <p className="text-blue-700">Bu tarih için yaz saati uygulaması bilgisi bulunamadı.</p>
                )}
              </div>
            )}
            <h2 className="text-2xl font-bold mb-4 text-indigo-800">Yaz Saati Uygulaması Hakkında</h2>
            <p className="text-gray-700 mb-6">
              Türkiye'de belirli tarihlerde yaz saati uygulandı ve sonradan kaldırıldı. 
              27 Mart 2016 tarihinden sonra kalıcı yaz saati uygulamasına geçildi.
            </p>
            <button 
              onClick={() => setShowPopup(false)} 
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
            >
              Kapat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SunTimes;