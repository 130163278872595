import { Table, TableRow, TableCell, WidthType, BorderStyle, Paragraph } from 'docx';
import { createGeneralInfoTable } from './generalInfoTable.js';
import { createTemyizTalebindeBulunanlarTable } from './temyizTalebindeBulunanlarTable.js';
import { createSanikSabikasıTable } from './sanikSabikasıTable.js';
import { createTetkikHakimiGorusuTable } from './tetkikHakimiGorusuTable.js';

export const createSideBySideTable = (generalInfo, temyizTalebindeBulunanlarInfo, sanikSabikasıInfo, tetkikHakimiGorusuInfo) => {
  const generalInfoTable = createGeneralInfoTable(generalInfo);
  const temyizTalebindeBulunanlarTable = createTemyizTalebindeBulunanlarTable(temyizTalebindeBulunanlarInfo);
  const sanikSabikasıTable = createSanikSabikasıTable(sanikSabikasıInfo);
  const tetkikHakimiGorusuTable = createTetkikHakimiGorusuTable(tetkikHakimiGorusuInfo);

  const rightColumnChildren = [temyizTalebindeBulunanlarTable];
  
  if (sanikSabikasıInfo && Object.keys(sanikSabikasıInfo).length > 0) {
    rightColumnChildren.push(new Paragraph({}), sanikSabikasıTable);
  }
  
  if (tetkikHakimiGorusuInfo && Object.keys(tetkikHakimiGorusuInfo).length > 0) {
    rightColumnChildren.push(new Paragraph({}), tetkikHakimiGorusuTable);
  }

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: BorderStyle.NONE },
      bottom: { style: BorderStyle.NONE },
      left: { style: BorderStyle.NONE },
      right: { style: BorderStyle.NONE },
      insideHorizontal: { style: BorderStyle.NONE },
      insideVertical: { style: BorderStyle.NONE },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [generalInfoTable],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              right: { style: BorderStyle.NONE },
            },
          }),
          new TableCell({
            children: [],
            width: {
              size: 4,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
          }),
          new TableCell({
            children: rightColumnChildren,
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              left: { style: BorderStyle.NONE },
            },
          }),
        ],
      }),
    ],
  });
};