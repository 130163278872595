import React, { useState, useEffect } from 'react';
import { useGeneralInfo } from '../../contexts/GeneralInfoContext';
import { createTemyizTalebi, getTemyizTalepleri, updateTemyizTalebi, deleteTemyizTalebi } from '../../services/temyizTalebiService';

const TemyizTalebiKarti = ({ talep, onGuncelle, onSil }) => {
  const [duzenlemeModu, setDuzenlemeModu] = useState(false);
  const [geciciTalep, setGeciciTalep] = useState(talep);

  useEffect(() => {
    setGeciciTalep({
      id: talep.id,
      talepEden: talep.talep_eden || talep.talepEden,
      isim: talep.isim,
      tebligTuru: talep.teblig_turu || talep.tebligTuru,
      digerTebligTuru: talep.diger_teblig_turu || talep.digerTebligTuru,
      tebligTarihi: talep.teblig_tarihi || talep.tebligTarihi,
      temyizTarihi: talep.temyiz_tarihi || talep.temyizTarihi
    });
  }, [talep]);

  const tebligTurleri = [
    "Yüze karşı",
    "Ceza infaz kurumu tebliği",
    "Normal tebliğ",
    "Diğer"
  ];

  const kaydet = async () => {
    try {
      console.log('Kaydedilecek talep:', geciciTalep);
      await onGuncelle(geciciTalep);
      setDuzenlemeModu(false);
    } catch (error) {
      console.error('Talep güncellenirken hata oluştu:', error);
      // Hata durumunda kullanıcıya bir bildirim gösterilebilir
    }
  };

  const tebligTuruRengi = (tur) => {
    switch(tur) {
      case "Yüze karşı": return "bg-blue-500";
      case "Ceza infaz kurumu tebliği": return "bg-purple-500";
      case "Normal tebliğ": return "bg-green-500";
      default: return "bg-gray-500";
    }
  };

  const formatTarih = (tarih) => {
    if (!tarih) return '';
    const [yil, ay, gun] = tarih.split('-');
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
      <div 
        className="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl relative group border border-gray-200"
        onClick={() => setDuzenlemeModu(true)}
      >
        {!duzenlemeModu ? (
          <div className="relative p-5">
            <span className={`absolute top-0 right-0 ${tebligTuruRengi(geciciTalep.tebligTuru)} text-white text-xs font-bold px-2 py-1 rounded-bl-lg`}>
              {geciciTalep.tebligTuru === "Diğer" ? geciciTalep.digerTebligTuru || 'Belirtilmemiş' : geciciTalep.tebligTuru || 'Belirtilmemiş'}
            </span>
            <h3 className="text-xl font-semibold mb-2 pr-20">
              {geciciTalep.talepEden || 'Belirtilmemiş'}
              {geciciTalep.isim && <span className="text-sm font-normal ml-2">({geciciTalep.isim})</span>}
            </h3>
            <div className="text-sm text-gray-600">
              {geciciTalep.tebligTarihi && <p>Tebliğ: {formatTarih(geciciTalep.tebligTarihi)}</p>}
              {geciciTalep.temyizTarihi && <p>Temyiz: {formatTarih(geciciTalep.temyizTarihi)}</p>}
            </div>
            <button
              className="absolute bottom-2 right-2 text-red-500 hover:text-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                onSil(talep.id);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        ) : (
          <div className="p-5 space-y-3" onClick={(e) => e.stopPropagation()}>
            <select
              value={geciciTalep.talepEden || ''}
              onChange={(e) => setGeciciTalep({...geciciTalep, talepEden: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Talep Eden Seçiniz</option>
              {["Sanık", "Katılan", "SSÇ", "Cumhuriyet Savcısı", "Suçtan Zarar Gören"].map((secenek) => (
                <option key={secenek} value={secenek}>{secenek}</option>
              ))}
            </select>
            <input
              type="text"
              value={geciciTalep.isim || ''}
              onChange={(e) => setGeciciTalep({...geciciTalep, isim: e.target.value})}
              placeholder="İsim"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={geciciTalep.tebligTuru || ''}
              onChange={(e) => setGeciciTalep({...geciciTalep, tebligTuru: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Tebliğ Türü Seçiniz</option>
              {tebligTurleri.map((tur) => (
                <option key={tur} value={tur}>{tur}</option>
              ))}
            </select>
            {geciciTalep.tebligTuru === "Diğer" && (
              <input
                type="text"
                value={geciciTalep.digerTebligTuru || ''}
                onChange={(e) => setGeciciTalep({...geciciTalep, digerTebligTuru: e.target.value})}
                placeholder="Diğer tebliğ türünü giriniz"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
            <input
              type="date"
              value={geciciTalep.tebligTarihi || ''}
              onChange={(e) => setGeciciTalep({...geciciTalep, tebligTarihi: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="date"
              value={geciciTalep.temyizTarihi || ''}
              onChange={(e) => setGeciciTalep({...geciciTalep, temyizTarihi: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end space-x-2">
              <button onClick={kaydet} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300">Kaydet</button>
              <button onClick={() => setDuzenlemeModu(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-300">İptal</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const YeniTalepModal = ({ isOpen, onClose, onEkle }) => {
  const [yeniTalep, setYeniTalep] = useState({
    talepEden: "",
    isim: "",
    tebligTuru: "",
    digerTebligTuru: "",
    tebligTarihi: "",
    temyizTarihi: "",
  });

  const tebligTurleri = [
    "Yüze karşı",
    "Ceza infaz kurumu tebliği",
    "Normal tebliğ",
    "Diğer"
  ];

  const kaydet = () => {
    // Boş alanları filtreleme
    const filteredTalep = Object.fromEntries(
      Object.entries(yeniTalep).filter(([_, v]) => v !== "")
    );
    onEkle(filteredTalep);
    onClose();
    // Formu sıfırla
    setYeniTalep({
      talepEden: "",
      isim: "",
      tebligTuru: "",
      digerTebligTuru: "",
      tebligTarihi: "",
      temyizTarihi: "",
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Yeni Temyiz Talebi</h3>
        <div className="space-y-3">
          <select
            value={yeniTalep.talepEden}
            onChange={(e) => setYeniTalep({...yeniTalep, talepEden: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Talep Eden Seçiniz</option>
            {["Sanık", "Katılan", "SSÇ", "Cumhuriyet Savcısı", "Suçtan Zarar Gören"].map((secenek) => (
              <option key={secenek} value={secenek}>{secenek}</option>
            ))}
          </select>
          <input
            type="text"
            value={yeniTalep.isim}
            onChange={(e) => setYeniTalep({...yeniTalep, isim: e.target.value})}
            placeholder="İsim"
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select
            value={yeniTalep.tebligTuru}
            onChange={(e) => setYeniTalep({...yeniTalep, tebligTuru: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Tebliğ Türü Seçiniz</option>
            {tebligTurleri.map((tur) => (
              <option key={tur} value={tur}>{tur}</option>
            ))}
          </select>
          {yeniTalep.tebligTuru === "Diğer" && (
            <input
              type="text"
              value={yeniTalep.digerTebligTuru}
              onChange={(e) => setYeniTalep({...yeniTalep, digerTebligTuru: e.target.value})}
              placeholder="Diğer tebliğ türünü giriniz"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
          <input
            type="date"
            value={yeniTalep.tebligTarihi}
            onChange={(e) => setYeniTalep({...yeniTalep, tebligTarihi: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="date"
            value={yeniTalep.temyizTarihi}
            onChange={(e) => setYeniTalep({...yeniTalep, temyizTarihi: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={kaydet} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
            Ekle
          </button>
          <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
            İptal
          </button>
        </div>
      </div>
    </div>
  );
};

const TemyizTalebindeBulunanlarCard = ({ info, onUpdate }) => {
  const [talepler, setTalepler] = useState(info || []);
  const [modalAcik, setModalAcik] = useState(false);
  const { generalInfo } = useGeneralInfo();

  useEffect(() => {
    if (generalInfo.takrirId) {
      fetchTemyizTalepleri();
    }
  }, [generalInfo.takrirId]);

  const fetchTemyizTalepleri = async () => {
    try {
      const fetchedTalepler = await getTemyizTalepleri(generalInfo.takrirId);
      console.log('Fetched talepler:', fetchedTalepler);
      setTalepler(fetchedTalepler);
    } catch (error) {
      console.error('Temyiz talepleri getirilirken hata oluştu:', error);
    }
  };

  const yeniTalepEkle = async (yeniTalep) => {
    try {
      console.log('Eklenecek yeni talep:', yeniTalep);
      
      const gonderilecekTalep = {
        takrir_id: generalInfo.takrirId,
        talep_eden: yeniTalep.talepEden,
        isim: yeniTalep.isim,
        teblig_turu: yeniTalep.tebligTuru,
        diger_teblig_turu: yeniTalep.digerTebligTuru,
        teblig_tarihi: yeniTalep.tebligTarihi,
        temyiz_tarihi: yeniTalep.temyizTarihi
      };

      // Boş veya undefined değerleri temizle
      Object.keys(gonderilecekTalep).forEach(key => 
        (gonderilecekTalep[key] === null || gonderilecekTalep[key] === undefined || gonderilecekTalep[key] === "") && delete gonderilecekTalep[key]
      );

      console.log('Gönderilecek yeni talep:', gonderilecekTalep);

      const eklenenTalep = await createTemyizTalebi(gonderilecekTalep);
      console.log('Eklenen talep:', eklenenTalep);
      
      if (eklenenTalep && eklenenTalep.id) {
        setTalepler(prevTalepler => [...prevTalepler, eklenenTalep]);
        onUpdate([...talepler, eklenenTalep]);
      } else {
        console.error('Talep eklenemedi veya geçersiz veri döndü');
      }
    } catch (error) {
      console.error('Yeni temyiz talebi eklenirken hata oluştu:', error);
    }
  };

  const talepGuncelle = async (guncelTalep) => {
    try {
      console.log('Güncellenecek talep:', guncelTalep);
      // talepEden alanını talep_eden olarak değiştir ve boş olmayan alanları al
      const gonderilecekTalep = {
        id: guncelTalep.id,
        takrir_id: guncelTalep.takrir_id,
        talep_eden: guncelTalep.talepEden || guncelTalep.talep_eden,
        isim: guncelTalep.isim,
        teblig_turu: guncelTalep.tebligTuru || guncelTalep.teblig_turu,
        diger_teblig_turu: guncelTalep.digerTebligTuru || guncelTalep.diger_teblig_turu,
        teblig_tarihi: guncelTalep.tebligTarihi || guncelTalep.teblig_tarihi,
        temyiz_tarihi: guncelTalep.temyizTarihi || guncelTalep.temyiz_tarihi
      };

      // Boş veya undefined değerleri temizle
      Object.keys(gonderilecekTalep).forEach(key => 
        (gonderilecekTalep[key] === null || gonderilecekTalep[key] === undefined || gonderilecekTalep[key] === "") && delete gonderilecekTalep[key]
      );

      console.log('Gönderilecek talep:', gonderilecekTalep);

      const guncellenmisTalep = await updateTemyizTalebi(guncelTalep.id, gonderilecekTalep);
      console.log('Güncellenmiş talep:', guncellenmisTalep);
      if (guncellenmisTalep && guncellenmisTalep.id) {
        const yeniTalepler = talepler.map(talep => 
          talep.id === guncellenmisTalep.id ? {
            ...guncellenmisTalep,
            talepEden: guncellenmisTalep.talep_eden,
            tebligTuru: guncellenmisTalep.teblig_turu,
            digerTebligTuru: guncellenmisTalep.diger_teblig_turu,
            tebligTarihi: guncellenmisTalep.teblig_tarihi,
            temyizTarihi: guncellenmisTalep.temyiz_tarihi
          } : talep
        );
        console.log('Yeni talepler:', yeniTalepler);
        setTalepler(yeniTalepler);
        onUpdate(yeniTalepler);
      } else {
        console.error('Talep güncellenemedi veya geçersiz veri döndü');
      }
    } catch (error) {
      console.error('Temyiz talebi güncellenirken hata oluştu:', error);
    }
  };

  const talepSil = async (id) => {
    try {
      await deleteTemyizTalebi(id);
      const yeniTalepler = talepler.filter(talep => talep.id !== id);
      setTalepler(yeniTalepler);
      onUpdate(yeniTalepler);
    } catch (error) {
      console.error('Temyiz talebi silinirken hata oluştu:', error);
    }
  };

  return (
    <div className="w-full mb-4 bg-white shadow-md rounded-lg overflow-hidden">
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        <button 
          onClick={() => setModalAcik(true)}
          className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Yeni Temyiz Talebi Ekle
        </button>
        <div className="flex flex-wrap -mx-2">
          {talepler.map((talep) => (
            <TemyizTalebiKarti 
              key={talep.id} 
              talep={talep} 
              onGuncelle={talepGuncelle} 
              onSil={talepSil} 
            />
          ))}
        </div>
      </div>
      <YeniTalepModal 
        isOpen={modalAcik}
        onClose={() => setModalAcik(false)}
        onEkle={yeniTalepEkle}
      />
    </div>
  );
};

export default TemyizTalebindeBulunanlarCard;