import axios from 'axios';

const API_URL = 'http://localhost:5000/api/takrirler';

export const getTakrir = async (yargitayEsasNo) => {
  try {
    const response = await axios.get(`${API_URL}/${yargitayEsasNo}`);
    return response.data;
  } catch (error) {
    console.error('Takrir getirme hatası:', error);
    throw error;
  }
};

export const createTakrir = async (takrirData) => {
  try {
    console.log('Gönderilen veri:', takrirData);
    const response = await axios.post(API_URL, takrirData);
    console.log('Sunucu yanıtı:', response.data);
    return response.data;
  } catch (error) {
    console.error('Takrir oluşturma hatası:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateTakrir = async (takrirData) => {
  try {
    console.log('Güncelleme isteği gönderiliyor:', takrirData);
    if (!takrirData.id) {
      throw new Error("Takrir ID'si bulunamadı");
    }
    const response = await axios.put(`${API_URL}/${takrirData.id}`, takrirData);
    console.log('Takrir güncellendi:', response.data);
    return response.data;
  } catch (error) {
    console.error('Takrir güncelleme hatası:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteTakrir = async (yargitayEsasNo) => {
  try {
    const response = await axios.delete(`${API_URL}/${yargitayEsasNo}`);
    return response.data;
  } catch (error) {
    console.error('Takrir silme hatası:', error);
    throw error;
  }
};

export const getAllTakrirler = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Tüm takrirleri getirme hatası:', error);
    throw error;
  }
};

export const searchTakrirler = async (searchTerm) => {
  try {
    console.log('Arama isteği gönderiliyor:', searchTerm);
    const response = await axios.get(`${API_URL}/search`, {
      params: { term: searchTerm }
    });
    console.log('Arama sonuçları:', response.data);
    return response.data;
  } catch (error) {
    console.error('Takrir arama hatası:', error);
    throw error;
  }
};
