import React, { useState } from 'react';
import Modal from './Modal'; // Mevcut Modal bileşeninizi kullanın

const EditAppealModal = ({ isOpen, onClose, appealInfo, onUpdate }) => {
  const [editedInfo, setEditedInfo] = useState(appealInfo);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedInfo(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(editedInfo);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <h2 className="text-xl font-bold mb-4">Temyiz Bilgilerini Düzenle</h2>
        <div>
          <label className="block text-sm font-medium text-gray-700">Tebliğ Tarihi</label>
          <input
            type="date"
            name="notificationDate"
            value={editedInfo.notificationDate}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Temyiz Tarihi</label>
          <input
            type="date"
            name="appealDate"
            value={editedInfo.appealDate}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="isAdverseAppeal"
              checked={editedInfo.isAdverseAppeal}
              onChange={handleChange}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-700">Aleyhe Temyiz</span>
          </label>
        </div>
        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="isInPresence"
              checked={editedInfo.isInPresence}
              onChange={handleChange}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-700">Yüze Karşı</span>
          </label>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            İptal
          </button>
          <button
            type="submit"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Kaydet
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditAppealModal;