import React, { useState, useEffect, useMemo } from 'react';
import KategoriAgaci from '../components/kararSablonlari/KategoriAgaci.js';
import SablonKarti from '../components/kararSablonlari/SablonKarti.js';
import SablonDuzenleyici from '../components/kararSablonlari/SablonDuzenleyici.js';
import SablonArama from '../components/kararSablonlari/SablonArama';
import EtiketListesi from '../components/kararSablonlari/EtiketListesi';
import CollapsiblePanel from '../components/common/CollapsiblePanel.js';
import { getSablonlar, setSablonlar } from '../components/kararSablonlari/sablonUtils';
import { PlusIcon } from '@heroicons/react/20/solid';
import { turkishToLower } from '../utils/stringUtils';
import { filtreliSablonlariGetir } from '../components/kararSablonlari/sablonFiltrele.js';
import * as sablonService from '../services/sablonService';

const baslangicKategoriYapisi = [
  {
    isim: 'Tekerrür',
    altKategoriler: [
      { isim: 'TCK Maddeleri', altKategoriler: [{ isim: 'TCK 58. Madde' }, { isim: 'TCK 50. Madde' }] },
      { isim: 'Suç Türleri', altKategoriler: [{ isim: 'Elektrik Hırsızlığı' }] },
      { isim: 'Hüküm Türleri', altKategoriler: [{ isim: 'Erteleme' }, { isim: 'Seçenek Yaptırımlar' }] },
      { isim: 'Özel Durumlar', altKategoriler: [{ isim: 'Yaş Durumu' }, { isim: 'Süre Hesaplaması' }] },
    ]
  }
];

const KararSablonlari = () => {
  const [sablonlar, setSablonlarState] = useState([]);
  const [aramaMetni, setAramaMetni] = useState('');
  const [secilenKategoriler, setSecilenKategoriler] = useState(['Tümü']);
  const [secilenEtiketler, setSecilenEtiketler] = useState([]);
  const [secilenKanunMaddeleri, setSecilenKanunMaddeleri] = useState([]);
  const [duzenlenecekSablon, setDuzenlenecekSablon] = useState(null);
  const [kategoriYapisi, setKategoriYapisi] = useState(baslangicKategoriYapisi);
  const [tumKanunMaddeleri, setTumKanunMaddeleri] = useState([]);
  const [tumEtiketler, setTumEtiketler] = useState([]);

  useEffect(() => {
    const fetchSablonlar = async () => {
      try {
        const yuklenenSablonlar = await sablonService.getSablonlar();
        console.log('Yüklenen şablonlar:', yuklenenSablonlar);
        
        const kanunMaddeleriSet = new Set();
        const etiketlerSet = new Set();

        yuklenenSablonlar.forEach(sablon => {
          if (sablon.kanun_maddeleri && Array.isArray(sablon.kanun_maddeleri)) {
            sablon.kanun_maddeleri.forEach(madde => {
              const maddeValue = typeof madde === 'string' ? madde : madde.value;
              kanunMaddeleriSet.add(maddeValue);
            });
          }
          if (sablon.etiketler && Array.isArray(sablon.etiketler)) {
            sablon.etiketler.forEach(etiket => {
              const etiketValue = typeof etiket === 'string' ? etiket : etiket.value;
              etiketlerSet.add(etiketValue);
            });
          }
        });

        const kanunMaddeleriArray = Array.from(kanunMaddeleriSet);
        const etiketlerArray = Array.from(etiketlerSet);

        setSablonlarState(yuklenenSablonlar);
        setTumKanunMaddeleri(kanunMaddeleriArray);
        setTumEtiketler(etiketlerArray);

        console.log('Yüklenen kanun maddeleri:', kanunMaddeleriArray);
        console.log('Yüklenen etiketler:', etiketlerArray);
      } catch (error) {
        console.error('Şablonlar yüklenirken hata oluştu:', error);
      }
    };

    fetchSablonlar();
  }, []);

  const filtreliSablonlar = useMemo(() => {
    return filtreliSablonlariGetir(sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni);
  }, [sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni]);

  // Filtrelenmiş şablonlardan etiketleri ve kanun maddelerini hesapla
  const [filtrelenmisEtiketler, filtrelenmisKanunMaddeleri] = useMemo(() => {
    const etiketSet = new Set();
    const kanunMaddeleriSet = new Set();

    filtreliSablonlar.forEach(sablon => {
      if (sablon.etiketler && Array.isArray(sablon.etiketler)) {
        sablon.etiketler.forEach(etiket => etiketSet.add(etiket));
      }
      if (sablon.kanun_maddeleri && Array.isArray(sablon.kanun_maddeleri)) {
        sablon.kanun_maddeleri.forEach(madde => kanunMaddeleriSet.add(madde));
      }
    });

    return [Array.from(etiketSet), Array.from(kanunMaddeleriSet)];
  }, [filtreliSablonlar]);

  const handleYeniSablon = () => {
    setDuzenlenecekSablon({
      id: null,
      baslik: '',
      kategori: '',
      etiketler: [],
      icerik: '',
      yeniSablon: true
    });
  };

  const handleSablonKaydet = async (yeniSablon) => {
    try {
      let kaydedilenSablon;
      const sablonData = {
        ...yeniSablon,
        etiketler: (yeniSablon.etiketler || []).map(etiket => 
          typeof etiket === 'string' ? etiket : (etiket?.value || etiket)
        ),
        kanun_maddeleri: (yeniSablon.kanun_maddeleri || []).map(madde => 
          typeof madde === 'string' ? madde : (madde?.value || madde)
        )
      };

      console.log('Kaydedilecek şablon verisi:', sablonData); // Hata ayıklama için

      if (yeniSablon.id) {
        kaydedilenSablon = await sablonService.updateSablon(yeniSablon.id, sablonData);
      } else {
        kaydedilenSablon = await sablonService.createSablon(sablonData);
      }

      const yeniSablonlar = yeniSablon.id
        ? sablonlar.map(s => s.id === yeniSablon.id ? kaydedilenSablon : s)
        : [...sablonlar, kaydedilenSablon];

      setSablonlarState(yeniSablonlar);

      const yeniKanunMaddeleri = new Set([...tumKanunMaddeleri, ...(sablonData.kanun_maddeleri || [])]);
      const yeniEtiketler = new Set([...tumEtiketler, ...(sablonData.etiketler || [])]);

      setTumKanunMaddeleri(Array.from(yeniKanunMaddeleri));
      setTumEtiketler(Array.from(yeniEtiketler));

      setDuzenlenecekSablon(null);
    } catch (error) {
      console.error('Şablon kaydedilirken hata oluştu:', error);
    }
  };

  const handleKategoriEkle = (yeniKategoriAdi, parentKategori) => {
    const yeniKategoriler = [...kategoriYapisi];
    if (parentKategori) {
      const ekleAltKategori = (kategoriler) => {
        for (let i = 0; i < kategoriler.length; i++) {
          if (kategoriler[i].isim === parentKategori) {
            kategoriler[i].altKategoriler.push({ isim: yeniKategoriAdi, altKategoriler: [] });
            return true;
          }
          if (kategoriler[i].altKategoriler && ekleAltKategori(kategoriler[i].altKategoriler)) {
            return true;
          }
        }
        return false;
      };
      ekleAltKategori(yeniKategoriler);
    } else {
      yeniKategoriler.push({ isim: yeniKategoriAdi, altKategoriler: [] });
    }
    setKategoriYapisi(yeniKategoriler);
  };

  const handleSablonSil = async (sablonId) => {
    try {
      await sablonService.deleteSablon(sablonId);
      const yeniSablonlar = sablonlar.filter(sablon => sablon.id !== sablonId);
      setSablonlarState(yeniSablonlar);
    } catch (error) {
      console.error('Şablon silinirken hata oluştu:', error);
    }
  };

  const handleSelectChange = (field, selectedOptions) => {
    setDuzenlenecekSablon(prevSablon => ({
      ...prevSablon,
      [field]: selectedOptions.map(option => option.value)
    }));
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <div className="w-1/4 border-r flex flex-col overflow-y-auto">
        <CollapsiblePanel title="Kategoriler">
          <KategoriAgaci 
            kategoriler={kategoriYapisi}
            setKategoriler={setKategoriYapisi}
            secilenKategoriler={secilenKategoriler}
            setSecilenKategoriler={setSecilenKategoriler}
            onKategoriEkle={handleKategoriEkle}
          />
        </CollapsiblePanel>
        <CollapsiblePanel title="Etiketler">
          <EtiketListesi
            etiketler={filtrelenmisEtiketler}
            secilenEtiketler={secilenEtiketler}
            setSecilenEtiketler={setSecilenEtiketler}
          />
        </CollapsiblePanel>
        <CollapsiblePanel title="Kanun Maddeleri">
          <EtiketListesi
            etiketler={filtrelenmisKanunMaddeleri}
            secilenEtiketler={secilenKanunMaddeleri}
            setSecilenEtiketler={setSecilenKanunMaddeleri}
          />
        </CollapsiblePanel>
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="bg-white p-4 flex flex-col shadow">
          <div className="flex justify-between items-center mb-2">
            <div className="flex-1 mr-4">
              <SablonArama 
                aramaMetni={aramaMetni} 
                setAramaMetni={setAramaMetni}
              />
            </div>
            <button 
              onClick={handleYeniSablon}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center transition duration-150 ease-in-out"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Yeni Şablon
            </button>
          </div>
          <div className="text-sm text-gray-600">
            {filtreliSablonlar.length} şablon bulundu
          </div>
        </div>
        <div className="flex-1 p-4 overflow-y-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filtreliSablonlar.map(sablon => (
              <SablonKarti 
                key={sablon.id} 
                sablon={sablon} 
                onClick={() => setDuzenlenecekSablon(sablon)}
                onDelete={() => handleSablonSil(sablon.id)}
              />
            ))}
          </div>
        </div>
      </div>
      {duzenlenecekSablon && (
        <SablonDuzenleyici
          sablon={duzenlenecekSablon}
          onKaydet={handleSablonKaydet}
          onIptal={() => setDuzenlenecekSablon(null)}
          kategoriler={kategoriYapisi.flatMap(k => [k.isim, ...k.altKategoriler.flatMap(ak => [ak.isim, ...ak.altKategoriler.map(a => a.isim)])])}
          yeniSablon={duzenlenecekSablon.yeniSablon}
          tumKanunMaddeleri={tumKanunMaddeleri.map(madde => typeof madde === 'string' ? madde : madde.value)}
          tumEtiketler={tumEtiketler.map(etiket => typeof etiket === 'string' ? etiket : etiket.value)}
          handleSelectChange={handleSelectChange}
        />
      )}
    </div>
  );
};

export default KararSablonlari;