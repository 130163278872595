import React, { useEffect, useRef } from 'react';
import { PlusCircle } from 'lucide-react';

const ColumnMenu = ({ menuPosition, activeMenu, handleAddColumn, setActiveMenu, predefinedColumns }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setActiveMenu]);

  if (!menuPosition || !activeMenu) return null;

  return (
    <div
      ref={menuRef}
      className="fixed bg-white border border-gray-200 rounded shadow-lg z-50"
      style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}
    >
      <div className="py-1">
        <button
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          onClick={() => {
            handleAddColumn(activeMenu.cardId, activeMenu.columnIndex);
            setActiveMenu(null);
          }}
        >
          <PlusCircle className="inline-block mr-2 h-4 w-4" />
          Boş Kolon Ekle
        </button>
        {predefinedColumns.map((column) => (
          <button
            key={column.id}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={() => {
              handleAddColumn(activeMenu.cardId, activeMenu.columnIndex, column);
              setActiveMenu(null);
            }}
          >
            <PlusCircle className="inline-block mr-2 h-4 w-4" />
            {column.isSingleLine ? column.content :
             column.isTwoLineText ? `${column.topContent} / ${column.bottomContent}` :
             column.law || column.content || 'Ön Tanımlı Kolon'}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ColumnMenu;