export const formatNumberWithSlash = (value, slashPosition = 4) => {
    // Sadece sayıları ve "/" karakterini koru
    const cleaned = value.replace(/[^\d/]/g, '');
    
    // Eğer "/" karakteri varsa ve doğru konumdaysa, olduğu gibi bırak
    if (cleaned.includes('/') && cleaned.indexOf('/') === slashPosition) {
      return cleaned;
    }
    
    // Sadece sayıları al
    const numbers = cleaned.replace(/\D/g, '');
    
    // Eğer slashPosition'dan fazla rakam varsa ve "/" yoksa, otomatik ekle
    if (numbers.length > slashPosition) {
      return `${numbers.slice(0, slashPosition)}/${numbers.slice(slashPosition)}`;
    }
    
    return numbers;
  };
  
  export const handleNumberWithSlashChange = (value, slashPosition = 4, setValue) => {
    let inputValue = value;
    
    // Eğer kullanıcı "/" girmeye çalışıyorsa ve öncesinde yeterli rakam yoksa, engelle
    if (inputValue.includes('/') && inputValue.indexOf('/') < slashPosition) {
      inputValue = inputValue.replace('/', '');
    }
    
    const formattedValue = formatNumberWithSlash(inputValue, slashPosition);
    setValue(formattedValue);
  };