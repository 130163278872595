import React, { useState, useEffect, useCallback } from 'react';
import GoruntulemeAlani from './duzenleme/GoruntulemeAlani.js';
import AISuggestionsModal from './duzenleme/AISuggestionsModal.js';
import DuzenlemeFormu from './duzenleme/DuzenlemeFormu.js';
import DetailsModal from './duzenleme/DetailsModal.js';

const SablonDuzenleyici = ({ sablon, onKaydet, onIptal, kategoriler, yeniSablon, tumKanunMaddeleri, tumEtiketler, handleSelectChange: parentHandleSelectChange }) => {
  const [duzenlenmisSablon, setDuzenlenmisSablon] = useState({
    ...sablon,
    etiketler: (sablon.etiketler || []).map(etiket => 
      typeof etiket === 'string' ? { value: etiket, label: etiket } : etiket
    ),
    kanun_maddeleri: (sablon.kanun_maddeleri || []).map(madde => 
      typeof madde === 'string' ? { value: madde, label: madde } : madde
    )
  });
  const [duzenlemeModu, setDuzenlemeModu] = useState(yeniSablon);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAISuggestionsModal, setShowAISuggestionsModal] = useState(false);
  const [editableAISuggestions, setEditableAISuggestions] = useState({
    ozet: '',
    baslik: '',
    etiketler: [],
    kanunMaddeleri: []
  });

  useEffect(() => {
    setDuzenlenmisSablon({
      ...sablon,
      etiketler: Array.isArray(sablon.etiketler) 
        ? sablon.etiketler.map(etiket => typeof etiket === 'string' ? { value: etiket, label: etiket } : etiket) 
        : [],
      kanun_maddeleri: Array.isArray(sablon.kanun_maddeleri) 
        ? sablon.kanun_maddeleri.map(madde => typeof madde === 'string' ? { value: madde, label: madde } : madde) 
        : []
    });
    setDuzenlemeModu(yeniSablon);
  }, [sablon, yeniSablon]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDuzenlenmisSablon(prev => ({ ...prev, [name]: value }));
  };

  const handleIcerikChange = (icerik) => {
    setDuzenlenmisSablon(prev => ({ ...prev, icerik }));
  };

  const handleSelectChange = useCallback((name, selectedOptions) => {
    setDuzenlenmisSablon(prev => {
      const updatedSablon = {
        ...prev,
        [name]: selectedOptions
      };
      parentHandleSelectChange(name, selectedOptions);
      return updatedSablon;
    });
  }, [parentHandleSelectChange]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sablonData = {
        ...duzenlenmisSablon,
        etiketler: (duzenlenmisSablon.etiketler || []).map(etiket => 
          typeof etiket === 'string' ? etiket : (etiket?.value || etiket)
        ),
        kanun_maddeleri: (duzenlenmisSablon.kanun_maddeleri || []).map(madde => 
          typeof madde === 'string' ? madde : (madde?.value || madde)
        )
      };
      await onKaydet(sablonData);
      setDuzenlemeModu(false);
    } catch (error) {
      console.error('Şablon kaydedilirken hata oluştu:', error);
    }
  };

  const handleAIEnhance = ({ ozet, baslik, etiketler, kanunMaddeleri }) => {
    setEditableAISuggestions({ ozet, baslik, etiketler, kanunMaddeleri });
    setShowAISuggestionsModal(true);
  };

  const handleAISuggestionsChange = (e) => {
    const { name, value } = e.target;
    setEditableAISuggestions(prev => ({ ...prev, [name]: value }));
  };

  const handleAISuggestionsSave = () => {
    setDuzenlenmisSablon(prev => ({
      ...prev,
      ozet: editableAISuggestions.ozet,
      baslik: editableAISuggestions.baslik || prev.baslik,
      etiketler: [...new Set([...prev.etiketler, ...editableAISuggestions.etiketler])],
      kanun_maddeleri: [...new Set([...(prev.kanun_maddeleri || []), ...editableAISuggestions.kanunMaddeleri])]
    }));
    setShowAISuggestionsModal(false);
  };

  const handleDetailsSave = (updatedSablon) => {
    setDuzenlenmisSablon(updatedSablon);
    setShowDetailsModal(false);
    setDuzenlemeModu(true);  // Detaylar kaydedildikten sonra düzenleme modunda kal
  };

  const toggleDuzenlemeModu = () => {
    setDuzenlemeModu(prev => !prev);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative mx-auto p-5 w-full max-w-4xl my-10">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="flex flex-col h-[calc(100vh-8rem)]">
            {duzenlemeModu ? (
              <DuzenlemeFormu
                sablon={sablon}
                duzenlenmisSablon={duzenlenmisSablon}
                kategoriler={kategoriler}
                tumEtiketler={tumEtiketler}
                tumKanunMaddeleri={tumKanunMaddeleri}
                handleChange={handleChange}
                handleIcerikChange={handleIcerikChange}
                handleSelectChange={handleSelectChange}
                handleSubmit={handleSubmit}
                handleIptal={onIptal}
                handleAIEnhance={handleAIEnhance}
                onShowDetails={() => setShowDetailsModal(true)}
              />
            ) : (
              <GoruntulemeAlani
                sablon={duzenlenmisSablon}
                onDuzenle={() => setDuzenlemeModu(true)}
                onKapat={onIptal}
                onShowDetails={() => setShowDetailsModal(true)}
              />
            )}
          </div>
        </div>
      </div>
      <DetailsModal
        duzenlenmisSablon={duzenlenmisSablon}
        kategoriler={kategoriler}
        handleChange={handleChange}
        handleSelectChange={handleSelectChange}
        onClose={() => setShowDetailsModal(false)}
        kayitliEtiketler={tumEtiketler}
        kayitliKanunMaddeleri={tumKanunMaddeleri}
        onSave={handleDetailsSave}
      />
      <AISuggestionsModal
        show={showAISuggestionsModal}
        suggestions={editableAISuggestions}
        onChange={handleAISuggestionsChange}
        onSave={handleAISuggestionsSave}
        onClose={() => setShowAISuggestionsModal(false)}
      />
    </div>
  );
};

export default SablonDuzenleyici;
