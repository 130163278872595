import React, { useState, useEffect } from 'react';
import { PlusCircle, Trash2, Edit2 } from 'lucide-react';
import { formatCeza } from '../utils/cezaHesaplamalari.js';
import EditColumnModal from './modals/EditColumnModal.js';
import EditTemplateModal from './modals/EditTemplateModal.js'; // Bu yeni bir bileşen olacak

const TemplateManager = () => {
  const [templates, setTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState({ title: '', columns: [] });
  const [isEditColumnModalOpen, setIsEditColumnModalOpen] = useState(false);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const [editingColumn, setEditingColumn] = useState(null);
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [editingTemplateIndex, setEditingTemplateIndex] = useState(null);

  useEffect(() => {
    const loadTemplates = () => {
      const storedTemplates = JSON.parse(localStorage.getItem('templates')) || [];
      setTemplates(storedTemplates);
    };

    loadTemplates();
    // LocalStorage'daki değişiklikleri dinle
    window.addEventListener('storage', loadTemplates);

    return () => {
      window.removeEventListener('storage', loadTemplates);
    };
  }, []);

  const saveTemplates = (newTemplates) => {
    setTemplates(newTemplates);
    localStorage.setItem('templates', JSON.stringify(newTemplates));
  };

  const addTemplate = () => {
    if (newTemplate.title.trim() !== '' && newTemplate.columns.length > 0) {
      const updatedTemplates = [...templates, newTemplate];
      saveTemplates(updatedTemplates);
      setNewTemplate({ title: '', columns: [] });
    }
  };

  const removeTemplate = (templateToRemove) => {
    const updatedTemplates = templates.filter(template => template.title !== templateToRemove.title);
    saveTemplates(updatedTemplates);
  };

  const startAddingColumn = () => {
    setEditingColumn({
      law: '',
      penalty: { year: 0, month: 0, day: 0, fine: 0 },
      discountRate: { type: 'indirim', rate: { numerator: 0, denominator: 1 } },
      width: 25,
      height: 40
    });
    setEditingColumnIndex(null);
    setIsEditColumnModalOpen(true);
  };

  const handleEditColumn = (column, index) => {
    setEditingColumn(column);
    setEditingColumnIndex(index);
    setIsEditColumnModalOpen(true);
  };

  const handleSaveColumn = (savedColumn) => {
    const newColumn = {
      ...savedColumn,
      id: savedColumn.id || Date.now().toString(), // Eğer id yoksa yeni bir id oluştur
    };

    if (editingColumnIndex !== null) {
      // Mevcut sütunu güncelle
      const updatedColumns = [...newTemplate.columns];
      updatedColumns[editingColumnIndex] = newColumn;
      setNewTemplate(prev => ({ ...prev, columns: updatedColumns }));
    } else {
      // Yeni sütun ekle
      setNewTemplate(prev => ({
        ...prev,
        columns: [...prev.columns, newColumn]
      }));
    }
    setIsEditColumnModalOpen(false);
  };

  const removeColumnFromTemplate = (columnId) => {
    setNewTemplate(prev => ({
      ...prev,
      columns: prev.columns.filter(col => col.id !== columnId)
    }));
  };

  const formatPenalty = (penalty) => {
    if (!penalty) return '';
    return formatCeza(penalty).replace(/(\d+)([YAGAPC])/g, '<strong>$1</strong>$2 ').trim();
  };

  const formatDiscountRate = (discountRate) => {
    if (!discountRate) return '';
    const { type, rate } = discountRate;
    if (!rate || rate.numerator === 0 || rate.denominator === 0) return '';
    const sign = type === 'indirim' ? '-' : '+';
    return `(${rate.numerator}/${rate.denominator})${sign}`;
  };

  const renderColumnContent = (column) => {
    console.log('Rendering column:', column); // Debugging için

    if (column.isSingleLine) {
      return <div className="whitespace-pre-wrap">{column.content}</div>;
    } else if (column.isTwoLineText) {
      return (
        <>
          <div className="font-bold mb-1">{column.topContent}</div>
          <div>{column.bottomContent}</div>
        </>
      );
    } else if (column.isBasePenalty || column.law) {
      return (
        <>
          <div className="font-bold mb-1">{column.law}</div>
          <div dangerouslySetInnerHTML={{ __html: formatPenalty(column.penalty) }} />
          {!column.isBasePenalty && <div>{formatDiscountRate(column.discountRate)}</div>}
        </>
      );
    } else {
      return <div>Bilinmeyen sütun tipi</div>;
    }
  };

  const renderTemplateColumns = (columns) => {
    return columns.map((column, index) => (
      <div key={column.id || index} className="px-2 mb-2" style={{ width: `${column.width}%` }}>
        <div className="border rounded p-2 h-full overflow-hidden">
          {renderColumnContent(column)}
        </div>
      </div>
    ));
  };

  const handleEditTemplate = (template, index) => {
    setEditingTemplate(template);
    setEditingTemplateIndex(index);
    setIsEditTemplateModalOpen(true);
  };

  const handleSaveTemplate = (savedTemplate) => {
    const updatedTemplates = [...templates];
    updatedTemplates[editingTemplateIndex] = savedTemplate;
    saveTemplates(updatedTemplates);
    setIsEditTemplateModalOpen(false);
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-xl font-semibold mb-4">Yeni Şablon Oluştur</h2>
        <input
          type="text"
          value={newTemplate.title}
          onChange={(e) => setNewTemplate(prev => ({ ...prev, title: e.target.value }))}
          className="w-full p-2 border rounded mb-4"
          placeholder="Şablon Başlığı"
        />
        <div className="mb-4 border rounded p-4">
          <div className="flex flex-wrap -mx-2">
            {renderTemplateColumns(newTemplate.columns)}
            <div className="px-2 mb-4" style={{ width: '25%' }}>
              <button
                className="border rounded p-2 h-full w-full flex items-center justify-center text-gray-500 hover:text-gray-700"
                onClick={startAddingColumn}
              >
                <PlusCircle className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        <button onClick={addTemplate} className="bg-green-500 text-white px-4 py-2 rounded">
          Şablonu Kaydet
        </button>
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-4">Mevcut Şablonlar</h2>
        {templates.map((template, index) => (
          <div key={index} className="mb-4 border rounded p-4">
            <div className="flex justify-between items-center mb-2">
              <h3 className="font-semibold">{template.title}</h3>
              <div>
                <button 
                  onClick={() => handleEditTemplate(template, index)} 
                  className="text-blue-500 mr-2"
                >
                  <Edit2 className="h-4 w-4" />
                </button>
                <button 
                  onClick={() => removeTemplate(template)} 
                  className="text-red-500"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
            <div className="flex flex-wrap -mx-2">
              {renderTemplateColumns(template.columns)}
            </div>
          </div>
        ))}
      </div>

      <EditColumnModal
        isOpen={isEditColumnModalOpen}
        onClose={() => setIsEditColumnModalOpen(false)}
        column={editingColumn}
        onSave={handleSaveColumn}
        prevPenalty={editingColumn?.penalty}
      />

      <EditTemplateModal
        isOpen={isEditTemplateModalOpen}
        onClose={() => setIsEditTemplateModalOpen(false)}
        template={editingTemplate}
        onSave={handleSaveTemplate}
      />
    </div>
  );
};

export default TemplateManager;