import React from 'react';

const DetailsModal = ({ show, sablon, onClose }) => {
  if (!show) return null;

  // Etiketleri ve kanun maddelerini güvenli bir şekilde işleme
  const etiketler = Array.isArray(sablon.etiketler) 
    ? sablon.etiketler 
    : [];
  const kanunMaddeleri = Array.isArray(sablon.kanun_maddeleri) 
    ? sablon.kanun_maddeleri 
    : [];

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Detaylar</h3>
        <div className="space-y-4">
          <div>
            <p className="text-sm text-gray-600">Kategori</p>
            <p className="font-medium text-gray-800">{sablon.kategori || 'Belirtilmemiş'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Etiketler</p>
            <div className="flex flex-wrap gap-2 mt-2">
              {etiketler.map(etiket => (
                <span key={etiket} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded">
                  {etiket}
                </span>
              ))}
            </div>
          </div>
          <div>
            <p className="text-sm text-gray-600">İlgili Kanun Maddeleri</p>
            <div className="flex flex-wrap gap-2 mt-2">
              {kanunMaddeleri.map(madde => (
                <span key={madde} className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded">
                  {madde}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out"
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;
