import React, { useState } from 'react';
import { format, isValid } from 'date-fns';
import { Calendar, ArrowRight, AlertTriangle, UserCheck, UserX, Edit2 } from 'lucide-react';
import EditAppealModal from '../modals/EditAppealModal.js'; // Bu bileşeni oluşturmanız gerekecek

const AppealInfo = ({ appealInfo, partyRole, onUpdate }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isValid(date) ? format(date, 'dd.MM.yyyy') : 'Geçersiz Tarih';
  };

  return (
    <div className="bg-gray-50 rounded-lg p-3 border border-gray-200 text-sm relative group">
      <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute top-2 right-2">
        <button
          onClick={() => setIsEditModalOpen(true)}
          className="text-gray-500 hover:text-gray-700"
        >
          <Edit2 className="w-4 h-4" />
        </button>
      </div>
      <div className="flex items-start space-x-4">
        <div className="flex-grow flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <div className="flex flex-col">
              <span className="text-xs text-gray-500">Tebliğ</span>
              <span className="text-gray-700">{formatDate(appealInfo.notificationDate)}</span>
            </div>
          </div>
          <ArrowRight className="w-4 h-4 text-gray-400" />
          <div className="flex items-center space-x-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <div className="flex flex-col">
              <span className="text-xs text-gray-500">Temyiz</span>
              <span className="text-gray-700">{formatDate(appealInfo.appealDate)}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          {appealInfo.isAdverseAppeal && (
            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              <AlertTriangle className="w-3 h-3 mr-1" />
              Aleyhe Temyiz
            </span>
          )}
          <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${appealInfo.isInPresence ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}>
            {appealInfo.isInPresence ? (
              <>
                <UserCheck className="w-3 h-3 mr-1" />
                Yüze Karşı
              </>
            ) : (
              <>
                <UserX className="w-3 h-3 mr-1" />
                Gıyapta
              </>
            )}
          </span>
        </div>
      </div>
      <EditAppealModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        appealInfo={appealInfo}
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default AppealInfo;