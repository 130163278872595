import React, { useState } from 'react';
import { hesaplaZamanAsimi } from '../zamanasimi/zamanAsimiHesaplayici';
import TarihGirisi from '../zamanasimi/TarihGirisi';
import ZamanAsimiSuresiSecimi from '../zamanasimi/ZamanAsimiSuresiSecimi.js';
import DurduranSebepModal from '../zamanasimi/DurduranSebepModal.js';
import KesenSebepModal from '../zamanasimi/KesenSebepModal.js';
import SebepKarti from '../zamanasimi/SebepKarti.js';
import SonucGosterimi from '../zamanasimi/SonucGosterimi.js';

function ZamanAsimi() {
  const [sucTarihi, setSucTarihi] = useState(new Date());
  const [zamanAsimiSuresi, setZamanAsimiSuresi] = useState(8);
  const [durduranSebepler, setDurduranSebepler] = useState([]);
  const [kesenSebepler, setKesenSebepler] = useState([]);
  const [sonuc, setSonuc] = useState(null);
  const [durduranModalAcik, setDurduranModalAcik] = useState(false);
  const [kesenModalAcik, setKesenModalAcik] = useState(false);

  const hesapla = () => {
    const hesaplananSonuc = hesaplaZamanAsimi(sucTarihi, zamanAsimiSuresi, durduranSebepler, kesenSebepler);
    setSonuc(hesaplananSonuc);
  };

  const ekleKesenSebep = (yeniSebep) => {
    setKesenSebepler([...kesenSebepler, yeniSebep]);
    setKesenModalAcik(false);
  };

  const ekleDurduranSebep = (yeniSebep) => {
    setDurduranSebepler([...durduranSebepler, yeniSebep]);
    setDurduranModalAcik(false);
  };

  const silKesenSebep = (index) => {
    setKesenSebepler(kesenSebepler.filter((_, i) => i !== index));
  };

  const silDurduranSebep = (index) => {
    setDurduranSebepler(durduranSebepler.filter((_, i) => i !== index));
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Zamanaşımı Hesaplama</h1>
      
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TarihGirisi label="Suç Tarihi" value={sucTarihi} onChange={setSucTarihi} />
          <ZamanAsimiSuresiSecimi value={zamanAsimiSuresi} onChange={setZamanAsimiSuresi} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Zamanaşımını Durduran Sebepler</h2>
          <button 
            onClick={() => setDurduranModalAcik(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          >
            Durduran Sebep Ekle
          </button>
          <div className="mt-4 grid grid-cols-1 gap-4">
            {durduranSebepler.map((sebep, index) => (
              <SebepKarti key={index} sebep={sebep} onSil={() => silDurduranSebep(index)} />
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Zamanaşımını Kesen Sebepler</h2>
          <button 
            onClick={() => setKesenModalAcik(true)}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          >
            Kesen Sebep Ekle
          </button>
          <div className="mt-4 grid grid-cols-1 gap-4">
            {kesenSebepler.map((sebep, index) => (
              <SebepKarti key={index} sebep={sebep} onSil={() => silKesenSebep(index)} />
            ))}
          </div>
        </div>
      </div>

      <div className="text-center">
        <button 
          onClick={hesapla} 
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Hesapla
        </button>
      </div>

      <SonucGosterimi sonuc={sonuc} />

      <DurduranSebepModal 
        acik={durduranModalAcik} 
        kapat={() => setDurduranModalAcik(false)} 
        ekle={ekleDurduranSebep}
      />
      <KesenSebepModal 
        acik={kesenModalAcik} 
        kapat={() => setKesenModalAcik(false)} 
        ekle={ekleKesenSebep}
      />
    </div>
  );
}

export default ZamanAsimi;
