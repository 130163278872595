import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { calculateDateDifference } from '../utils/dateUtils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';

// Türkçe lokalizasyonunu kaydet
registerLocale('tr', tr);
setDefaultLocale('tr');

// Ekran boyutunu kontrol etmek için bir hook ekleyelim
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

const DateInput = React.memo(({ label, value, onChange, onBlur, onKeyPress, onComplete = () => {}, inputRef, isEndDate, autoFocusRef, error }) => {
  const [localValue, setLocalValue] = useState(value);
  const [showNativeDatePicker, setShowNativeDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState('');
  const isAutoFocused = useRef(false);
  const buttonRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const formatDate = useCallback((input) => {
    const cleaned = input.replace(/[^\d]/g, '');
    const match = cleaned.match(/^(\d{1,2})(\d{1,2})?(\d{0,4})?$/);
    if (match) {
      const [, day, month, year] = match;
      if (day) {
        if (month) {
          if (year) {
            return `${day}/${month}/${year}`;
          }
          return `${day}/${month}`;
        }
        return day;
      }
    }
    return cleaned;
  }, []);

  const handleChange = useCallback((e) => {
    let input = e.target.value;
    if (input.replace(/[^\d]/g, '').length <= 8) {
      const formatted = formatDate(input);
      setLocalValue(formatted);
      onChange(formatted);
      
      if (formatted.length === 10) {
        onComplete();
      }
    }
    isAutoFocused.current = false;
  }, [formatDate, onChange, onComplete]);

  const handleBlur = useCallback((e) => {
    let formatted = e.target.value;
    if (formatted.length === 8 && !formatted.includes('/')) {
      formatted = `${formatted.slice(0, 2)}/${formatted.slice(2, 4)}/${formatted.slice(4)}`;
    } else if (formatted.length > 5 && formatted.indexOf('/') === 2 && formatted.lastIndexOf('/') === 2) {
      formatted = `${formatted.slice(0, 5)}/${formatted.slice(5)}`;
    }
    setLocalValue(formatted);
    onChange(formatted);
    onBlur(e);
  }, [onChange, onBlur]);

  const handleFocus = useCallback(() => {
    if (isEndDate && autoFocusRef.current) {
      // Otomatik odaklanma durumunda içeriği silme
      isAutoFocused.current = true;
      autoFocusRef.current = false;
    } else {
      // Eğer otomatik odaklanma değilse içeriği sil
      setLocalValue('');
      onChange('');
    }
  }, [onChange, isEndDate, autoFocusRef]);

  const handleCalendarClick = useCallback((e) => {
    e.stopPropagation();
    if (isLargeScreen) {
      setIsDatePickerOpen(true);
    } else {
      setShowNativeDatePicker(true);
    }
    setTempDate('');
    
    // Butonu geçici olarak devre dışı bırak
    if (buttonRef.current) {
      buttonRef.current.style.pointerEvents = 'none';
    }

    // Kısa bir süre sonra butonu tekrar etkinleştir
    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.style.pointerEvents = 'auto';
      }
    }, 300);
  }, [isLargeScreen]);

  const handleNativeDatePickerChange = useCallback((e) => {
    const selectedDate = e.target.value;
    if (selectedDate) {
      setTempDate(selectedDate); // Seçilen tarihi geçici olarak sakla
    }
  }, []);

  const handleNativeDatePickerBlur = useCallback(() => {
    if (tempDate) {
      const date = new Date(tempDate);
      const formattedDate = date.toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\./g, '/');
      setLocalValue(formattedDate);
      onChange(formattedDate);
      onComplete();
    }
    
    // Native date picker'ı kapatmadan önce kısa bir gecikme ekle
    setTimeout(() => {
      setShowNativeDatePicker(false);
    }, 100);
  }, [tempDate, onChange, onComplete]);

  const handleButtonMouseDown = useCallback((e) => {
    // Fare olaylarını engelle
    e.stopPropagation();
  }, []);

  const handleDatePickerChange = useCallback((date) => {
    if (date) {
      const formattedDate = date.toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\./g, '/');
      setLocalValue(formattedDate);
      onChange(formattedDate);
      onComplete();
    }
    setIsDatePickerOpen(false);
  }, [onChange, onComplete]);

  // parseDate fonksiyonunu ekleyelim
  const parseDate = useCallback((dateString) => {
    if (dateString.length !== 10) return null;
    const [day, month, year] = dateString.split(/[-./]/);
    return new Date(year, month - 1, day);
  }, []);

  const currentYear = new Date().getFullYear();
  const yearRange = 100; // 100 yıllık bir aralık belirledik, isteğe göre değiştirilebilir

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-4 relative">
      <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-indigo-600" />
        {label}
      </label>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          className="w-full p-2 pr-12 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="GG/AA/YYYY"
        />
        <button 
          ref={buttonRef}
          onClick={handleCalendarClick}
          onMouseDown={handleButtonMouseDown}
          onTouchStart={handleButtonMouseDown}
          className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center text-indigo-600 hover:bg-indigo-100 rounded-r-md transition-colors duration-200"
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="text-xl" />
        </button>
        {isLargeScreen && isDatePickerOpen && (
          <DatePicker
            selected={localValue ? parseDate(localValue) : null}
            onChange={handleDatePickerChange}
            onClickOutside={() => setIsDatePickerOpen(false)}
            inline
            dateFormat="dd/MM/yyyy"
            locale="tr"
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            scrollableMonthYearDropdown
            yearDropdownItemNumber={yearRange}
            minDate={new Date(currentYear - yearRange, 0, 1)}
            maxDate={new Date()}
            monthNames={[
              "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
              "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
            ]}
            monthNamesShort={[
              "Oca", "Şub", "Mar", "Nis", "May", "Haz",
              "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"
            ]}
            dayNames={["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]}
            dayNamesShort={["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"]}
            dayNamesMin={["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"]}
            dropdownMode="select"
          />
        )}
        {!isLargeScreen && showNativeDatePicker && (
          <input
            type="date"
            onChange={handleNativeDatePickerChange}
            onBlur={handleNativeDatePickerBlur}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            autoFocus
          />
        )}
      </div>
      {error && (
        <div className="absolute top-0 right-0 mt-2 mr-2">
          <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
            {error}
          </span>
        </div>
      )}
    </div>
  );
});

const DateDifference = () => {
  const [dates, setDates] = useState({ start: '', end: '' });
  const [result, setResult] = useState(null);
  const [errors, setErrors] = useState({ start: '', end: '' });
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const autoFocusRef = useRef(false);

  const parseDate = useCallback((dateString) => {
    if (dateString.length !== 10) return null;
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  }, []);

  const isValidDate = useCallback((dateString) => {
    if (dateString.length !== 10) return false;
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return date && date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  }, []);

  const calculateDifference = useCallback((currentDates = dates) => {
    const { start, end } = currentDates;
    
    // Tarih alanlarının dolu olup olmadığını kontrol et
    if (!start || !end || start.length !== 10 || end.length !== 10) {
      setResult(null);
      setErrors({
        start: start.length !== 10 ? 'Geçersiz tarih' : '',
        end: end.length !== 10 ? 'Geçersiz tarih' : ''
      });
      return;
    }

    if (isValidDate(start) && isValidDate(end)) {
      const startDate = parseDate(start);
      const endDate = parseDate(end);
      const [earlierDate, laterDate] = startDate <= endDate ? [startDate, endDate] : [endDate, startDate];
      setResult(calculateDateDifference(earlierDate, laterDate));
      setErrors({ start: '', end: '' });
    } else {
      setResult(null);
      setErrors({
        start: !isValidDate(start) ? 'Geçersiz tarih' : '',
        end: !isValidDate(end) ? 'Geçersiz tarih' : ''
      });
    }
  }, [parseDate, isValidDate, dates]);

  const handleDateChange = useCallback((field) => (value) => {
    setDates(prev => {
      const newDates = { ...prev, [field]: value };
      if (value.length === 10) {
        if (!isValidDate(value)) {
          setErrors(prev => ({ ...prev, [field]: 'Geçersiz tarih' }));
        } else {
          setErrors(prev => ({ ...prev, [field]: '' }));
          const otherField = field === 'start' ? 'end' : 'start';
          if (newDates[otherField].length === 10 && isValidDate(newDates[otherField])) {
            calculateDifference(newDates);
          }
        }
      } else {
        setErrors(prev => ({ ...prev, [field]: '' }));
        setResult(null);
      }
      return newDates;
    });
  }, [isValidDate, calculateDifference]);

  const handleDateBlur = useCallback((field) => (e) => {
    const formattedDate = e.target.value.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    setDates(prev => ({ ...prev, [field]: formattedDate }));
  }, []);

  const handleStartDateComplete = useCallback(() => {
    if (endDateRef.current) {
      autoFocusRef.current = true;
      endDateRef.current.focus();
    }
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      if (dates.start && dates.end) {
        calculateDifference();
      } else if (dates.start && !dates.end && endDateRef.current) {
        endDateRef.current.focus();
      }
    }
  }, [dates, calculateDifference]);

  const dateInputs = useMemo(() => [
    { label: "Başlangıç Tarihi", value: dates.start, onChange: handleDateChange('start'), ref: startDateRef, onComplete: handleStartDateComplete, error: errors.start },
    { label: "Bitiş Tarihi", value: dates.end, onChange: handleDateChange('end'), ref: endDateRef, isEndDate: true, error: errors.end }
  ], [dates, handleDateChange, handleStartDateComplete, errors]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-100 to-purple-100">
      <div className="flex-grow max-w-4xl w-full mx-auto px-4 py-4 sm:py-6">
        <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center text-indigo-800">Tarih Farkı Hesaplayıcı</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mb-4">
          {dateInputs.map((input, index) => (
            <DateInput
              key={index}
              label={input.label}
              value={input.value}
              onChange={input.onChange}
              onBlur={handleDateBlur(index === 0 ? 'start' : 'end')}
              onKeyPress={handleKeyPress}
              onComplete={input.onComplete}
              inputRef={input.ref}
              isEndDate={input.isEndDate}
              autoFocusRef={autoFocusRef}
              error={input.error}
            />
          ))}
        </div>

        <button
          onClick={() => calculateDifference()}
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 sm:py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 mb-4"
        >
          <FontAwesomeIcon icon={faCalculator} className="mr-2" />
          Hesapla
        </button>

        {result && dates.start.length === 10 && dates.end.length === 10 && (
          <div className="bg-gradient-to-br from-blue-500 to-indigo-700 rounded-lg shadow-lg p-4 sm:p-6 text-white relative overflow-hidden">
            <p className="text-2xl sm:text-3xl font-bold text-center">
              {result}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateDifference;
