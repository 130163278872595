// Function to parse dates in "DD.MM.YYYY" format
export const parseDate = (input) => {
    if (!input) return null;
    if (input instanceof Date) return input;

    // DD.MM.YYYY formatını kontrol et
    const ddmmyyyyRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    const ddmmyyyyMatch = input.match(ddmmyyyyRegex);
    if (ddmmyyyyMatch) {
        const [, day, month, year] = ddmmyyyyMatch;
        return new Date(year, month - 1, day);
    }

    // YYYY-MM-DD formatını kontrol et
    const yyyymmddRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const yyyymmddMatch = input.match(yyyymmddRegex);
    if (yyyymmddMatch) {
        const [, year, month, day] = yyyymmddMatch;
        return new Date(year, month - 1, day);
    }

    // Diğer olası formatları buraya ekleyebilirsiniz

    console.warn('Geçersiz tarih formatı:', input);
    return null;
};

export const calculateDateDifference = (startDate, endDate) => {
    const start = parseDate(startDate);
    const end = parseDate(endDate);

    if (!start || !end) {
        return null;
    }

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();

    // Adjust days
    if (days < 0) {
        months--;
        const prevMonth = new Date(end.getFullYear(), end.getMonth(), 0);
        days += prevMonth.getDate();
    }

    // Adjust months
    if (months < 0) {
        years--;
        months += 12;
    }

    let diff = "";

    if (years > 0) diff += `${years} yıl`;
    if (months > 0) diff += `${diff ? " " : ""}${months} ay`;
    if (days > 0) diff += `${diff ? " " : ""}${days} gün`;

    return diff || "0 gün";
};

export const calculateAge = (birthDate, referenceDate) => {
    if (!birthDate) return null;

    const dob = parseDate(birthDate);
    const refDate = referenceDate ? parseDate(referenceDate) : new Date();

    if (!dob || !refDate) {
        return null;
    }

    return calculateDateDifference(dob, refDate);
};

export const calculateAgeAtDate = (birthDate, targetDate) => {
    if (!birthDate || !targetDate) return null;

    const birth = parseDate(birthDate);
    const target = parseDate(targetDate);

    if (!birth || !target) return null;

    const ageDifference = calculateDateDifference(birth, target);

    if (!ageDifference) return null;

    // Sadece yıl kısmını al
    const yearMatch = ageDifference.match(/(\d+)\s*yıl/);
    if (yearMatch) {
        return parseInt(yearMatch[1], 10);
    }

    // Eğer 1 yıldan az ise, ay veya gün olarak göster
    const monthMatch = ageDifference.match(/(\d+)\s*ay/);
    if (monthMatch) {
        return `${monthMatch[1]} ay`;
    }

    const dayMatch = ageDifference.match(/(\d+)\s*gün/);
    if (dayMatch) {
        return `${dayMatch[1]} gün`;
    }

    return '0';
};

