import React, { useState, useEffect } from 'react';
import Select from 'react-select/creatable';
import { createTakrir, updateTakrir, searchTakrirler } from '../../services/takrirService';
import { useGeneralInfo } from '../../contexts/GeneralInfoContext';

const TakrirCard = ({ info = {}, onUpdate }) => {
  const { updateGeneralInfo } = useGeneralInfo();
  const [localInfo, setLocalInfo] = useState({
    id: null,
    hakim: '',
    yargitayEsasNo: '',
    takrirGunu: '',
  });
  const [hakimOptions, setHakimOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [error, setError] = useState('');
  const [isNewTakrir, setIsNewTakrir] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTakrir, setSelectedTakrir] = useState(null);

  useEffect(() => {
    if (info && Object.keys(info).length > 0) {
      const formattedInfo = {
        id: info.id,
        hakim: info.hakim || info.hakim_adi,
        yargitayEsasNo: info.yargitayEsasNo || info.yargitay_esas_no,
        takrirGunu: formatDate(info.takrirGunu || info.takrir_gunu),
      };
      setLocalInfo(formattedInfo);
      setIsEditing(false);
      setIsNewTakrir(false);
    } else {
      setLocalInfo({
        id: null,
        hakim: '',
        yargitayEsasNo: '',
        takrirGunu: '',
      });
      setIsEditing(true);
      setIsNewTakrir(true);
    }
    loadHakimOptions();
  }, [info]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const loadHakimOptions = () => {
    const storedHakimler = JSON.parse(localStorage.getItem('hakimler')) || [];
    setHakimOptions(storedHakimler.map(hakim => ({ value: hakim, label: hakim })));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleHakimChange = (selectedOption) => {
    setLocalInfo(prev => ({ ...prev, hakim: selectedOption ? selectedOption.value : '' }));
  };

  const handleCreateHakim = (inputValue) => {
    const newHakim = { value: inputValue, label: inputValue };
    setHakimOptions(prev => [...prev, newHakim]);
    
    const storedHakimler = JSON.parse(localStorage.getItem('hakimler')) || [];
    localStorage.setItem('hakimler', JSON.stringify([...storedHakimler, inputValue]));
    
    setLocalInfo(prev => ({ ...prev, hakim: inputValue }));
    return newHakim;
  };

  const formatYargitayEsasNo = (value) => {
    const cleaned = value.replace(/[^\d/]/g, '');
    if (cleaned.includes('/') && cleaned.indexOf('/') === 4) {
      return cleaned;
    }
    const numbers = cleaned.replace(/\D/g, '');
    if (numbers.length > 4) {
      return `${numbers.slice(0, 4)}/${numbers.slice(4)}`;
    }
    return numbers;
  };

  const handleYargitayEsasNoChange = (e) => {
    let inputValue = e.target.value;
    if (inputValue.includes('/') && inputValue.indexOf('/') < 4) {
      inputValue = inputValue.replace('/', '');
    }
    const formattedValue = formatYargitayEsasNo(inputValue);
    setLocalInfo(prev => ({ ...prev, yargitayEsasNo: formattedValue }));
  };

  const handleSave = async () => {
    setError('');
    try {
      let result;
      const takrirData = {
        ...localInfo,
        takrirGunu: localInfo.takrirGunu || null
      };

      if (isNewTakrir) {
        result = await createTakrir(takrirData);
      } else {
        result = await updateTakrir(takrirData);
      }
      
      console.log('Sunucudan gelen veri:', result);

      if (result.takrir) {
        const updatedTakrir = {
          id: result.takrir.id,
          hakim: result.takrir.hakim || result.takrir.hakim_adi,
          yargitayEsasNo: result.takrir.yargitay_esas_no || result.takrir.yargitayEsasNo,
          takrirGunu: result.takrir.takrir_gunu || result.takrir.takrirGunu,
        };
        setLocalInfo(updatedTakrir);
        onUpdate(updatedTakrir);
        setIsEditing(false);
        setIsNewTakrir(false);
        
        // GeneralInfoContext'i güncelle
        updateGeneralInfo({ takrirId: result.takrir.id });
      } else {
        throw new Error('Sunucudan beklenen veri alınamadı');
      }
    } catch (error) {
      console.error('Takrir kaydetme/güncelleme hatası:', error);
      if (error.response && error.response.status === 404) {
        setError('Güncellenecek takrir bulunamadı. Lütfen sayfayı yenileyip tekrar deneyin.');
      } else if (error.response && error.response.status === 409) {
        setError('Bu Yargıtay Esas Numarası ile daha önce bir kayıt oluşturulmuş.');
      } else if (error.message === "Takrir ID'si bulunamadı") {
        setError('Takrir ID\'si bulunamadı. Lütfen sayfayı yenileyip tekrar deneyin.');
      } else if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError('Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleNewTakrir = () => {
    setLocalInfo({
      hakim: '',
      yargitayEsasNo: '',
      takrirGunu: '',
    });
    setIsEditing(true);
    setIsNewTakrir(true);
    setError('');
    
    // GeneralInfoContext'teki takrirId'yi sıfırla
    updateGeneralInfo({ takrirId: null });
  };

  const handleSearch = async () => {
    try {
      const results = await searchTakrirler(searchTerm);
      setSearchResults(results);
    } catch (error) {
      console.error('Takrir arama hatası:', error);
      setError('Arama sırasında bir hata oluştu.');
    }
  };

  const handleSelectTakrir = (takrir) => {
    setSelectedTakrir(takrir);
    setLocalInfo({
      id: takrir.id,
      hakim: takrir.hakim || takrir.hakim_adi,
      yargitayEsasNo: takrir.yargitayEsasNo || takrir.yargitay_esas_no,
      takrirGunu: formatDate(takrir.takrirGunu || takrir.takrir_gunu),
    });
    setIsEditing(false);
    setIsNewTakrir(false);
    updateGeneralInfo({ takrirId: takrir.id });
    setSearchResults([]); // Arama sonuçlarını temizle
    setSearchTerm(''); // Arama terimini temizle
  };

  return (
    <div className="space-y-4 bg-white p-4 rounded-lg shadow">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {/* Arama bölümü */}
      <div className="flex space-x-2">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Takrir günü veya Yargıtay esas no"
          className="flex-1 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-3 py-2"
        />
        <button
          onClick={handleSearch}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Ara
        </button>
      </div>

      {/* Arama sonuçları */}
      {searchResults.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Arama Sonuçları:</h3>
          <ul className="space-y-2">
            {searchResults.map((takrir) => (
              <li key={takrir.id} className="flex justify-between items-center bg-gray-100 p-2 rounded">
                <span>{takrir.yargitayEsasNo || takrir.yargitay_esas_no} - {formatDate(takrir.takrirGunu || takrir.takrir_gunu)}</span>
                <button
                  onClick={() => handleSelectTakrir(takrir)}
                  className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                >
                  Seç
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Mevcut TakrirCard içeriği */}
      {isEditing ? (
        <>
          <div className="flex space-x-4">
            <div className="flex-1">
              <label htmlFor="hakim" className="block text-sm font-medium text-gray-700">Hâkim</label>
              <Select
                id="hakim"
                name="hakim"
                options={hakimOptions}
                value={hakimOptions.find(option => option.value === localInfo.hakim) || null}
                onChange={handleHakimChange}
                onCreateOption={handleCreateHakim}
                className="mt-1 block w-full"
                classNamePrefix="select"
                isClearable
                isSearchable
                placeholder="Hâkim seçin veya yeni ekleyin"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="yargitayEsasNo" className="block text-sm font-medium text-gray-700">Yargıtay Esas No</label>
              <input
                type="text"
                id="yargitayEsasNo"
                name="yargitayEsasNo"
                value={localInfo.yargitayEsasNo || ''}
                onChange={handleYargitayEsasNoChange}
                placeholder="YYYY/NNNN"
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-3 py-2"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="takrirGunu" className="block text-sm font-medium text-gray-700">Takrir Günü</label>
              <input
                type="date"
                id="takrirGunu"
                name="takrirGunu"
                value={localInfo.takrirGunu || ''}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-3 py-2"
              />
            </div>
          </div>
          <button
            onClick={handleSave}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Kaydet
          </button>
        </>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <span className="font-semibold">Hâkim:</span> {localInfo.hakim || 'Belirtilmemiş'}
            </div>
            <div>
              <span className="font-semibold">Yargıtay Esas No:</span> {localInfo.yargitayEsasNo || 'Belirtilmemiş'}
            </div>
            <div>
              <span className="font-semibold">Takrir Günü:</span> {localInfo.takrirGunu || 'Belirtilmemiş'}
            </div>
          </div>
          <div className="mt-4">
            <button
              onClick={handleEdit}
              className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
            >
              Düzenle
            </button>
            <button
              onClick={handleNewTakrir}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors ml-2"
            >
              Yeni Takrir
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TakrirCard;