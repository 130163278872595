const PREDEFINED_COLUMNS_KEY = 'predefinedColumns';

export const getPredefinedColumns = () => {
  const columns = localStorage.getItem(PREDEFINED_COLUMNS_KEY);
  return columns ? JSON.parse(columns) : [];
};

export const addPredefinedColumn = (column) => {
  const columns = getPredefinedColumns();
  const newColumn = { ...column, id: Date.now() };
  columns.push(newColumn);
  localStorage.setItem(PREDEFINED_COLUMNS_KEY, JSON.stringify(columns));
};

export const updatePredefinedColumn = (updatedColumn) => {
  const columns = getPredefinedColumns();
  const index = columns.findIndex(col => col.id === updatedColumn.id);
  if (index !== -1) {
    columns[index] = updatedColumn;
    localStorage.setItem(PREDEFINED_COLUMNS_KEY, JSON.stringify(columns));
  }
};

export const deletePredefinedColumn = (columnId) => {
  const columns = getPredefinedColumns();
  const updatedColumns = columns.filter(col => col.id !== columnId);
  localStorage.setItem(PREDEFINED_COLUMNS_KEY, JSON.stringify(updatedColumns));
};