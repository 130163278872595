export const saveCardAsTemplate = (card) => {
    const storedTemplates = JSON.parse(localStorage.getItem('templates')) || [];
    
    const newTemplate = {
      title: `Şablon ${storedTemplates.length + 1}`,
      columns: card.columns.map(column => ({
        ...column,
        id: undefined // ID'yi kaldırıyoruz çünkü yeni bir şablon oluşturulduğunda yeni ID'ler atanacak
      }))
    };
  
    const updatedTemplates = [...storedTemplates, newTemplate];
    localStorage.setItem('templates', JSON.stringify(updatedTemplates));
  
    return newTemplate;
  };