import axios from 'axios';

const API_URL = 'http://localhost:5000/api/ceza-sahsilestirme';

export const createCezaSahsilestirme = async (takrirId, sahsilestirmeMetni) => {
  const response = await axios.post(API_URL, { takrirId, sahsilestirmeMetni });
  return response.data;
};

export const getCezaSahsilestirme = async (takrirId) => {
  const response = await axios.get(`${API_URL}/${takrirId}`);
  return response.data;
};

export const updateCezaSahsilestirme = async (takrirId, sahsilestirmeMetni) => {
  const response = await axios.put(`${API_URL}/${takrirId}`, { sahsilestirmeMetni });
  return response.data;
};

export const deleteCezaSahsilestirme = async (takrirId) => {
  const response = await axios.delete(`${API_URL}/${takrirId}`);
  return response.data;
};