import React, { useState } from 'react';
import { TagIcon, FolderIcon, CalendarIcon, UserIcon, TrashIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

const SablonKarti = ({ sablon, onClick, onDelete }) => {
  const [showBack, setShowBack] = useState(false);

  const temizleVeKisalt = (metin, uzunluk) => {
    if (!metin) return 'İçerik yok';
    const temizMetin = metin
      .replace(/<[^>]*>/g, '') // HTML etiketlerini kaldır
      .replace(/&nbsp;/g, ' ') // &nbsp; karakterlerini boşlukla değiştir
      .replace(/\s+/g, ' ') // Birden fazla boşluğu tek boşluğa indir
      .trim();
    return temizMetin.length > uzunluk ? temizMetin.slice(0, uzunluk) + '...' : temizMetin;
  };

  const kisaltilmisBaslik = temizleVeKisalt(sablon.baslik, 110);

  return (
    <div 
      className="bg-white p-6 rounded-lg shadow-md cursor-pointer hover:shadow-xl transition-shadow duration-200 border border-gray-200 flex flex-col h-full w-full relative group"
      onClick={() => onClick(sablon)}
    >
      {!showBack ? (
        <>
          <h3 className="text-lg font-semibold mb-2 text-gray-800">{kisaltilmisBaslik}</h3>
          {sablon.ozet && (
            <p className="text-sm text-gray-600 mb-4 flex-grow bg-gray-100 p-3 rounded leading-relaxed overflow-y-auto">
              {temizleVeKisalt(sablon.ozet, 400)}
            </p>
          )}
          <InformationCircleIcon
            className="h-6 w-6 text-gray-500 absolute bottom-3 right-3 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setShowBack(true);
            }}
          />
        </>
      ) : (
        <div className="flex flex-col overflow-y-auto p-3">
          <div className="mb-2">
            <h4 className="font-semibold text-gray-700 mb-1">Kategori:</h4>
            <span className="text-sm text-gray-600 bg-gray-100 px-2 py-1 rounded">{sablon.kategori || 'Belirtilmemiş'}</span>
          </div>
          <div className="mb-2">
            <h4 className="font-semibold text-gray-700 mb-1">Kanun Maddeleri:</h4>
            <div className="flex flex-wrap gap-1">
              {Array.isArray(sablon.kanun_maddeleri) && sablon.kanun_maddeleri.length > 0 ? (
                sablon.kanun_maddeleri.map(madde => (
                  <span key={typeof madde === 'string' ? madde : madde.value} className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded">
                    {typeof madde === 'string' ? madde : madde.label}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">Kanun maddesi belirtilmemiş</span>
              )}
            </div>
          </div>
          <div className="mb-2">
            <h4 className="font-semibold text-gray-700 mb-1">Etiketler:</h4>
            <div className="flex flex-wrap gap-1">
              {Array.isArray(sablon.etiketler) && sablon.etiketler.map(etiket => (
                <span key={typeof etiket === 'string' ? etiket : etiket.value} className="flex items-center bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded">
                  <TagIcon className="h-3 w-3 mr-1" />
                  {typeof etiket === 'string' ? etiket : etiket.label}
                </span>
              ))}
            </div>
          </div>
          <button
            className="absolute bottom-3 left-3 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-opacity duration-200 opacity-0 group-hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm('Bu şablonu silmek istediğinizden emin misiniz?')) {
                onDelete(sablon.id);
              }
            }}
          >
            <TrashIcon className="h-4 w-4" />
          </button>
          <button
            className="absolute bottom-3 right-3 bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-300"
            onClick={(e) => {
              e.stopPropagation();
              setShowBack(false);
            }}
          >
            Geri
          </button>
        </div>
      )}
    </div>
  );
};

export default SablonKarti;
