import { turkishToLower } from '../../utils/stringUtils';

export function filtreliSablonlariGetir(sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni) {
  const aramaMetniKucuk = turkishToLower(aramaMetni);
  const tumKategorilerSecili = secilenKategoriler.includes('Tümü');
  
  return sablonlar.filter(sablon => 
    (tumKategorilerSecili || secilenKategoriler.includes(sablon.kategori)) &&
    (secilenEtiketler.length === 0 || (sablon.etiketler && secilenEtiketler.every(etiket => sablon.etiketler.includes(etiket)))) &&
    (secilenKanunMaddeleri.length === 0 || (sablon.kanun_maddeleri && secilenKanunMaddeleri.every(madde => sablon.kanun_maddeleri.includes(madde)))) &&
    (turkishToLower(sablon.baslik).includes(aramaMetniKucuk) ||
     (sablon.etiketler && sablon.etiketler.some(etiket => turkishToLower(etiket).includes(aramaMetniKucuk))) ||
     (sablon.icerik && turkishToLower(sablon.icerik).includes(aramaMetniKucuk)))
  );
}
