import React, { useRef, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDrag, useDrop } from 'react-dnd';
import { getCardStyle } from '../utils/cardStyles.js';
import AISummaryButton from './ozetleme/AISummaryButton.js';

const StatementCard = ({ id, index, info, onUpdate, onDelete, moveCard }) => {
  console.log("StatementCard render ediliyor:", info);
  
  const [localInfo, setLocalInfo] = useState(info || {});
  const ref = useRef(null);

  useEffect(() => {
    if (info) {
      // 'ifade' tipini 'tanık' olarak değiştir
      const updatedInfo = {...info, type: info.type === 'ifade' ? 'tanık' : info.type};
      setLocalInfo(updatedInfo);
    }
  }, [info]);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleChange = (field, value) => {
    setLocalInfo(prev => ({ ...prev, [field]: value }));
  };

  const handleBlur = () => {
    if (typeof onUpdate === 'function') {
      onUpdate(id, localInfo);
    }
  };

  const handleTypeChange = (newType) => {
    console.log("Tip değişikliği:", localInfo.type, "->", newType);
    if (newType !== localInfo.type) {
      onUpdate(id, { ...localInfo, type: newType });
    }
  };

  const cardStyle = getCardStyle(localInfo.type);

  const getCategoryOptions = () => {
    if (localInfo.type === 'sanık') {
      return ['Sanık', 'Suça Sürüklenen Çocuk', 'Temyiz Dışı'];
    } else if (localInfo.type === 'mağdur') {
      return ['Mağdur', 'Katılan', 'Şikâyetçi'];
    } else if (localInfo.type === 'tanık') {
      return ['Tanık'];
    } else {
      return ['Olay Özeti', 'İddianame Özeti', 'İDM Gerekçesi','Bam Gerekçesi', 'Yargıtay Kararı', 'Diğer'];
    }
  };

  if (!localInfo) {
    return null; // veya bir yükleme göstergesi
  }

  return (
    <div 
      ref={ref} 
      style={{ opacity: isDragging ? 0.5 : 1 }} 
      className={`mb-6 p-6 bg-white shadow-md rounded-lg transition-all duration-300 ease-in-out hover:shadow-lg ${cardStyle.background} ${cardStyle.border}`}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2 w-1/3">
            {localInfo.type !== 'özet' && (
              <select 
                value={localInfo.type || ''}
                onChange={(e) => handleTypeChange(e.target.value)}
                onBlur={handleBlur}
                className="p-2 text-sm bg-transparent border-b border-gray-300 focus:border-blue-500 focus:outline-none"
              >
                <option value="tanık">Tanık</option>
                <option value="mağdur">Mağdur</option>
                <option value="sanık">Sanık</option>
              </select>
            )}
            <select 
              value={localInfo.category || ''}
              onChange={(e) => handleChange('category', e.target.value)}
              onBlur={handleBlur}
              className="p-2 text-sm bg-transparent border-b border-gray-300 focus:border-blue-500 focus:outline-none"
            >
              {getCategoryOptions().map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {localInfo.type === 'özet' && localInfo.category === 'Diğer' && (
              <input 
                type="text"
                value={localInfo.customCategory || ''}
                onChange={(e) => handleChange('customCategory', e.target.value)}
                onBlur={handleBlur}
                placeholder="Özel özet türü"
                className="p-2 text-sm bg-transparent border-b border-gray-300 focus:border-blue-500 focus:outline-none"
              />
            )}
          </div>
          
          {localInfo.type !== 'özet' && (
            <input 
              type="text"
              value={localInfo.name || ''}
              onChange={(e) => handleChange('name', e.target.value)}
              onBlur={handleBlur}
              placeholder="Ad Soyad"
              className="w-1/3 p-2 text-lg font-semibold bg-transparent border-b border-gray-300 focus:border-blue-500 focus:outline-none text-center"
            />
          )}
          
          <div className="flex items-center space-x-2 w-1/3 justify-end">
            <input 
              type="date" 
              value={localInfo.date || ''}
              onChange={(e) => handleChange('date', e.target.value)}
              onBlur={handleBlur}
              className="p-2 text-sm bg-transparent border-b border-gray-300 focus:border-blue-500 focus:outline-none"
            />
            {localInfo.type !== 'özet' && (
              <select 
                value={localInfo.stage || ''}
                onChange={(e) => handleChange('stage', e.target.value)}
                onBlur={handleBlur}
                className="p-2 text-sm bg-transparent border-b border-gray-300 focus:border-blue-500 focus:outline-none"
              >
                <option value="Mahkeme">Mahkeme</option>
                <option value="Savcılık">Savcılık</option>
                <option value="Kolluk">Kolluk</option>
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ReactQuill 
          theme="snow"
          value={localInfo.content || ''}
          onChange={(content) => handleChange('content', content)}
          onBlur={handleBlur}
          className="bg-transparent"
        />
      </div>
      <div className="mt-4 flex justify-end items-center">
        <AISummaryButton content={localInfo.content || ''} />
        <button 
          onClick={() => onDelete(id)}
          className="text-sm text-gray-400 hover:text-red-500 transition-colors duration-300 focus:outline-none"
        >
          Sil
        </button>
      </div>
    </div>
  );
};

export default StatementCard;