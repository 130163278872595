export function tarihFarki(baslangic, bitis) {
  const fark = bitis.getTime() - baslangic.getTime();
  const gunSayisi = Math.floor(fark / (1000 * 60 * 60 * 24));
  const yil = Math.floor(gunSayisi / 365);
  const ay = Math.floor((gunSayisi % 365) / 30);
  const gun = gunSayisi % 30;

  return { yil, ay, gun, gunSayisi };
}

export function ekleYil(tarih, yil) {
  const yeniTarih = new Date(tarih);
  yeniTarih.setFullYear(yeniTarih.getFullYear() + yil);
  return yeniTarih;
}

export function ekleGun(tarih, gun) {
  const yeniTarih = new Date(tarih);
  yeniTarih.setDate(yeniTarih.getDate() + gun);
  return yeniTarih;
}

