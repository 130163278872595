import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, BorderStyle, AlignmentType } from 'docx';
import { BORDER_COLOR } from './constants';
import { formatDate } from './utils';

export const createTakrirTable = (takrirInfo) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      bottom: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      left: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      right: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      insideHorizontal: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
      insideVertical: { style: BorderStyle.SINGLE, size: 1, color: BORDER_COLOR },
    },
    columnWidths: [3333, 3333, 3334], // Eşit genişlikte üç sütun (toplam 10000)
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({})],
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({
                  text: "YARGITAY 2. CEZA DAİRESİ",
                  bold: true,
                })],
                alignment: AlignmentType.CENTER,
                
              }),
              new Paragraph({
                children: [new TextRun({
                  text: "TAKRİR RAPORU",
                  bold: true,
                })],
                alignment: AlignmentType.CENTER,
              })
            ],
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: takrirInfo.yargitayEsasNo || '',
                bold: true,
                size: 36,
              })],
              alignment: AlignmentType.RIGHT,
            })],
            margins: {
              left: 100,
              right: 100,
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: `T. Hakimi: ${takrirInfo.hakim || ''}`,
              })],
              alignment: AlignmentType.LEFT,
            })],
          }),
          new TableCell({
            children: [new Paragraph({})],
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: `Takrir Günü: ${formatDate(takrirInfo.takrirGunu) || ''}`,
              })],
              alignment: AlignmentType.RIGHT,
            })],
            margins: {
              left: 100,
              right: 100,
            },
          }),
        ],
      }),
    ],
  });
};

