import React from 'react';

const zamanAsimiSureleri = [
  { label: '5 Yıl', value: 5 },
  { label: '8 Yıl', value: 8 },
  { label: '15 Yıl', value: 15 },
  { label: '20 Yıl', value: 20 },
];

function ZamanAsimiSuresiSecimi({ value, onChange }) {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zamanAsimiSuresi">
        Zamanaşımı Süresi
      </label>
      <select
        id="zamanAsimiSuresi"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      >
        {zamanAsimiSureleri.map((sure) => (
          <option key={sure.value} value={sure.value}>
            {sure.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ZamanAsimiSuresiSecimi;

