import React, { useRef, useEffect } from 'react';
import { PlusCircle, ChevronDown } from 'lucide-react';

const AddCardMenu = ({ isOpen, toggleMenu, addCard, templates }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, toggleMenu]);

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <button 
        className="px-2 py-1 text-xs border rounded inline-flex items-center"
        onClick={toggleMenu}
      >
        <PlusCircle className="h-3 w-3 mr-1" /> Yeni Ceza Uygulaması <ChevronDown className="h-3 w-3 ml-1" />
      </button>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
              onClick={() => {
                addCard();
                toggleMenu();
              }}
            >
              Boş Kart
            </button>
            {templates.map((template, index) => (
              <button
                key={index}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                onClick={() => {
                  addCard(template);
                  toggleMenu();
                }}
              >
                {template.title}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCardMenu;