import React, { useState, lazy, Suspense } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import 'react-quill/dist/quill.snow.css';
import AIEnhancer from './AIEnhancer';
import { Etiketleme, KanunMaddeleri } from './Etiketleme';
import DetaylarModal from './DetaylarModal';

const ReactQuill = lazy(() => import('react-quill'));

const DuzenlemeFormu = ({
  sablon,
  duzenlenmisSablon,
  kategoriler,
  kayitliEtiketler,
  kayitliKanunMaddeleri,
  handleChange,
  handleIcerikChange,
  handleSelectChange,  // Bu prop'u ekleyin
  handleSubmit,
  handleIptal,
  handleAIEnhance
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleModalSave = () => {
    // Burada modal içindeki değişiklikleri kaydetme işlemini yapabilirsiniz
    // Örneğin, duzenlenmisSablon state'ini güncelleyebilirsiniz
    toggleModal();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto p-6 space-y-6">
        <div className="space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg shadow-inner border border-gray-300">
            <label htmlFor="icerik" className="block text-lg font-semibold text-gray-800 mb-2">İçerik</label>
            <div className="h-[calc(100vh-450px)] min-h-[300px] overflow-hidden">
              <Suspense fallback={<div>Yükleniyor...</div>}>
                <ReactQuill
                  id="icerik"
                  value={duzenlenmisSablon.icerik}
                  onChange={handleIcerikChange}
                  className="h-full pb-12"
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, false] }],
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['link', 'image'],
                      ['clean']
                    ],
                  }}
                />
              </Suspense>
            </div>
          </div>
          <AIEnhancer
            icerik={duzenlenmisSablon.icerik}
            mevcutEtiketler={duzenlenmisSablon.etiketler}
            mevcutKanunMaddeleri={duzenlenmisSablon.kanunMaddeleri || []}
            onEnhance={handleAIEnhance}
          />
        </div>
      </div>
      <div className="flex justify-between items-center p-4 bg-gray-100 border-t">
        <button
          type="button"
          onClick={toggleModal}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-150 ease-in-out"
        >
          Detayları Düzenle
        </button>
        <div className="flex space-x-3">
          <button
            type="button"
            onClick={handleIptal}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out"
          >
            İptal
          </button>
          <button
            type="submit"
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out"
          >
            Kaydet
          </button>
        </div>
      </div>
      {isModalOpen && (
        <DetaylarModal
          duzenlenmisSablon={duzenlenmisSablon}
          kategoriler={kategoriler}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}  // Bu prop'u ekleyin
          kayitliEtiketler={kayitliEtiketler}
          kayitliKanunMaddeleri={kayitliKanunMaddeleri}
          onClose={toggleModal}
          onSave={handleModalSave}  // Yeni prop ekleyelim
        />
      )}
    </form>
  );
};

export default DuzenlemeFormu;