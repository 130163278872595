import axios from 'axios';

const API_URL = 'http://localhost:5000/api/summaries';

export const saveSummary = async (summaryData) => {
  try {
    const response = await axios.post(API_URL, summaryData);
    return response.data;
  } catch (error) {
    console.error('Özet kaydetme hatası:', error);
    throw error;
  }
};

export const getSummary = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Özet getirme hatası:', error);
    throw error;
  }
};

export const updateSummary = async (id, summaryData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, summaryData);
    return response.data;
  } catch (error) {
    console.error('Özet güncelleme hatası:', error);
    throw error;
  }
};

export const deleteSummary = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Özet silme hatası:', error);
    throw error;
  }
};

export const getAllSummaries = async () => {
  try {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  } catch (error) {
    console.error('Tüm özetleri getirme hatası:', error);
    throw error;
  }
};
