import axios from 'axios';

const API_ENDPOINT = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';

export async function predictWithGoogleAI(prompt) {
  const apiKey = process.env.REACT_APP_GOOGLE_AI_API_KEY;

  if (!apiKey) {
    throw new Error('API anahtarı tanımlanmamış');
  }

  try {
    const response = await axios.post(
      `${API_ENDPOINT}?key=${apiKey}`,
      {
        contents: [
          {
            parts: [
              { text: prompt }
            ]
          }
        ]
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // API yanıtının yapısına göre bu kısmı ayarlayın
    return response.data.candidates[0].content.parts[0].text;
  } catch (error) {
    console.error('AI tahmininde bir hata oluştu:', error);
    if (error.response) {
      console.error('Yanıt verisi:', error.response.data);
      console.error('Durum kodu:', error.response.status);
      console.error('Yanıt başlıkları:', error.response.headers);
    } else if (error.request) {
      console.error('İstek:', error.request);
    } else {
      console.error('Hata mesajı:', error.message);
    }
    throw error;
  }
}