import React, { useState } from 'react';
import { predictWithGoogleAI } from '../../utils/googleAI.js';
import SummaryModal from './SummaryModal.js';

const AISummaryButton = ({ content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState('');

  const createPrompt = (customPrompt = '') => {
    return `${customPrompt || 'Lütfen aşağıdaki metin içerisindeki olayı kısa cümleler kullanarak özetle, özeti mümkün olduğunca uzun tut, özette tarih ve saat bilgileri dataylı olarak yer alsın:'}\n\n${content}`;
  };

  const handleSummarize = async () => {
    setIsLoading(true);
    try {
      const prompt = createPrompt();
      setInitialPrompt(prompt);
      const result = await predictWithGoogleAI(prompt);
      setSummary(result);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Özet oluşturulurken bir hata oluştu:', error);
      alert('Özet oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegenerate = async (newPrompt) => {
    setIsLoading(true);
    try {
      const result = await predictWithGoogleAI(newPrompt);
      setSummary(result);
    } catch (error) {
      console.error('Özet yeniden oluşturulurken bir hata oluştu:', error);
      alert('Özet yeniden oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={handleSummarize}
        disabled={isLoading}
        className="text-sm text-blue-500 hover:text-blue-700 transition-colors duration-300 focus:outline-none mr-2"
      >
        {isLoading ? 'Özetleniyor...' : 'AI ile Özetle'}
      </button>
      <SummaryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        summary={summary}
        onRegenerate={handleRegenerate}
        initialPrompt={initialPrompt}
        isLoading={isLoading}
      />
    </>
  );
};

export default AISummaryButton;