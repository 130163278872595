import React, { useState } from 'react';
import TarihGirisi from './TarihGirisi';

const durduranSebepTipleri = [
  'HAGB Kararı Verilmesi',
  'Soruşturma İzni İstenilmesi',
  'Diğer'
];

function DurduranSebepModal({ acik, kapat, ekle }) {
  const [yeniSebep, setYeniSebep] = useState({ tip: '', baslangic: new Date(), bitis: new Date(), aciklama: '' });

  const handleEkle = () => {
    ekle(yeniSebep);
    setYeniSebep({ tip: '', baslangic: new Date(), bitis: new Date(), aciklama: '' });
  };

  if (!acik) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Zamanaşımını Durduran Sebep Ekle</h2>
        <select
          value={yeniSebep.tip}
          onChange={(e) => setYeniSebep({...yeniSebep, tip: e.target.value})}
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="">Sebep Tipi Seçin</option>
          {durduranSebepTipleri.map((tip) => (
            <option key={tip} value={tip}>{tip}</option>
          ))}
        </select>
        <TarihGirisi label="Başlangıç Tarihi" value={yeniSebep.baslangic} onChange={(date) => setYeniSebep({...yeniSebep, baslangic: date})} />
        <TarihGirisi label="Bitiş Tarihi" value={yeniSebep.bitis} onChange={(date) => setYeniSebep({...yeniSebep, bitis: date})} />
        <input
          type="text"
          placeholder="Açıklama"
          value={yeniSebep.aciklama}
          onChange={(e) => setYeniSebep({...yeniSebep, aciklama: e.target.value})}
          className="w-full p-2 mb-4 border rounded"
        />
        <div className="flex justify-end">
          <button onClick={kapat} className="mr-2 px-4 py-2 bg-gray-300 rounded">İptal</button>
          <button onClick={handleEkle} className="px-4 py-2 bg-blue-500 text-white rounded">Ekle</button>
        </div>
      </div>
    </div>
  );
}

export default DurduranSebepModal;

