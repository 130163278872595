import { Parser } from 'htmlparser2';
import { Paragraph, TextRun, AlignmentType, HeadingLevel } from 'docx';

export const parseHtmlContent = (htmlContent) => {
  if (!htmlContent || typeof htmlContent !== 'string') {
    console.warn('Geçersiz HTML içeriği:', htmlContent);
    return [new Paragraph({ text: 'İçerik bulunamadı' })];
  }

  const paragraphs = [];
  let currentParagraph = new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    spacing: { after: 200 }
  });
  let currentTextRun = {
    text: '',
    bold: false,
    italics: false,
    underline: false,
    color: undefined,
  };
  let inList = false;
  let listLevel = 0;

  const parser = new Parser({
    onopentag(name, attributes) {
      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name)) {
        if (currentParagraph.children && currentParagraph.children.length > 0) {
          paragraphs.push(currentParagraph);
        }
        currentParagraph = new Paragraph({
          heading: HeadingLevel[name.toUpperCase()],
          alignment: AlignmentType.LEFT,
          spacing: { before: 400, after: 200 }
        });
        currentTextRun.bold = true;
      } else if (name === 'p') {
        if (currentParagraph.children && currentParagraph.children.length > 0) {
          paragraphs.push(currentParagraph);
          currentParagraph = new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            spacing: { after: 200 }
          });
        }
      } else if (name === 'ul' || name === 'ol') {
        inList = true;
        listLevel++;
      } else if (name === 'li') {
        if (currentParagraph.children && currentParagraph.children.length > 0) {
          paragraphs.push(currentParagraph);
        }
        currentParagraph = new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          spacing: { after: 200 },
          bullet: {
            level: listLevel - 1
          }
        });
      } else if (name === 'strong' || name === 'b') {
        currentTextRun.bold = true;
      } else if (name === 'em' || name === 'i') {
        currentTextRun.italics = true;
      } else if (name === 'u') {
        currentTextRun.underline = true;
      } else if (name === 'span') {
        if (attributes.style) {
          if (attributes.style.includes('font-weight: bold')) {
            currentTextRun.bold = true;
          }
          if (attributes.style.includes('font-style: italic')) {
            currentTextRun.italics = true;
          }
          if (attributes.style.includes('text-decoration: underline')) {
            currentTextRun.underline = true;
          }
          const colorMatch = attributes.style.match(/color:\s*(#[0-9A-Fa-f]{6}|rgb\(\d+,\s*\d+,\s*\d+\))/);
          if (colorMatch) {
            currentTextRun.color = colorMatch[1];
          }
        }
      }
    },
    ontext(text) {
      currentTextRun.text += text;
    },
    onclosetag(name) {
      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'li', 'strong', 'b', 'em', 'i', 'u', 'span'].includes(name)) {
        if (currentTextRun.text.trim() !== '') {
          currentParagraph.addChildElement(new TextRun({
            text: currentTextRun.text,
            bold: currentTextRun.bold,
            italics: currentTextRun.italics,
            underline: currentTextRun.underline,
            color: currentTextRun.color,
          }));
        }
        if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'li'].includes(name)) {
          paragraphs.push(currentParagraph);
          currentParagraph = new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            spacing: { after: 200 }
          });
        }
        currentTextRun = {
          text: '',
          bold: false,
          italics: false,
          underline: false,
          color: undefined,
        };
      } else if (name === 'ul' || name === 'ol') {
        inList = false;
        listLevel--;
      }
    },
  });

  parser.write(htmlContent);
  parser.end();

  if (currentParagraph.children && currentParagraph.children.length > 0) {
    paragraphs.push(currentParagraph);
  }

  return paragraphs;
};