import { Footer, Paragraph, TextRun, AlignmentType, PageNumber, SimpleField } from 'docx';

export const createFooter = () => {
  return new Footer({
    children: [
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [
              "Sayfa "
            ]
          }),
          new SimpleField("PAGE"),
          new TextRun({
            children: [
              "/"
            ]
          }),
          new SimpleField("NUMPAGES")
        ]
      })
    ]
  });
};