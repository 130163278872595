import React, { useState, useEffect } from 'react';
import { getCezaSahsilestirme, updateCezaSahsilestirme } from '../../services/cezaSahsilestirmeService';
import { useGeneralInfo } from '../../contexts/GeneralInfoContext';

const CezaSahsilestirmeCard = () => {
  const { generalInfo } = useGeneralInfo();
  const [localInfo, setLocalInfo] = useState({
    yerelMahkemeUygulamasi: '',
    tck62: '',
    tck50: '',
    tck51: '',
    cmk231: ''
  });

  useEffect(() => {
    const fetchCezaSahsilestirme = async () => {
      if (generalInfo.takrirId) {
        try {
          const data = await getCezaSahsilestirme(generalInfo.takrirId);
          setLocalInfo(data);
        } catch (error) {
          console.error('Ceza şahsileştirme bilgisi getirme hatası:', error);
        }
      }
    };

    fetchCezaSahsilestirme();
  }, [generalInfo.takrirId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleBlur = async () => {
    try {
      if (!generalInfo.takrirId) {
        console.error('takrirId tanımlı değil');
        return;
      }
      await updateCezaSahsilestirme(generalInfo.takrirId, { sahsilestirmeMetni: localInfo });
    } catch (error) {
      console.error('Ceza şahsileştirme güncelleme hatası:', error);
    }
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="yerelMahkemeUygulamasi">
          Yerel Mahkeme ya da BAM'ın cezanın şahsîleştirilmesine ilişkin uygulaması ve gerekçesi:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="yerelMahkemeUygulamasi"
          name="yerelMahkemeUygulamasi"
          value={localInfo.yerelMahkemeUygulamasi}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="4"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tck62">
          TCK'nın 62.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="tck62"
          name="tck62"
          value={localInfo.tck62}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tck50">
          TCK'nın 50.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="tck50"
          name="tck50"
          value={localInfo.tck50}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tck51">
          TCK'nın 51.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="tck51"
          name="tck51"
          value={localInfo.tck51}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cmk231">
          CMK'nın 231.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="cmk231"
          name="cmk231"
          value={localInfo.cmk231}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
    </div>
  );
};

export default CezaSahsilestirmeCard;
