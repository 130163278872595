import { tarihFarki, ekleYil, ekleGun } from './tarihHesaplamalari';

export function hesaplaZamanAsimi(sucTarihi, zamanAsimiSuresi, durduranSebepler, kesenSebepler) {
  let olaganZamanAsimi = ekleYil(sucTarihi, zamanAsimiSuresi);
  let olaganustuZamanAsimi = hesaplaOlaganustuZamanAsimi(sucTarihi, zamanAsimiSuresi);

  const toplamDurdurmaSuresi = hesaplaToplamDurdurmaSuresi(durduranSebepler);
  olaganZamanAsimi = ekleGun(olaganZamanAsimi, toplamDurdurmaSuresi);
  olaganustuZamanAsimi = ekleGun(olaganustuZamanAsimi, toplamDurdurmaSuresi);

  const sonKesenTarih = hesaplaSonKesenTarih(kesenSebepler);
  if (sonKesenTarih > sucTarihi) {
    olaganZamanAsimi = ekleYil(sonKesenTarih, zamanAsimiSuresi);
    olaganustuZamanAsimi = hesaplaOlaganustuZamanAsimi(sonKesenTarih, zamanAsimiSuresi);
  }

  return {
    olaganZamanAsimi,
    olaganustuZamanAsimi,
    toplamDurdurmaSuresi,
  };
}

function hesaplaOlaganustuZamanAsimi(baslangicTarihi, yil) {
  return ekleYil(baslangicTarihi, Math.floor(yil * 1.5));
}

function hesaplaToplamDurdurmaSuresi(durduranSebepler) {
  return durduranSebepler.reduce((toplam, sebep) => {
    return toplam + tarihFarki(sebep.baslangic, sebep.bitis).gunSayisi;
  }, 0);
}

function hesaplaSonKesenTarih(kesenSebepler) {
  return kesenSebepler.reduce((sonTarih, sebep) => {
    return sebep.tarih > sonTarih ? sebep.tarih : sonTarih;
  }, new Date(0));
}
