import { toast } from 'react-toastify';

export const ekleKategori = (kategoriler, yeniKategoriAdi, parentKategori) => {
  if (!parentKategori) {
    return [...kategoriler, { isim: yeniKategoriAdi, altKategoriler: [] }];
  }

  const ekleAltKategori = (kategoriListesi) => {
    return kategoriListesi.map(kategori => {
      if (kategori.isim === parentKategori) {
        if (!kategori.altKategoriler.some(alt => alt.isim === yeniKategoriAdi)) {
          return {
            ...kategori,
            altKategoriler: [...(kategori.altKategoriler || []), { isim: yeniKategoriAdi, altKategoriler: [] }]
          };
        }
      }
      return {
        ...kategori,
        altKategoriler: kategori.altKategoriler ? ekleAltKategori(kategori.altKategoriler) : []
      };
    });
  };

  return ekleAltKategori(kategoriler);
};

export const guncelleKategori = (kategoriler, eskiAd, yeniAd) => {
  const guncelle = (kategoriListesi) => {
    return kategoriListesi.map(kategori => {
      if (kategori.isim === eskiAd) {
        return { ...kategori, isim: yeniAd };
      } else if (kategori.altKategoriler && kategori.altKategoriler.length > 0) {
        return {
          ...kategori,
          altKategoriler: guncelle(kategori.altKategoriler)
        };
      }
      return kategori;
    });
  };

  return guncelle(kategoriler);
};

export const silKategori = (kategoriler, kategoriIsim) => {
  return kategoriler.reduce((acc, kategori) => {
    if (kategori.isim === kategoriIsim) {
      return acc;
    }
    if (kategori.altKategoriler && kategori.altKategoriler.length > 0) {
      return [...acc, {
        ...kategori,
        altKategoriler: silKategori(kategori.altKategoriler, kategoriIsim)
      }];
    }
    return [...acc, kategori];
  }, []);
};

const kategoriVarMi = (kategoriler, kategoriAdi) => {
  if (!kategoriler || !Array.isArray(kategoriler)) {
    return false;
  }
  return kategoriler.some(kategori => 
    kategori.isim === kategoriAdi || 
    (kategori.altKategoriler && kategoriVarMi(kategori.altKategoriler, kategoriAdi))
  );
};

export const handleKategoriEkleVeyaGuncelle = (kategoriler, yeniKategoriAdi, parentKategori, eskiKategoriAdi) => {
  if (!kategoriler) {
    kategoriler = [];
  }

  // Eğer parentKategori null ise, yeni kategoriyi ana listeye ekle
  if (!parentKategori) {
    if (!eskiKategoriAdi) {
      // Yeni kategori ekleniyor
      if (!kategoriVarMi(kategoriler, yeniKategoriAdi)) {
        return [...kategoriler, { isim: yeniKategoriAdi, altKategoriler: [] }];
      }
    } else {
      // Mevcut kategori güncelleniyor
      return kategoriler.map(k => 
        k.isim === eskiKategoriAdi ? { ...k, isim: yeniKategoriAdi } : k
      );
    }
  }

  // Parent kategori varsa, o kategoriyi bul ve alt kategorisini ekle/güncelle
  return kategoriler.map(kategori => {
    if (kategori.isim === parentKategori) {
      const yeniAltKategoriler = handleKategoriEkleVeyaGuncelle(
        kategori.altKategoriler || [],
        yeniKategoriAdi,
        null,
        eskiKategoriAdi
      );
      return { ...kategori, altKategoriler: yeniAltKategoriler };
    }
    if (kategori.altKategoriler) {
      return {
        ...kategori,
        altKategoriler: handleKategoriEkleVeyaGuncelle(
          kategori.altKategoriler,
          yeniKategoriAdi,
          parentKategori,
          eskiKategoriAdi
        )
      };
    }
    return kategori;
  });
};

// ... Diğer yardımcı fonksiyonlar buraya eklenebilir