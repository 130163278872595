import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const CollapsiblePanel = ({ title, children, className, maxHeight = '230px' }) => {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('auto');

  useEffect(() => {
    if (isOpen) {
      const height = contentRef.current.scrollHeight;
      setContentHeight(`${height}px`);
    } else {
      setContentHeight('0px');
    }
  }, [isOpen, children]);

  return (
    <div className={`mb-4 shadow-sm ${className}`}>
      <button
        className="w-full flex justify-between items-center p-3 font-medium text-left text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 focus:outline-none transition duration-150 ease-in-out rounded-t-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-base">{title}</span>
        {isOpen ? (
          <ChevronUpIcon className="h-4 w-4" />
        ) : (
          <ChevronDownIcon className="h-4 w-4" />
        )}
      </button>
      <div 
        className={`bg-white transition-all duration-300 ease-in-out overflow-hidden`}
        style={{ maxHeight: contentHeight }}
      >
        <div ref={contentRef} className="p-4 pb-8 overflow-y-auto" style={{ maxHeight }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapsiblePanel;