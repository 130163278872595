import React, { useState, useMemo, useEffect } from 'react';
import { MagnifyingGlassIcon, TagIcon, XCircleIcon, TrashIcon } from '@heroicons/react/20/solid';

const EtiketListesi = ({ etiketler, secilenEtiketler, setSecilenEtiketler }) => {
  const [aramaMetni, setAramaMetni] = useState('');

  useEffect(() => {
    console.log('EtiketListesi etiketler:', etiketler);
  }, [etiketler]);

  const filtreliEtiketler = useMemo(() => {
    return (etiketler || [])
      .filter(etiket => typeof etiket === 'string' && etiket.toLowerCase().includes(aramaMetni.toLowerCase()))
      .sort((a, b) => a.localeCompare(b, 'tr'));
  }, [etiketler, aramaMetni]);

  const handleEtiketToggle = (etiket) => {
    setSecilenEtiketler(prev => 
      prev.includes(etiket) ? prev.filter(e => e !== etiket) : [...prev, etiket]
    );
  };

  const tumunuTemizle = () => {
    setSecilenEtiketler([]);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex flex-wrap gap-2 flex-grow">
          {secilenEtiketler.map(etiket => (
            <span key={etiket} className="inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
              {etiket}
              <XCircleIcon
                className="ml-1 h-4 w-4 cursor-pointer text-blue-600 hover:text-blue-800"
                onClick={() => handleEtiketToggle(etiket)}
              />
            </span>
          ))}
        </div>
        {secilenEtiketler.length > 0 && (
          <button
            onClick={tumunuTemizle}
            className="flex items-center px-3 py-1 text-sm font-medium text-red-600 hover:text-red-800 transition-colors duration-150"
          >
            <TrashIcon className="h-4 w-4 mr-1" />
            Tümünü Temizle
          </button>
        )}
      </div>

      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Ara..."
          value={aramaMetni}
          onChange={(e) => setAramaMetni(e.target.value)}
          className="w-full p-2 pl-8 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
        <MagnifyingGlassIcon className="absolute left-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>
      
      <div className="space-y-2">
        {filtreliEtiketler.length > 0 ? (
          filtreliEtiketler.map(etiket => (
            <button
              key={etiket}
              className={`flex items-center w-full text-left px-3 py-2 rounded transition-colors duration-150 ${
                secilenEtiketler.includes(etiket) ? 'bg-blue-500 text-white' : 'hover:bg-gray-100 text-gray-700'
              }`}
              onClick={() => handleEtiketToggle(etiket)}
            >
              <TagIcon className={`h-4 w-4 mr-2 ${secilenEtiketler.includes(etiket) ? 'text-white' : 'text-gray-500'}`} />
              <span>{etiket}</span>
            </button>
          ))
        ) : (
          <p>Hiç etiket bulunamadı.</p>
        )}
      </div>
    </div>
  );
};

export default EtiketListesi;
