import React, { useState, useEffect } from 'react';
import { Etiketleme, KanunMaddeleri } from './Etiketleme.js';

const DetaylarModal = ({
  duzenlenmisSablon,
  kategoriler,
  handleChange,
  handleSelectChange,
  onClose,
  kayitliEtiketler,
  kayitliKanunMaddeleri,
  onSave
}) => {
  const [localSablon, setLocalSablon] = useState(duzenlenmisSablon);

  useEffect(() => {
    setLocalSablon(duzenlenmisSablon);
  }, [duzenlenmisSablon]);

  const handleEtiketChange = (yeniEtiketler) => {
    setLocalSablon(prev => ({ ...prev, etiketler: yeniEtiketler }));
  };

  const handleKanunMaddeleriChange = (yeniKanunMaddeleri) => {
    setLocalSablon(prev => ({ ...prev, kanun_maddeleri: yeniKanunMaddeleri }));
  };

  const handleLocalChange = (e) => {
    const { name, value } = e.target;
    setLocalSablon(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    // Tüm değişiklikleri ana bileşene ilet
    Object.entries(localSablon).forEach(([key, value]) => {
      if (key === 'etiketler' || key === 'kanun_maddeleri') {
        handleSelectChange(key, value);
      } else {
        handleChange({ target: { name: key, value } });
      }
    });
    // Kaydetme işlemini tamamla
    onSave(localSablon);
  };

  // Null checks ekleyelim
  const etiketler = localSablon?.etiketler || [];
  const kanunMaddeleri = localSablon?.kanun_maddeleri || [];
  const kayitliEtiketlerArray = kayitliEtiketler || [];
  const kayitliKanunMaddeleriArray = kayitliKanunMaddeleri || [];

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-2xl mx-4">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Detayları Düzenle</h2>
        <div className="space-y-6">
          <div>
            <label htmlFor="ozet" className="block text-lg font-medium text-gray-700">Özet</label>
            <textarea
              id="ozet"
              name="ozet"
              value={localSablon?.ozet || ''}
              onChange={handleLocalChange}
              className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              rows="4"
            />
          </div>
          <div>
            <label htmlFor="baslik" className="block text-lg font-medium text-gray-700">Başlık</label>
            <input
              type="text"
              id="baslik"
              name="baslik"
              value={localSablon?.baslik || ''}
              onChange={handleLocalChange}
              className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="kategori" className="block text-lg font-medium text-gray-700">Kategori</label>
            <select
              id="kategori"
              name="kategori"
              value={localSablon?.kategori || ''}
              onChange={handleLocalChange}
              className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              <option value="">Kategori Seçin</option>
              {kategoriler.map(kategori => (
                <option key={kategori} value={kategori}>{kategori}</option>
              ))}
            </select>
          </div>
          <div>
            <Etiketleme
              etiketler={etiketler}
              onChange={handleEtiketChange}
              kayitliEtiketler={kayitliEtiketlerArray.map(etiket => typeof etiket === 'string' ? etiket : etiket.value)}
            />
          </div>
          <div>
            <KanunMaddeleri
              kanunMaddeleri={kanunMaddeleri}
              onChange={handleKanunMaddeleriChange}
              kayitliKanunMaddeleri={kayitliKanunMaddeleriArray.map(madde => typeof madde === 'string' ? madde : madde.value)}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-8">
          <button
            onClick={onClose}
            className="px-5 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition duration-150 ease-in-out"
          >
            İptal
          </button>
          <button
            onClick={handleSave}
            className="px-5 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetaylarModal;
