import { Document, Packer, Paragraph } from 'docx';
import { documentStyles } from './wordComponents/documentStyles.js';
import { createTakrirTable } from './wordComponents/takrirTable.js';
import { createSideBySideTable } from './wordComponents/sideBySideTable.js';
import { createSummaryTable } from './wordComponents/summaryTable.js';
import { createFooter } from './wordComponents/footer.js';
import { createTarafCard } from './wordComponents/tarafCard.js';
import { createOnIncelemeTable } from './wordComponents/onIncelemeTable.js';
import { createProcedureTable } from './wordComponents/procedureTable.js';
import { createCezaSahsilestirmeTable } from './wordComponents/cezaSahsilestirmeTable.js';
import { createSanikSabikasıTable } from './wordComponents/sanikSabikasıTable.js';
import { createTebliğnameTable } from './wordComponents/tebliğnameTable.js';
import { createTetkikHakimiGorusuTable } from './wordComponents/tetkikHakimiGorusuTable.js';
import { createTemyizNedenleriTable } from './wordComponents/temyizNedenleriTable.js';
import { createDelillerTable } from './wordComponents/delillerTable.js';

export const exportToWord = async (summaries = [], generalInfo = {}, procedureInfo = {}, takrirInfo = {}, parties = [], 
                                   onIncelemeInfo = {}, cezaSahsilestirmeInfo = {}, sanikSabikasıInfo = {}, tebliğnameInfo = {}, 
                                   tetkikHakimiGorusuInfo = {}, temyizTalebindeBulunanlarInfo = [], temyizNedenleriInfo = {}, delillerInfo = {},
                                   margins = { top: 1.5, bottom: 1.5, left: 2, right: 2 }) => {
  const takrirTable = createTakrirTable(takrirInfo);
  const sideBySideTable = createSideBySideTable(generalInfo, temyizTalebindeBulunanlarInfo, sanikSabikasıInfo, tetkikHakimiGorusuInfo);

  const children = [takrirTable, new Paragraph({}), sideBySideTable, new Paragraph({})];

  // Ön İnceleme Tablosu
  if (onIncelemeInfo && Object.keys(onIncelemeInfo).length > 0) {
    const onIncelemeTable = createOnIncelemeTable(onIncelemeInfo);
    children.push(onIncelemeTable, new Paragraph({}));
  }

  // Taraf bilgilerini ekle
  parties.forEach(party => {
    if (party && Object.keys(party).length > 0) {
      const tarafElements = createTarafCard(party, generalInfo.sucTarihi);
      children.push(...tarafElements);
      children.push(new Paragraph({}));
    }
  });

  // Özetleri ekle
  summaries.forEach((summary, index) => {
    if (summary && Object.keys(summary).length > 0) {
      const summaryTable = createSummaryTable(summary);
      children.push(summaryTable);
      if (index < summaries.length - 1) {
        children.push(new Paragraph({}));
      }
    }
  });
  children.push(new Paragraph({}));

  // Diğer tabloları ekle
  if (procedureInfo && Object.keys(procedureInfo).length > 0) {
    const procedureTable = createProcedureTable(procedureInfo);
    if (procedureTable) {
      children.push(procedureTable, new Paragraph({}));
    }
  }
  if (cezaSahsilestirmeInfo && Object.keys(cezaSahsilestirmeInfo).length > 0) {
    const cezaSahsilestirmeTable = createCezaSahsilestirmeTable(cezaSahsilestirmeInfo);
    if (cezaSahsilestirmeTable) {
      children.push(cezaSahsilestirmeTable, new Paragraph({}));
    }
  }

 
  // if (sanikSabikasıInfo && Object.keys(sanikSabikasıInfo).length > 0) {
  //   children.push(createSanikSabikasıTable(sanikSabikasıInfo), new Paragraph({}));
  // }
  if (tebliğnameInfo && Object.keys(tebliğnameInfo).length > 0) {
    children.push(createTebliğnameTable(tebliğnameInfo), new Paragraph({}));
  }
  // if (tetkikHakimiGorusuInfo && Object.keys(tetkikHakimiGorusuInfo).length > 0) {
  //   children.push(createTetkikHakimiGorusuTable(tetkikHakimiGorusuInfo), new Paragraph({}));
  // }
  if (temyizNedenleriInfo && Object.keys(temyizNedenleriInfo).length > 0) {
    children.push(createTemyizNedenleriTable(temyizNedenleriInfo), new Paragraph({}));
  }
  if (delillerInfo && Object.keys(delillerInfo).length > 0) {
    children.push(createDelillerTable(delillerInfo), new Paragraph({}));
  }

  const doc = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: margins.top * 567,    // 1 cm yaklaşık 567 dxa
              right: margins.right * 567,
              bottom: margins.bottom * 567,
              left: margins.left * 567,
            },
          },
        },
        children: children,
        footers: {
          default: createFooter(),
        },
      },
    ],
    styles: documentStyles,
  });

  try {
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    
    const yargitayEsasNo = (takrirInfo && takrirInfo.yargitayEsasNo) ? takrirInfo.yargitayEsasNo : 'YeniTakrir';
    const fileName = `TakrirRaporu_${yargitayEsasNo.replace(/\//g, '_')}.docx`;
    
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Word dosyası oluşturulurken bir hata oluştu:', error);
  }
};