import React from 'react';
import { Users } from 'lucide-react';
import CollapsiblePanel from './summariesComponents/CollapsiblePanel';
import PartyCard from './partyCard/PartyCard';
// PanelButton'ı doğru konumdan import edelim
import PanelButton from './summariesComponents/PanelButton.js';

const PartiesPanel = ({ 
  isPartiesOpen, 
  setIsPartiesOpen, 
  items, 
  addNewParty, 
  updateParty, 
  deleteItem, 
  addPenaltyCard, 
  updatePenaltyCard, 
  deletePenaltyCard 
}) => {
  return (
    <CollapsiblePanel
      title="Taraflar"
      isOpen={isPartiesOpen}
      setIsOpen={setIsPartiesOpen}
    >
      <PanelButton
        onClick={addNewParty}
        icon={Users}
        text="Yeni Taraf Ekle"
        color="bg-yellow-500 hover:bg-yellow-600"
      />
      <div className="flex flex-wrap -mx-2">
        {items.filter(item => item.type === 'taraf').map((item, index) => (
          <div key={item.id} className="w-full sm:w-1/2 px-2 mb-4">
            <PartyCard
              id={item.id}
              index={index}
              {...item}
              onUpdate={(updatedParty) => updateParty(item.id, updatedParty)}
              onDelete={() => deleteItem(item.id)}
              onAddPenaltyCard={(newPenaltyCard) => addPenaltyCard(item.id, newPenaltyCard)}
              onUpdatePenaltyCard={(penaltyCardId, updatedPenaltyCard) => updatePenaltyCard(item.id, penaltyCardId, updatedPenaltyCard)}
              onDeletePenaltyCard={(penaltyCardId) => deletePenaltyCard(item.id, penaltyCardId)}
            />
          </div>
        ))}
      </div>
    </CollapsiblePanel>
  );
};

export default PartiesPanel;