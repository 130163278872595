import React, { useState } from 'react';
import { FolderIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid';

const KategoriKlasorGorunumu = ({ kategoriler, secilenKategoriler, handleKategoriSecim, handleContextMenu, onYeniKategoriEkle }) => {
  const [currentPath, setCurrentPath] = useState([]);

  const getCurrentKategoriler = () => {
    let current = kategoriler;
    for (let kategori of currentPath) {
      current = current.find(k => k.isim === kategori)?.altKategoriler || [];
    }
    return current;
  };

  const handleKategoriClick = (kategori) => {
    setCurrentPath([...currentPath, kategori.isim]);
  };

  const handleBack = () => {
    setCurrentPath(currentPath.slice(0, -1));
  };

  const currentKategoriler = getCurrentKategoriler();

  const handleYeniKlasorEkle = () => {
    const yeniKategori = currentPath.length > 0 ? { isim: currentPath[currentPath.length - 1] } : null;
    onYeniKategoriEkle(yeniKategori);
  };

  return (
    <div className="p-4">
      <div className="flex items-center mb-4 text-sm text-gray-600">
        <button
          onClick={handleBack}
          disabled={currentPath.length === 0}
          className={`mr-2 ${currentPath.length === 0 ? 'text-gray-400' : 'text-blue-600 hover:text-blue-800'}`}
        >
          <ChevronLeftIcon className="h-5 w-5 inline" />
        </button>
        {currentPath.length === 0 ? 'Ana Dizin' : currentPath.join(' > ')}
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div
          className="flex flex-col items-center p-3 cursor-pointer hover:bg-gray-100 rounded"
          onClick={handleYeniKlasorEkle}
        >
          <PlusIcon className="h-16 w-16 text-gray-400" />
          <span className="mt-2 text-sm text-center">Yeni Klasör Ekle</span>
        </div>
        {currentKategoriler.map((kategori) => (
          <div
            key={kategori.isim}
            className="flex flex-col items-center p-3 cursor-pointer hover:bg-gray-100 rounded group relative"
            onClick={() => handleKategoriClick(kategori)}
            onContextMenu={(e) => handleContextMenu(e, kategori)}
          >
            <FolderIcon className="h-16 w-16 text-yellow-500" />
            <div className="mt-2 text-center w-full">
              <input
                type="checkbox"
                checked={secilenKategoriler.includes(kategori.isim)}
                onChange={() => handleKategoriSecim(kategori.isim, kategori.altKategoriler || [])}
                className="mr-1"
                onClick={(e) => e.stopPropagation()}
              />
              <span className="text-sm block max-w-full line-clamp-2" title={kategori.isim}>
                {kategori.isim}
              </span>
            </div>
            {kategori.altKategoriler && kategori.altKategoriler.length > 0 && (
              <ChevronRightIcon className="h-5 w-5 text-gray-400 absolute right-2 bottom-2" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KategoriKlasorGorunumu;