import React from 'react';
import { Calendar, Edit2 } from 'lucide-react';
import { calculateAge, parseDate } from '../../utils/dateUtils.js';

const PartyInfo = ({ party, sucTarihi, onEdit }) => {
  const formatDate = (date) => {
    if (!date) return 'Belirtilmemiş';
    try {
      const parsedDate = parseDate(date);
      if (!parsedDate) return 'Geçersiz Tarih';
      return parsedDate.toLocaleDateString('tr-TR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Tarih ayrıştırma veya biçimlendirme hatası:', error);
      return 'Geçersiz Tarih';
    }
  };

  const getAge = (birthDate) => {
    if (!birthDate || !sucTarihi) {
      return 'Belirtilmemiş';
    }

    try {
      const parsedBirthDate = parseDate(birthDate);
      const parsedSucTarihi = parseDate(sucTarihi);

      if (!parsedBirthDate || !parsedSucTarihi) {
        return 'Geçersiz Tarih';
      }

      const age = calculateAge(parsedBirthDate, parsedSucTarihi);
      return age !== null ? age.toString() : 'Geçersiz Tarih';
    } catch (error) {
      console.error('Yaş hesaplama hatası:', error);
      return 'Geçersiz Tarih';
    }
  };

  const getPartyTypeColor = (role) => {
    switch (role) {
      case 'Şüpheli':
        return 'bg-red-100 text-red-800';
      case 'Mağdur':
        return 'bg-blue-100 text-blue-800';
      case 'Tanık':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg relative p-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xl font-bold text-gray-800">{party.name}</h3>
        <div className="flex items-center space-x-2">
          <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getPartyTypeColor(party.role)}`}>
            {party.role || 'Belirtilmemiş'}
          </span>
          <button
            onClick={onEdit}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <Edit2 className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="flex items-center text-sm text-gray-600 mt-1">
        <Calendar className="w-4 h-4 mr-1" />
        <span>{formatDate(party.birthDate)}</span>
      </div>
      <p className="mt-1">Yaş: {getAge(party.birthDate)}</p>
      <div className="flex mt-2 space-x-2">
        {party.SIR && (
          <span className="px-2 py-1 rounded-full text-xs font-semibold bg-green-100 text-green-800">
            SIR+
          </span>
        )}
        {party.FM && (
          <span className="px-2 py-1 rounded-full text-xs font-semibold bg-purple-100 text-purple-800">
            FM+
          </span>
        )}
      </div>
    </div>
  );
};

export default PartyInfo;