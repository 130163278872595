import React, { useState, useEffect } from 'react';

const ProcedureCard = ({ info, onUpdate }) => {
  const [localInfo, setLocalInfo] = useState(info);

  useEffect(() => {
    setLocalInfo(info);
  }, [info]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleBlur = () => {
    onUpdate(localInfo);
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {/* <h3 className="text-xl font-semibold mb-4">SORUŞTURMA ve KOVUŞTURMA KOŞULLARI</h3> */}
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="davaŞartı">
            Dava şartı
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="davaŞartı"
            name="davaŞartı"
            value={localInfo.davaŞartı || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            rows="3"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="şikayet">
            Şikâyet
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="şikayet"
            name="şikayet"
            value={localInfo.şikayet || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            rows="3"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="önÖdeme">
            Ön ödeme
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="önÖdeme"
            name="önÖdeme"
            value={localInfo.önÖdeme || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            rows="3"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="uzlaştırma">
            Uzlaştırma
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="uzlaştırma"
            name="uzlaştırma"
            value={localInfo.uzlaştırma || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Varsa, durma süresi !"
            rows="3"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="basitYargılama">
            Basit Yargılama-Seri Muhakeme Usulleri
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="basitYargılama"
            name="basitYargılama"
            value={localInfo.basitYargılama || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            rows="3"
          />
        </div>
      </div>
    </div>
  );
};

export default ProcedureCard;