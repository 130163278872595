import React, { useState, useEffect } from 'react';
import TemplateManager from '../components/TemplateManager.js';
import PredefinedColumnsManager from '../components/PredefinedColumnsManager.js';

const Settings = () => {
  const [crimes, setCrimes] = useState([]);
  const [newCrime, setNewCrime] = useState('');
  const [judges, setJudges] = useState([]);
  const [newJudge, setNewJudge] = useState('');
  const [mahkemeTürleri, setMahkemeTürleri] = useState([]);
  const [newMahkemeTürü, setNewMahkemeTürü] = useState('');
  const [defaultMahkeme, setDefaultMahkeme] = useState('');

  useEffect(() => {
    const storedCrimes = JSON.parse(localStorage.getItem('crimes')) || [];
    const storedJudges = JSON.parse(localStorage.getItem('hakimler')) || [];
    const storedMahkemeTürleri = JSON.parse(localStorage.getItem('mahkemeTürleri')) || [];
    const storedDefaultMahkeme = localStorage.getItem('defaultMahkeme') || '';
    setCrimes(storedCrimes);
    setJudges(storedJudges);
    setMahkemeTürleri(storedMahkemeTürleri);
    setDefaultMahkeme(storedDefaultMahkeme);
  }, []);

  const addCrime = () => {
    if (newCrime.trim() !== '') {
      const updatedCrimes = [...crimes, { value: newCrime.toLowerCase().replace(/ /g, '_'), label: newCrime }];
      setCrimes(updatedCrimes);
      localStorage.setItem('crimes', JSON.stringify(updatedCrimes));
      setNewCrime('');
    }
  };

  const removeCrime = (crimeToRemove) => {
    const updatedCrimes = crimes.filter(crime => crime.value !== crimeToRemove.value);
    setCrimes(updatedCrimes);
    localStorage.setItem('crimes', JSON.stringify(updatedCrimes));
  };

  const addJudge = () => {
    if (newJudge.trim() !== '') {
      const updatedJudges = [...judges, newJudge];
      setJudges(updatedJudges);
      localStorage.setItem('hakimler', JSON.stringify(updatedJudges));
      setNewJudge('');
    }
  };

  const removeJudge = (judgeToRemove) => {
    const updatedJudges = judges.filter(judge => judge !== judgeToRemove);
    setJudges(updatedJudges);
    localStorage.setItem('hakimler', JSON.stringify(updatedJudges));
  };

  const addMahkemeTürü = () => {
    if (newMahkemeTürü.trim() !== '') {
      const updatedMahkemeTürleri = [...mahkemeTürleri, { value: newMahkemeTürü.toLowerCase().replace(/ /g, '_'), label: newMahkemeTürü }];
      setMahkemeTürleri(updatedMahkemeTürleri);
      localStorage.setItem('mahkemeTürleri', JSON.stringify(updatedMahkemeTürleri));
      setNewMahkemeTürü('');
    }
  };

  const removeMahkemeTürü = (mahkemeToRemove) => {
    const updatedMahkemeTürleri = mahkemeTürleri.filter(mahkeme => mahkeme.value !== mahkemeToRemove.value);
    setMahkemeTürleri(updatedMahkemeTürleri);
    localStorage.setItem('mahkemeTürleri', JSON.stringify(updatedMahkemeTürleri));
    if (defaultMahkeme === mahkemeToRemove.value) {
      setDefaultMahkeme('');
      localStorage.setItem('defaultMahkeme', '');
    }
  };

  const handleDefaultMahkemeChange = (e) => {
    setDefaultMahkeme(e.target.value);
    localStorage.setItem('defaultMahkeme', e.target.value);
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Ayarlar</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SettingCard title="Suçlar" onAdd={addCrime} onRemove={removeCrime}
          items={crimes} newItem={newCrime} setNewItem={setNewCrime}
          addButtonText="Suç Ekle" inputPlaceholder="Yeni suç ekle"
        />
        
        <SettingCard title="Hâkimler" onAdd={addJudge} onRemove={removeJudge}
          items={judges} newItem={newJudge} setNewItem={setNewJudge}
          addButtonText="Hâkim Ekle" inputPlaceholder="Yeni hâkim ekle"
        />

        <SettingCard title="Mahkeme Türleri" onAdd={addMahkemeTürü} onRemove={removeMahkemeTürü}
          items={mahkemeTürleri} newItem={newMahkemeTürü} setNewItem={setNewMahkemeTürü}
          addButtonText="Mahkeme Türü Ekle" inputPlaceholder="Yeni mahkeme türü ekle"
        />

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-700">Varsayılan Mahkeme</h2>
          <select
            value={defaultMahkeme}
            onChange={handleDefaultMahkemeChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option value="">Seçiniz</option>
            {mahkemeTürleri.map((mahkeme) => (
              <option key={mahkeme.value} value={mahkeme.value}>
                {mahkeme.label}
              </option>
            ))}
          </select>
        </div>

        <PredefinedColumnsManager />
      </div>

      <div className="mt-8">
        <TemplateManager />
      </div>
    </div>
  );
};

const SettingCard = ({ title, onAdd, onRemove, items, newItem, setNewItem, addButtonText, inputPlaceholder }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h2 className="text-xl font-semibold mb-4 text-gray-700">{title}</h2>
    <div className="flex mb-4">
      <input
        type="text"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        className="flex-grow mr-2 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        placeholder={inputPlaceholder}
      />
      <button onClick={onAdd} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300">
        {addButtonText}
      </button>
    </div>
    <ul className="space-y-2">
      {items.map((item, index) => (
        <li key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded">
          <span>{item.label || item}</span>
          <button onClick={() => onRemove(item)} className="text-red-500 hover:text-red-700 transition duration-300">
            Sil
          </button>
        </li>
      ))}
    </ul>
  </div>
);

export default Settings;