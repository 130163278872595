import React from 'react';

const AISuggestionsModal = ({ show, suggestions, onChange, onSave, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-3xl"> {/* max-w-lg'den max-w-3xl'e değiştirildi */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">AI Önerileri</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="ai-ozet" className="block text-sm font-medium text-gray-700">Özet</label>
            <textarea
              id="ai-ozet"
              name="ozet"
              value={suggestions.ozet}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-3"
              rows="8" // 4'ten 8'e çıkarıldı
              style={{ minHeight: '200px' }} // Minimum yükseklik eklendi
            />
          </div>
          <div>
            <label htmlFor="ai-baslik" className="block text-sm font-medium text-gray-700">Başlık</label>
            <input
              type="text"
              id="ai-baslik"
              name="baslik"
              value={suggestions.baslik}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 px-3 py-2"
            />
          </div>
          <div>
            <label htmlFor="ai-etiketler" className="block text-sm font-medium text-gray-700">Etiketler</label>
            <input
              type="text"
              id="ai-etiketler"
              name="etiketler"
              value={suggestions.etiketler.join(', ')}
              onChange={(e) => onChange({ target: { name: 'etiketler', value: e.target.value.split(', ') } })}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 px-3 py-2"
            />
          </div>
          <div>
            <label htmlFor="ai-kanunMaddeleri" className="block text-sm font-medium text-gray-700">Kanun Maddeleri</label>
            <input
              type="text"
              id="ai-kanunMaddeleri"
              name="kanunMaddeleri"
              value={suggestions.kanunMaddeleri.join(', ')}
              onChange={(e) => onChange({ target: { name: 'kanunMaddeleri', value: e.target.value.split(', ') } })}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 px-3 py-2"
            />
          </div>
        </div>
        <div className="flex justify-end mt-4 space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out"
          >
            İptal
          </button>
          <button
            onClick={onSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default AISuggestionsModal;
