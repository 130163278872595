import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useGeneralInfo } from '../../contexts/GeneralInfoContext';

const CollapsiblePanel = React.memo(({ title, isOpen, setIsOpen, children, isTakrirCard = false }) => {
  const { generalInfo } = useGeneralInfo();
  const isDisabled = !isTakrirCard && !generalInfo.takrirId;

  return (
    <div className={`border rounded-lg overflow-visible ${isDisabled ? 'opacity-50' : ''}`}>
      <button
        className="w-full px-4 py-2 bg-gray-100 flex justify-between items-center"
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
        disabled={isDisabled}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
        {isOpen ? (
          <ChevronUp className="w-5 h-5" />
        ) : (
          <ChevronDown className="w-5 h-5" />
        )}
      </button>
      {isOpen && !isDisabled && <div className="p-4">{children}</div>}
    </div>
  );
});

export default CollapsiblePanel;
