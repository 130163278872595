import React, { useState } from 'react';
import TarihGirisi from './TarihGirisi';

const kesenSebepTipleri = [
  'Şüpheli veya sanıklardan birinin savcı huzurunda ifadesinin alınması veya sorguya çekilmesi',
  'Şüpheli veya sanıklardan biri hakkında tutuklama kararının verilmesi',
  'Suçla ilgili olarak iddianame düzenlenmesi',
  'Sanıklardan bir kısmı hakkında da olsa, mahkûmiyet kararı verilmesi'
];

function KesenSebepModal({ acik, kapat, ekle }) {
  const [yeniSebep, setYeniSebep] = useState({ tip: '', tarih: new Date(), aciklama: '' });

  const handleEkle = () => {
    ekle(yeniSebep);
    setYeniSebep({ tip: '', tarih: new Date(), aciklama: '' });
  };

  if (!acik) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Zamanaşımını Kesen Sebep Ekle</h2>
        <select
          value={yeniSebep.tip}
          onChange={(e) => setYeniSebep({...yeniSebep, tip: e.target.value})}
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="">Sebep Tipi Seçin</option>
          {kesenSebepTipleri.map((tip) => (
            <option key={tip} value={tip}>{tip}</option>
          ))}
        </select>
        <TarihGirisi label="Tarih" value={yeniSebep.tarih} onChange={(date) => setYeniSebep({...yeniSebep, tarih: date})} />
        <input
          type="text"
          placeholder="Açıklama"
          value={yeniSebep.aciklama}
          onChange={(e) => setYeniSebep({...yeniSebep, aciklama: e.target.value})}
          className="w-full p-2 mb-4 border rounded"
        />
        <div className="flex justify-end">
          <button onClick={kapat} className="mr-2 px-4 py-2 bg-gray-300 rounded">İptal</button>
          <button onClick={handleEkle} className="px-4 py-2 bg-green-500 text-white rounded">Ekle</button>
        </div>
      </div>
    </div>
  );
}

export default KesenSebepModal;

