export const getCardStyle = (type) => {
    switch (type) {
      case 'tanık':
        return {
          background: 'bg-blue-50',
          border: 'border-l-4 border-blue-500'
        };
      case 'mağdur':
        return {
          background: 'bg-green-50',
          border: 'border-l-4 border-green-500'
        };
      case 'sanık':
        return {
          background: 'bg-red-50',
          border: 'border-l-4 border-red-500'
        };
      case 'özet':
        return {
          background: 'bg-purple-50',
          border: 'border-l-4 border-purple-500'
        };
      default:
        return {
          background: 'bg-gray-50',
          border: 'border-l-4 border-gray-500'
        };
    }
  };