import React from 'react';
import { PencilIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

const GoruntulemeAlani = ({ sablon, onDuzenle, onKapat, onShowDetails }) => (
  <div className="flex flex-col h-full">
    <div className="flex justify-between items-center p-4 border-b">
      <h2 className="text-xl font-semibold text-gray-800 truncate">{sablon.baslik}</h2>
      <button
        onClick={onShowDetails}
        className="text-gray-400 hover:text-gray-500"
      >
        <InformationCircleIcon className="h-5 w-5" />
      </button>
    </div>
    <div className="flex-grow overflow-y-auto p-4">
      <div dangerouslySetInnerHTML={{ __html: sablon.icerik }} className="prose max-w-none" />
    </div>
    <div className="flex justify-end space-x-3 p-4 border-t">
      <button
        onClick={onDuzenle}
        className="flex items-center px-3 py-1 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600 transition duration-150 ease-in-out"
      >
        <PencilIcon className="h-4 w-4 mr-1" />
        Düzenle
      </button>
      <button
        onClick={onKapat}
        className="px-3 py-1 bg-gray-200 text-gray-800 text-sm rounded-md hover:bg-gray-300 transition duration-150 ease-in-out"
      >
        Kapat
      </button>
    </div>
  </div>
);

export default GoruntulemeAlani;