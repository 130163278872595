import React from 'react';
import { PlusCircle, Trash2, Edit2 } from 'lucide-react';
import { formatCeza } from '../../../utils/cezaHesaplamalari';

const Column = ({
  column,
  index,
  cardId,
  handleEditColumn,
  showColumnMenu,
  deleteColumn,
  startResizing,
}) => {
  const formatPenalty = (penalty) => {
    return formatCeza(penalty).replace(/(\d+)([YAGAPC])/g, '<strong>$1</strong>$2 ').trim();
  };

  const formatDiscountRate = (discountRate) => {
    if (!discountRate || discountRate.rate.numerator === 0 || discountRate.rate.denominator === 0) {
      return '';
    }
    const { type, rate } = discountRate;
    const sign = type === 'indirim' ? '-' : '+';
    return `(${rate.numerator}/${rate.denominator})${sign}`;
  };

  return (
    <div 
      className={`relative group ${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}`}
      style={{ width: `${column.width}%`, height: '100%' }}
    >
      <div className="absolute top-0 right-0 z-10">
        <button
          className="h-5 w-5 opacity-0 group-hover:opacity-100"
          onClick={() => handleEditColumn(column, cardId, index)}
        >
          <Edit2 className="h-3 w-3" />
        </button>
      </div>
      {column.isSingleLine ? (
        <div className="h-full flex items-center justify-center overflow-hidden text-xs p-0.5 font-semibold">
          <div className="text-center">{column.content}</div>
        </div>
      ) : column.isTwoLineText ? (
        <>
          <div className="h-1/2 flex items-center justify-center overflow-hidden text-xs p-0.5 border-b border-gray-400 font-semibold">
            <div className="text-center">{column.topContent}</div>
          </div>
          <div className="h-1/2 flex items-center justify-center overflow-hidden text-xs p-0.5">
            <div className="text-center">{column.bottomContent}</div>
          </div>
        </>
      ) : (
        <>
          <div className="h-1/2 flex items-center justify-center overflow-hidden text-xs p-0.5 border-b border-gray-400 font-semibold">
            <div className="text-center">
              {column.law}
              {!column.isBasePenalty && formatDiscountRate(column.discountRate) && (
                <span className="ml-1 text-gray-500">
                  {formatDiscountRate(column.discountRate)}
                </span>
              )}
            </div>
          </div>
          <div className="h-1/2 flex items-center justify-center overflow-hidden text-xs p-0.5">
            <div 
              className="text-center"
              dangerouslySetInnerHTML={{ __html: formatPenalty(column.penalty) }}
            />
          </div>
        </>
      )}
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-50">
        <div className="relative">
          <button 
            className="h-5 w-5 text-white mr-1"
            onClick={(e) => showColumnMenu(e, cardId, index)}
          >
            <PlusCircle className="h-3 w-3" />
          </button>
        </div>
        <button 
          className="h-5 w-5 text-white"
          onClick={() => deleteColumn(cardId, column.id)}
        >
          <Trash2 className="h-3 w-3" />
        </button>
      </div>
      <div 
        className="absolute top-0 right-0 bottom-0 w-1 cursor-col-resize bg-gray-300 opacity-0 group-hover:opacity-100"
        onMouseDown={(e) => startResizing(cardId, column.id, e)}
      />
    </div>
  );
};

export default Column;