import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { GeneralInfoProvider } from './contexts/GeneralInfoContext';
import { NotificationProvider } from './contexts/NotificationContext.js';
import Notification from './components/Notification.js';

// Sayfaları import et
import Summaries from './components/Summaries.js';
import Calculation from './components/Calculation.js';
// import ShowReport from './components/ShowReport';
import Settings from './pages/Settings.js';
import KayitliTakrirler from './pages/KayitliTakrirler.js';
import SunTimes from './pages/SunTimes.js';
import KararSablonlari from './pages/KararSablonlari.js';
import DateDifference from './pages/DateDifference.js';
import ZamanAsimi from './pages/ZamanAsimi.js';

function App() {
  return (
    <Router>
      <NotificationProvider>
        <GeneralInfoProvider>
          <AppContent />
        </GeneralInfoProvider>
      </NotificationProvider>
    </Router>
  );
}

function AppContent() {
  const [reportData, setReportData] = React.useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const updateReportData = (section, data) => {
    setReportData(prevData => ({
      ...prevData,
      [section]: data
    }));
  };

  useEffect(() => {
    const selectedTakrir = localStorage.getItem('selectedTakrir');
    if (selectedTakrir) {
      updateReportData('all', JSON.parse(selectedTakrir));
      localStorage.removeItem('selectedTakrir');
    }
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Notification />
      <nav className="bg-white shadow-lg">
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex-shrink-0 flex items-center">
               
                <span className="ml-2 text-xl font-semibold text-gray-800">Mustafa EKİM</span>
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <NavLink to="/">Ceza Hesaplama</NavLink>
              <NavLink to="/gunes-saatleri">Güneş Saatleri</NavLink>
              <NavLink to="/tarih-farki">Tarih Farkı</NavLink>
              {/* <NavLink to="/zaman-asimi">Zaman Aşımı</NavLink> */}
              {/* <NavLink to="/karar-sablonlari">Karar Şablonları</NavLink>
              <NavLink to="/takrir-raporu">Takrir Raporu</NavLink>
              <NavLink to="/kayitli-takrirler">Kayıtlı Takrirler</NavLink>
              <NavLink to="/ayarlar">Ayarlar</NavLink> */}
              
            </div>
            <div className="flex items-center sm:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Ana menüyü aç</span>
                <svg className={`${isMenuOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className={`${isMenuOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobil menü */}
        <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="pt-2 pb-3 space-y-1">
            <MobileNavLink to="/">Ceza Hesaplama</MobileNavLink>
            <MobileNavLink to="/gunes-saatleri">Güneş Saatleri</MobileNavLink>
            <MobileNavLink to="/tarih-farki">Tarih Farkı</MobileNavLink>
            {/* <MobileNavLink to="/zaman-asimi">Zaman Aşımı</MobileNavLink> */}
            {/* <MobileNavLink to="/karar-sablonlari">Karar Şablonları</MobileNavLink>
            <MobileNavLink to="/takrir-raporu">Takrir Raporu</MobileNavLink>
            <MobileNavLink to="/kayitli-takrirler">Kayıtlı Takrirler</MobileNavLink>
            <MobileNavLink to="/ayarlar">Ayarlar</MobileNavLink>
             */}
          </div>
        </div>
      </nav>

      <main className="flex-grow w-full mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <Routes>
          <Route path="/" element={<Calculation onChange={(data) => updateReportData('calculation', data)} data={reportData.calculation} />} />
          <Route path="/takrir-raporu" element={<HomePage reportData={reportData} updateReportData={updateReportData} />} />
          <Route path="/kayitli-takrirler" element={<KayitliTakrirler />} />
          <Route path="/gunes-saatleri" element={<SunTimes />} />
          <Route path="/karar-sablonlari" element={<KararSablonlari />} />
          <Route path="/ayarlar" element={<Settings onChange={(data) => updateReportData('settings', data)} data={reportData.settings} />} />
          <Route path="/tarih-farki" element={<DateDifference />} />
          <Route path="/zaman-asimi" element={<ZamanAsimi />} />
        </Routes>
      </main>
    </div>
  );
}

function NavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`px-3 py-2 rounded-md text-sm font-medium ${
        isActive
          ? 'bg-indigo-500 text-white'
          : 'text-gray-600 hover:bg-indigo-100 hover:text-indigo-700'
      } transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
}

function MobileNavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`block px-3 py-2 rounded-md text-base font-medium ${
        isActive
          ? 'bg-indigo-500 text-white'
          : 'text-gray-700 hover:bg-indigo-100 hover:text-indigo-700'
      } transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
}

function HomePage({ reportData, updateReportData }) {
  const handleDataChange = (section, data) => {
    updateReportData(section, data);
  };

  return (
    <div className="space-y-8">
      <section className="bg-white shadow rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Takrir Raporu Oluştur</h2>
        <Summaries
          onChange={(data) => handleDataChange('summaries', data)}
          data={reportData.summaries}
        />
      </section>
    </div>
  );
}

export default App;
